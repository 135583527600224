import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Range } from '@sweetspot/shared/types'
import {
  Button,
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContentHeader,
  InputSelectSearchableContent,
  InputSelectSearchableItem,
  InputSelectSearchableTrigger,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import { useSelector } from 'react-redux'

const RangeSelector = () => {
  const { t } = useTranslation()
  const { ranges, selectedRange, setSelectedRange } = useBaysContext()
  const [search, setSearch] = useState('')
  const location = useLocation()
  const history = useHistory()

  const selectedClubUuid = useSelector((state) => {
    const list = state.golfClub.list
    const selectedId = state.golfClub.selectedId
    const selectedClub = list.find((club) => club.id === selectedId)
    return selectedClub?.uuid
  })

  const updateSelectedRangeIdInUrl = useCallback(
    (selectedId: string) => {
      if (!selectedId) {
        return
      }
      const params = new URLSearchParams(location.search)
      params.set('range_id', selectedId)
      params.set('club_id', selectedClubUuid)
      params.delete('trms_site_id')
      history.replace({ search: params.toString() })
    },
    [history, location.search, selectedClubUuid]
  )

  useEffect(() => {
    if (ranges?.length && setSelectedRange) {
      const params = new URLSearchParams(location.search)
      const rangeId = params.get('range_id')
      const trmsSiteId = params.get('trms_site_id')

      let selected: Range | undefined
      if (trmsSiteId) {
        selected = ranges.find((range) => range.toptracer_site_id === trmsSiteId)
      } else if (rangeId) {
        selected = ranges.find((range) => range.id === rangeId)
      }

      if (selected) {
        setSelectedRange(selected)
        if (trmsSiteId) {
          updateSelectedRangeIdInUrl(selected.id)
        }
        return
      }

      if (!selectedRange && !trmsSiteId) {
        setSelectedRange(ranges[0])
        updateSelectedRangeIdInUrl(ranges[0]?.id)
      }
    }
  }, [ranges, selectedRange, setSelectedRange, location.search, updateSelectedRangeIdInUrl])

  const rangesToRender = useMemo(
    () =>
      ranges?.filter(
        (range) => range.name.toLowerCase().includes(search.toLowerCase()),
        [search, ranges]
      ),
    [search, ranges]
  )

  const handleValueChange = useCallback(
    (newValue: string) => {
      const selected = ranges.find((range) => range.id === newValue)
      if (setSelectedRange && selected) {
        setSelectedRange(selected)
        setSearch('')

        updateSelectedRangeIdInUrl(selected?.id)
      }
    },
    [ranges, setSelectedRange, updateSelectedRangeIdInUrl]
  )

  const isNotAvailable = useMemo(() => !ranges?.length, [ranges])

  return (
    <InputSelect withSearch>
      <InputBase disabled={isNotAvailable}>
        <InputLabelContainer className="mb-0.5">{t('words.range_one')}</InputLabelContainer>
        <InputSelectSearchableTrigger disabled={isNotAvailable} asChild>
          <InputContainer className="cursor-pointer">
            <InputLeadingContainer>
              <i className="fa-kit fa-range-regular" />
            </InputLeadingContainer>
            <InputSelectStatefulInput
              value={
                isNotAvailable
                  ? `--- ${t('sentences.notAvailable')}`
                  : ranges.find((range) => range.id === selectedRange?.id)?.name
              }
              onChange={() => null}
              onClick={() => setSearch('')}
              className="text-content-base text-ellipsis px-10 "
              readOnly
            />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectSearchableTrigger>
      </InputBase>

      <InputSelectSearchableContent>
        <div className="bg-background-mono-lighter">
          <InputBase className="w-full p-4">
            <InputContainer>
              <InputLeadingContainer>
                <i className="fa-regular fa-magnifying-glass" />
              </InputLeadingContainer>
              <Input
                rounding="pill"
                className="px-10"
                placeholder={t('sentences.startTyping') + '...'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputContainer>
          </InputBase>
        </div>
        <InputSelectContentHeader />
        {rangesToRender?.map((range) => (
          <div
            key={range.id}
            className="focus-visible:border-border-stroke-subtle mx-0 px-0 focus-visible:border-2"
          >
            <InputSelectSearchableItem value={range.id} onSelect={handleValueChange}>
              <ListItem className="ml-2 h-full justify-center gap-2">
                <ListItemLeading>
                  <i className="fa-kit fa-range-regular" />
                </ListItemLeading>
                <ListItemMainContent className="flex-row justify-between pr-4">
                  <ListItemParagraph className="text-content-base">{range.name}</ListItemParagraph>
                  <ListItemTrailing>
                    {selectedRange?.id === range.id && (
                      <i className="fa-regular fa-check h-4 w-4" />
                    )}
                  </ListItemTrailing>
                </ListItemMainContent>
              </ListItem>
            </InputSelectSearchableItem>
          </div>
        ))}
        <div className="mt-1 flex min-h-[50px] justify-center py-0.5">
          <Button
            variant="link-dark"
            className="text-content-base w-full font-medium text-black hover:no-underline focus:no-underline"
          >
            <Link
              className="height-full flex w-full items-center justify-center "
              to={'/settings/manage-range'}
              target={'_blank'}
            >
              <i className="fa-regular fa-arrow-up-right-from-square mr-2" />
              {t('words.new')} {t('words.range_one')}
            </Link>
          </Button>
        </div>
      </InputSelectSearchableContent>
    </InputSelect>
  )
}

export default RangeSelector
