import { formatInTimeZone, zonedTimeToUtc, toDate as toDateTz } from 'date-fns-tz'
import { format, toDate, set, isValid } from 'date-fns'
import moment from 'moment'

/**
 * Utility function to format a date in a specific timezone.
 *
 * @param {string | Date} date - The date to format.
 * @param {string} timeZone - The timezone to use for formatting.
 * @param {string} format - The format string to use (default is 'yyyy-MM-dd HH:mm:ss').
 * @returns {string} - The formatted date string.
 */
export function formatDateInTimeZone(date, timeZone = 'UTC', format = 'yyyy-MM-dd HH:mm:ss') {
  if (!isValid(date)) return date
  return formatInTimeZone(toDateTz(date, { timeZone }), timeZone, format)
}

/**
 * Utility function to set the time to 23:59:59.999 and format a date in a specific timezone.
 *
 * @param {string | Date} date - The date to format.
 * @param {string} timeZone - The timezone to use for formatting.
 * @param {string} format - The format string to use (default is 'yyyy-MM-dd HH:mm:ss').
 * @returns {string} - The formatted date string with the time set to 23:59:59.999.
 */
export function formatEndOfDayInTimeZone(date, timeZone, format = 'yyyy-MM-dd HH:mm:ss') {
  if (!isValid(date)) return date
  // Set the time to 23:59:59.999
  const endOfDay = set(toDateTz(date, { timeZone }), {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999,
  })
  // Format the date in the provided timezone
  return formatInTimeZone(endOfDay, timeZone, format)
}

/**
 * Returns the start of the day in the specified time zone, converted to UTC.
 *
 * @param {Date} date - The date object.
 * @param {string} tz - The time zone string.
 * @returns {Date} - The start of the day in UTC.
 */
export const startOfDayInZonedTimeInUTC = (date, tz) => {
  const formattedDate = format(new Date(date), "yyyy-MM-dd'T'00:00:00")
  const convertToDateFomat = toDate(new Date(formattedDate))

  return zonedTimeToUtc(convertToDateFomat, tz)
}
/**
 * Returns the end of the day in the specified zoned time in UTC.
 *
 * @param {Date} date - The date object.
 * @param {string} tz - The time zone string.
 * @returns {Date} - The end of the day in the specified zoned time in UTC.
 */
export const endOfDayInZonedTimeInUTC = (date, tz) => {
  const formattedDate = format(new Date(date), "yyyy-MM-dd'T'23:59:59")
  const convertToDateFomat = toDate(new Date(formattedDate))

  return zonedTimeToUtc(convertToDateFomat, tz)
}
/**
 * Get date for TeeTimes filter (from & after)
 * @param {Date} date
 * @param {string} timezone
 */
export const getFromAndAfterDateForOneDay = (date, timezone) => {
  const afterDate = startOfDayInZonedTimeInUTC(date, timezone).toISOString()
  const beforeDate = endOfDayInZonedTimeInUTC(date, timezone).toISOString()

  return { afterDate, beforeDate }
}

/**
 * Get dates as start and end of day in local
 *
 * @param {Date} date
 * @returns
 */
export const getStartAndEndOfDateLocal = (date) => {
  const start = new Date(date)
  const end = new Date(date)

  // Validate
  if (typeof start?.getMonth !== 'function' || typeof end?.getMonth !== 'function')
    return [null, null]

  start.setHours(0, 0, 0, 0)
  end.setHours(23, 59, 59, 999)

  return [start, end]
}

/**
 * Get dates as start and end of day in UTC
 *
 * @param {Date} date
 * @returns
 */
export const getStartEndOfDateUTC = (date) => {
  const start = new Date(date)
  const end = new Date(date)

  // Validate
  if (typeof start?.getMonth !== 'function' || typeof end?.getMonth !== 'function')
    return [null, null]

  start.setUTCHours(0, 0, 0, 0)
  end.setUTCHours(23, 59, 59, 999)

  return [start, end]
}

/**
 * Return ISO String but with included offset
 *
 * @param {Date} date
 * @returns
 */
export const toISOStringWithOffset = (date) => {
  let tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num
    }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  )
}

/**
 * Checks if date is valid
 *
 * @param {Date} date
 * @returns
 */
export const isValidDate = (date) => {
  return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime())
}

/**
 * check if date is before today
 *
 * @param {Date} date
 * @returns
 */
export const isDateBeforeToday = (date) => {
  if (!isValidDate(date)) return undefined
  return new Date(date.toDateString()) < new Date(new Date().toDateString())
}

/**
 * Check if date is before now
 *
 * @param {Date} date
 * @returns
 */
export const isDateBeforeNow = (date) => {
  if (!isValidDate(date)) return undefined
  return new Date(date) < new Date()
}

/**
 * Checks if dateOne is before dateTwo
 *
 * @param {Date} dateOne
 * @param {Date} dateTwo
 * @returns
 */
export const isDateBeforeOtherDate = (dateOne, dateTwo) => {
  if (!isValidDate(dateOne) || !isValidDate(dateTwo)) return undefined
  return new Date(dateOne) < new Date(dateTwo)
}

/**
 * Returns date as unix seconds
 *
 * @param {Date} date
 * @returns
 */
export const getUnixSeconds = (date) => {
  if (!isValidDate(date)) return undefined
  return Math.floor(date.getTime() / 1000)
}

export const changeTimezone = (date, timeZone) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      })
    )
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    })
  )
}

export const getStartAndEndOfDateTZ = (date, timezone) => {
  if (!timezone || !date) return [null, null]
  // Preserve original DD/MM/YY to avoid TZ day skip
  const _date = moment(date)
  const selectedDate = {
    year: _date.year(),
    month: _date.month(),
    date: _date.date(),
  }
  const startDay = moment(new Date(date))
    .tz(timezone)
    .set({ ...selectedDate, hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format()
  const endDay = moment(new Date(date))
    .tz(timezone)
    .set({ ...selectedDate, hour: 23, minute: 59, second: 59, millisecond: 999 })
    .format()
  return [startDay, endDay]
}

export const getStartOfDateTZ = (date, timezone) => {
  if (!timezone || !date) return [null, null]
  // Preserve original DD/MM/YY to avoid TZ day skip
  const _date = moment(date)
  const selectedDate = {
    year: _date.year(),
    month: _date.month(),
    date: _date.date(),
  }
  return moment(new Date(date))
    .tz(timezone)
    .set({ ...selectedDate, hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format()
}

export const getEndOfDateTZ = (date, timezone) => {
  if (!timezone || !date) return [null, null]
  // Preserve original DD/MM/YY to avoid TZ day skip
  const _date = moment(date)
  const selectedDate = {
    year: _date.year(),
    month: _date.month(),
    date: _date.date(),
  }
  return moment(new Date(date))
    .tz(timezone)
    .set({ ...selectedDate, hour: 23, minute: 59, second: 59, millisecond: 999 })
    .format()
}

export const onlyDateString = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const getHoursMinsFromISOString = (dateStr) => {
  const [, timeString] = dateStr.split('T')
  const [hours, mins] = timeString.split(':')
  return [hours, mins]
}
