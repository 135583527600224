import React, { MouseEvent, useState } from 'react'
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@sweetspot/scramble-ds'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import DeleteActionItem from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/PeriodActionsPopover/DeleteActionItem'
import EditActionItem from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/PeriodActionsPopover/EditActionItem'

type PeriodActionsPopoverProps = {
  periodUuid: string
}
const PeriodActionsPopover = ({ periodUuid }: PeriodActionsPopoverProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggleDropdown = (
    e:
      | MouseEvent<HTMLDivElement>
      | CustomEvent<{
          originalEvent: PointerEvent | FocusEvent
        }>
  ) => {
    e.preventDefault()
    setIsDropdownOpen(!isDropdownOpen)
  }

  return (
    <Popover open={isDropdownOpen}>
      <PopoverTrigger onClick={(e) => e.preventDefault()} asChild>
        <div className="cursor-pointer" onClick={toggleDropdown}>
          <FontAwesomeIcon icon={faEllipsis} />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="z-50 w-fit p-2 drop-shadow"
        onInteractOutside={toggleDropdown}
      >
        <DeleteActionItem periodUuid={periodUuid} />
        <PopoverArrow className="fill-white" width={16} height={10} />
      </PopoverContent>
    </Popover>
  )
}

export default PeriodActionsPopover
