import { Identify, identify } from '@amplitude/analytics-browser'
import {
  AMPLITUDE_OPERATION_METHODS,
  AMPLITUDE_USER_PROPERTIES,
} from '@sweetspot/shared/util/constants'
import { Club, Venue } from '@sweetspot/shared/types'

type VenueType = {
  [key: string]: string
}

export const updateUserProperty = (
  userProperty: string,
  userValue: string,
  operation = AMPLITUDE_OPERATION_METHODS.SET
) => {
  const identifyEvent = new Identify()

  if (operation === AMPLITUDE_OPERATION_METHODS.POST_INSERT) {
    identifyEvent.postInsert(userProperty, userValue)
  } else {
    identifyEvent.set(userProperty, userValue)
  }

  identify(identifyEvent)
}

export const getVenueType = (venueId: string): string | undefined => {
  const types: VenueType = {
    course: 'Course',
    simulator: 'Simulator',
    driving_range: 'Driving ranges',
    pro: 'Academy',
    other: 'Other',
  }

  return types[venueId]
}

export const getFormattedVenueTypes = (venuesList: Venue[]): string[] => {
  return [
    ...new Set(
      venuesList
        .filter((item) => item.is_active)
        .map((item) => getVenueType(item.type))
        .filter((type): type is string => Boolean(type))
    ),
  ]
}

export const trackClubPropertiesToAmplitude = (club: Club) => {
  updateUserProperty(AMPLITUDE_USER_PROPERTIES.CUSOTMER, club?.name)
  updateUserProperty(AMPLITUDE_USER_PROPERTIES.FEDERATION, club?.git_id ? 'SGF' : 'SGU')
}
