import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { cn } from '../../../utils'

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('bg-background-base-clean h-fit border-b', className)}
    {...props}
  />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionLabel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      data-testid="scramble-accordion-label"
      className={cn('text-content-sm font-bold leading-5', className)}
      {...props}
      ref={ref}
    />
  )
)

AccordionLabel.displayName = 'AccordionLabel'

const AccordionParagraph = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      data-testid="scramble-accordion-paragraph"
      className={cn(
        'text-content-sm line-clamp-2 overflow-hidden text-ellipsis text-start font-normal text-[#6B7280]',
        className
      )}
      {...props}
      ref={ref}
    />
  )
)

AccordionParagraph.displayName = 'AccordionParagraph'

interface AccordionTriggerProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
  // initial direction of the trigger arrow
  direction?: 'up' | 'down'
  childrenClassName?: string
}

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(({ direction = 'down', className, childrenClassName, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex px-4 py-2">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-center justify-between text-sm font-medium transition-all [&[data-state=open]>svg]:rotate-180',
        className
      )}
      {...props}
    >
      {children && (
        <div className={cn('flex flex-col items-start pr-6', childrenClassName)}>{children}</div>
      )}

      <FontAwesomeIcon
        icon={direction === 'down' ? faChevronDown : faChevronUp}
        className="text-muted-foreground h-4 w-4 shrink-0 transition-transform duration-200"
      />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down !bg-background-base-clean text-sm data-[state=closed]:overflow-hidden"
    {...props}
  >
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  AccordionLabel,
  AccordionParagraph,
}
