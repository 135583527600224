import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

import { localizedDaysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import generateTableData from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useWeekPeriod/generate-table-data'
import { Period } from '@sweetspot/shared/types'
import getWeekPeriodOptions from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useOptionsForSelects/getWeekPeriodOptions'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import getTeeTimes from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useWeekPeriod/getTeeTimes'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

import { AllCheckboxValues, PeriodPricingTableData, WeekPeriodOption } from '../types'
import { divideIntoWeeks } from '../utils'

type UsePeriodsPricingProps = {
  selectedPeriod: Period | null
  currentCourse: GolfCourseItem | null
}

const usePeriodsPricing = ({ selectedPeriod, currentCourse }: UsePeriodsPricingProps) => {
  const { t } = useTranslation()
  const [selectedBayIds, setSelectedBayIds] = useState<string[]>([])
  const [price, setPrice] = useState<number>(0)
  const [pricePerExtraPlayer, setPricePerExtraPlayer] = useState<number>(0)
  const [selectedCells, setSelectedCells] = useState<Set<string>>(new Set())
  const [allCells, setAllCells] = useState<Set<string> | null>(null)
  const [allCheckbox, setAllCheckbox] = useState<AllCheckboxValues | null>(null)
  const [tableData, setTableData] = useState<PeriodPricingTableData | null>(null)
  const [activePeriodTab, setActivePeriodTab] = useState<string>()
  const [selectedCols, setSelectedCols] = useState<boolean[] | null>(null)
  const [selectedRows, setSelectedRows] = useState<boolean[] | null>(null)

  const [currency] = useClubCurrency()

  // if form has been updated
  const hasUpdated = false

  const weekPeriodOptions: WeekPeriodOption[] = useMemo(() => {
    const weeks = divideIntoWeeks(selectedPeriod?.start_date, selectedPeriod?.end_date)
    return getWeekPeriodOptions(selectedPeriod, t, currentCourse?.timezone).map(
      (option: WeekPeriodOption, index: number) => {
        return {
          ...option,
          name:
            option.id === -1 ? option.name : `${weeks[index].startDate} - ${weeks[index].endDate}`,
        }
      }
    )
  }, [selectedPeriod, t, currentCourse])

  const selectedWeek = useMemo(
    () =>
      weekPeriodOptions.find((option) => `${option.id}` === activePeriodTab) ||
      weekPeriodOptions[0] ||
      {},
    [weekPeriodOptions, activePeriodTab]
  )

  const DAYS_OF_WEEK = useMemo(() => localizedDaysOfWeek('en'), [])

  const { data: teeTimes, isLoading } = useQuery(
    ['TEE_TIMES', selectedPeriod?.id, selectedWeek?.id, selectedBayIds[0]],
    () => {
      // setValue('hasUpdated', true)
      return getTeeTimes(currentCourse, selectedWeek, selectedBayIds[0])
    }
  )

  // initialize allSells and selectedCells
  useEffect(() => {
    if (tableData?.data) {
      const allCells: Set<string> = new Set()
      for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
        const dayOfWeek = DAYS_OF_WEEK[i]
        const slots = tableData?.data[dayOfWeek]
        if (!slots) continue
        for (let j = 0; j < slots.length; j++) {
          allCells.add(`${j}-${i}`)
        }
      }
      setAllCells(allCells)
      setSelectedCells(new Set())
    }
  }, [DAYS_OF_WEEK, tableData])

  // set values for allCheckbox, selectedRows and selectedCols
  useEffect(() => {
    const allCheckbox =
      allCells && selectedCells.size === allCells.size
        ? AllCheckboxValues.ALL
        : selectedCells.size > 0
        ? AllCheckboxValues.SOME
        : null
    setAllCheckbox(allCheckbox)

    setSelectedRows(
      Array(tableData?.timeSlots.length)
        .fill(null)
        .map((_, idxRow) => {
          if (!tableData?.timeSlots.length) return false
          for (let idxCol = 0; idxCol < 7; idxCol++) {
            if (
              tableData?.data[DAYS_OF_WEEK[idxCol]] &&
              !selectedCells.has(`${idxRow}-${idxCol}`)
            ) {
              return false
            }
          }
          return true
        })
    )

    const selectedCols = Array(7)
      .fill(null)
      .map((_, idxCol) => {
        if (!tableData?.timeSlots.length) return false
        for (let idxRow = 0; idxRow < tableData?.timeSlots.length; idxRow++) {
          if (!selectedCells.has(`${idxRow}-${idxCol}`)) {
            return false
          }
        }
        return true
      })
    setSelectedCols(selectedCols)
  }, [allCells, selectedCells, tableData?.timeSlots, tableData?.data, DAYS_OF_WEEK])

  // populate table with data
  useEffect(() => {
    if (teeTimes && (!tableData || hasUpdated)) {
      // setValue('hasUpdated', false)
      setTableData(
        generateTableData({
          timePeriod: selectedPeriod,
          teeTimes,
          selectedWeek,
          space: selectedBayIds[0],
          currency,
          timezone: currentCourse?.timezone,
        }) as PeriodPricingTableData
      )
    }
  }, [
    selectedPeriod,
    teeTimes,
    selectedWeek,
    selectedBayIds,
    hasUpdated,
    tableData,
    currency,
    currentCourse?.timezone,
  ])

  const onAllCheckboxChange = useCallback(
    (value: boolean) => {
      if (value) {
        setSelectedCells(new Set(allCells))
      } else {
        setSelectedCells(new Set())
      }
    },
    [allCells]
  )

  const onColumnClick = useCallback(
    (col: number, isSelected?: boolean) => {
      if (tableData?.data[DAYS_OF_WEEK[col]]) {
        setSelectedCells((curr) => {
          const setCopy = new Set(curr)
          Array(tableData?.timeSlots.length)
            .fill(null)
            .forEach((_, idx) => {
              setCopy[isSelected ? 'delete' : 'add'](`${idx}-${col}`)
            })
          return setCopy
        })
      }
    },
    [DAYS_OF_WEEK, tableData?.data, tableData?.timeSlots]
  )

  const onRowClick = useCallback(
    (row: number, isSelected?: boolean) => {
      setSelectedCells((curr) => {
        const setCopy = new Set(curr)
        Array(7)
          .fill(null)
          .forEach((_, idx) => {
            const cellId = `${row}-${idx}`
            if (allCells?.has(cellId)) {
              setCopy[isSelected ? 'delete' : 'add'](cellId)
            }
          })
        return setCopy
      })
    },
    [allCells]
  )

  const onCellClick = useCallback(
    (cellId: string, isSelected?: boolean) => {
      setSelectedCells((curr) => {
        const setCopy = new Set(curr)
        if (isSelected) {
          setCopy.delete(cellId)
        } else {
          if (allCells?.has(cellId)) setCopy.add(cellId)
        }
        return setCopy
      })
    },
    [allCells]
  )

  return {
    pricings: [],
    groupOptions: [],
    selectedBayIds,
    setSelectedBayIds,
    price,
    setPrice,
    pricePerExtraPlayer,
    setPricePerExtraPlayer,
    selectedCells,
    setSelectedCells,
    allCheckbox,
    onAllCheckboxChange,
    DAYS_OF_WEEK,
    activePeriodTab,
    setActivePeriodTab,
    weekPeriodOptions,
    timeSlots: tableData?.timeSlots || [],
    tableData: tableData?.data || {},
    onColumnClick,
    selectedCols,
    onRowClick,
    selectedRows,
    onCellClick,
  }
}

export default usePeriodsPricing
