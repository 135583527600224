import { useEffect } from 'react'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@sweetspot/scramble-ds'

import { useAvailabilityAndPricingContext } from '../../AvailabilityAndPricingProvider'
import PricingPeriodTabContent from './PricingPeriodTabContent/PricingPeriodTabContent'

const PricingPeriodTabs = () => {
  const { activePeriodTab, setActivePeriodTab, weekPeriodOptions } =
    useAvailabilityAndPricingContext()

  // set active tab to the first one
  useEffect(() => {
    if (weekPeriodOptions.length) {
      setActivePeriodTab(`${weekPeriodOptions[0].id}`)
    }
  }, [weekPeriodOptions, setActivePeriodTab])

  return (
    <div className="flex w-full pt-8">
      <Tabs
        value={activePeriodTab}
        onValueChange={(tab) => setActivePeriodTab(tab)}
        className="h-full w-full"
      >
        <TabsList className="justify-start rounded-none border-b">
          {weekPeriodOptions.map((option) => (
            <TabsTrigger value={`${option.id}`} className="-mb-1.5 w-auto px-4">
              {option.name}
            </TabsTrigger>
          ))}
        </TabsList>
        {weekPeriodOptions.map((option) => (
          <TabsContent value={`${option.id}`} className="h-[calc(100%-48px)]">
            <PricingPeriodTabContent />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  )
}

export default PricingPeriodTabs
