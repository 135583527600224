import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputTrailingContainer,
} from '@sweetspot/scramble-ds'
import getWeekPeriodOptions from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useOptionsForSelects/getWeekPeriodOptions'
import { GolfCourseTypes } from '@sweetspot/shared/types'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

import { useAvailabilityAndPricingContext } from '../../../AvailabilityAndPricingProvider'

const MIN_PRICE = 0
const MAX_PRICE = 99999
const MIN_PRICE_PER_EXTRA_PLAYER = 0
const MAX_PRICE_PER_EXTRA_PLAYER = 9999

const PricingTableFilters = () => {
  const { t } = useTranslation()
  const [currency] = useClubCurrency()
  const {
    currentCourse,
    price,
    setPrice,
    pricePerExtraPlayer,
    setPricePerExtraPlayer,
    selectedCells,
  } = useAvailabilityAndPricingContext()

  const hasExtraPlayers = useMemo(
    () => currentCourse?.type !== GolfCourseTypes.COURSE,
    [currentCourse?.type]
  )

  const isApplyDisabled = useMemo(
    () =>
      selectedCells.size === 0 ||
      typeof price !== 'number' ||
      (hasExtraPlayers && typeof pricePerExtraPlayer !== 'number'),
    [price, pricePerExtraPlayer, selectedCells, hasExtraPlayers]
  )

  const preventedSymbols = useMemo(() => ['e', 'E', '+', '-'], [])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const isPreventedSymbol = preventedSymbols.includes(e.key)
      if (isPreventedSymbol) {
        e.preventDefault()
      }
    },
    [preventedSymbols]
  )

  const handleOnPriceChange = useCallback(
    (value: string) => {
      const newValue = Math.max(MIN_PRICE, Math.min(MAX_PRICE, Number(value))).toString()
      setPrice(Number(parseFloat(newValue).toFixed(2)))
    },
    [setPrice]
  )

  const handleOnPriceExtraChange = useCallback(
    (value: string) => {
      const newValue = Math.max(
        MIN_PRICE_PER_EXTRA_PLAYER,
        Math.min(MAX_PRICE_PER_EXTRA_PLAYER, Number(value))
      ).toString()
      setPricePerExtraPlayer(Number(parseFloat(newValue).toFixed(2)))
    },
    [setPricePerExtraPlayer]
  )

  return (
    <div className="flex items-end gap-4">
      <InputBase className="w-[146px]">
        <InputLabelContainer>{t('editPeriodsNew.price')}</InputLabelContainer>
        <InputContainer>
          <Input
            value={price}
            onChange={(e) => handleOnPriceChange(e.target.value)}
            onKeyDown={handleKeyDown}
            className="text-content-base border-border-stroke-clean h-8 border-[1px] px-4"
            type="number"
            min={MIN_PRICE}
            max={MAX_PRICE}
          />
          <InputTrailingContainer className="text-content-sm text-text-dark right-4 font-medium">
            <p>{currency}</p>
          </InputTrailingContainer>
        </InputContainer>
      </InputBase>

      {hasExtraPlayers && (
        <InputBase className="w-[146px]">
          <InputLabelContainer>{t('editPeriodsNew.pricePerExtraPlayer')}</InputLabelContainer>
          <InputContainer>
            <Input
              value={pricePerExtraPlayer}
              onChange={(e) => handleOnPriceExtraChange(e.target.value)}
              onKeyDown={handleKeyDown}
              className="text-content-base border-border-stroke-clean h-8 border-[1px] px-4"
              type="number"
              min={MIN_PRICE_PER_EXTRA_PLAYER}
              max={MAX_PRICE_PER_EXTRA_PLAYER}
            />
            <InputTrailingContainer className="text-content-sm text-text-dark right-4 font-medium">
              <p>{currency}</p>
            </InputTrailingContainer>
          </InputContainer>
        </InputBase>
      )}

      <Button variant="ghost-dark" className="h-8 w-[146px]" disabled={isApplyDisabled}>
        {t('words.apply')}
      </Button>
    </div>
  )
}

export default PricingTableFilters
