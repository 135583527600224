import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classname from 'classnames'
import { t } from 'i18next'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import DOMHelpers from '@sweetspot/club-portal-legacy/helpers/DOMHelpers'

import { ReactComponent as CaretIcon } from '@sweetspot/club-portal-legacy/resources/images/caret.svg'
import style from './style.module.scss'

/**
 * This component creates a standard buttonDropdown.
 */
export class ButtonDropdown extends Component {
  state = {
    isShowActions: false,
    itemId: '',
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick = (event) => {
    if (!DOMHelpers.didClickInside(this._container, event)) {
      this.setState({ isShowActions: false })
    }
  }

  handleMouseOut = (event) => {
    if (!DOMHelpers.didClickInside(this._subActions, event)) {
      this.setState({ itemId: '' })
    }
  }

  toggleShowActions = () => {
    this.setState({
      isShowActions: !this.state.isShowActions,
    })
  }

  renderSubItems = ({ id, subOptions }) => {
    if (!subOptions || !subOptions.length) return
    const { onClick, fromRight, noItemWrap, subItemClassName } = this.props

    return (
      <div
        className={classname(
          style.subItemsList,
          fromRight && style.fromRight,
          noItemWrap && style.noItemWrap,
          subItemClassName
        )}
        ref={(element) => (this._subActions = element)}
      >
        {subOptions.map((subItem) => (
          <div
            key={subItem.id}
            className={classname(style.subItem, { [style.disabled]: subItem.disabled })}
            onClick={() => !subItem.disabled && onClick(id, subItem.id)}
          >
            {subItem.name}
          </div>
        ))}
      </div>
    )
  }

  renderActionList = () => {
    if (!this.state.isShowActions) return
    const { itemId } = this.state
    const {
      values,
      actionStyles,
      onClick,
      fromRight,
      noItemWrap,
      listItemClassName = '',
      actionsListClassName = '',
    } = this.props

    return (
      // Set height based on number of actions

      <div
        className={classname(
          style.list,
          fromRight && style.fromRight,
          noItemWrap && style.noItemWrap,
          actionsListClassName
        )}
        style={actionStyles}
      >
        {values.map((value, index) => (
          <div
            key={index}
            className={classname(style.listItem, listItemClassName, {
              [style.disabled]: value.disabled,
            })}
            onMouseOver={() => this.setState({ itemId: value.id })}
            onMouseOut={this.handleMouseOut}
            onClick={() => !value.subOptions && !value.disabled && onClick(value.id)}
          >
            {value.label && <Text textId={value.label} />}
            {value.name && <span>{value.name}</span>}
            {value.subListItem && !!value.subListItem.length && <CaretIcon />}
            {itemId === value.id && !value.disabled && this.renderSubItems(value)}
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { disabled, text } = this.props

    return (
      <div className={style.container} ref={(container) => (this._container = container)}>
        <button
          disabled={disabled}
          onClick={this.toggleShowActions}
          className={`system-button info-outline md-32`}
        >
          <div className={style.buttonText}>
            {t(text)} &nbsp;
            <CaretIcon className={style.image} />
          </div>
          {this.renderActionList()}
        </button>
      </div>
    )
  }
}

ButtonDropdown.propTypes = {
  /**
   * String (textId) for action button
   */
  text: PropTypes.string.isRequired,
  /**
   * Bool to disabled action button
   */
  disabled: PropTypes.bool,
  /**
   * Object to styles actions list
   */
  actionStyles: PropTypes.object,
  /**
   * Array to show list of actions
   */
  values: PropTypes.array.isRequired,
  /**
   * Function to run when clicking on each item in the action list
   */
  onClick: PropTypes.func.isRequired,

  fromRight: PropTypes.bool,

  /**
   * Boolean to control if list item content is shown in single line
   */
  noItemWrap: PropTypes.bool,
  /**
   * a className added to each list item
   */
  listItemClassName: PropTypes.string,
  /**
   * className string for the actions list popup
   */
  actionsListClassName: PropTypes.string,
  /**
   * className string for the actions list popup
   */
  subItemClassName: PropTypes.string,
}

export default ButtonDropdown
