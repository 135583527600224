import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { createRange, CreateRangePayload } from '@sweetspot/sweetspot-js/features/ranges/services'
import { Venue, ViolationError } from '@sweetspot/shared/types'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'

import { useManageRangeContext } from '../ManageRangeProvider'
import { isViolationError } from '@sweetspot/shared/util/validators'

const useCreateRangeMutation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()
  const { toggleCreateRangeSideBar, refetchRanges } = useManageRangeContext()

  const createRangeMutation = useMutation((data: CreateRangePayload) => createRange(data), {
    onSuccess: async (response) => {
      addToast(t('toast.courseCreated', { type: t('words.drivingRange') }), {
        appearance: 'success',
      })
      refetchRanges()
      toggleCreateRangeSideBar()
      const path = history.location.pathname
      history.push(`${path}#${response?.id}`)
      // the corresponding course needs a bit time to be created after the range is created
      setTimeout(async () => {
        const courses: Venue[] = await queryCourses({ uuid: response.external_reference })
        if (courses?.length) {
          dispatch({
            type: CONSTANTS.GOLF_COURSE.CREATE,
            payload: courses[0],
          })
        }
      }, 2000)
    },
    onError: (error: { error: string } | ViolationError) => {
      if (isViolationError(error)) {
        addToast(t('toast.defaultError'), { appearance: 'error' })
      } else {
        if (error?.error?.includes('name: Range with this name already exists.')) {
          addToast(t('sentences.rangeNameAlreadyExists'), { appearance: 'error' })
        } else {
          addToast(t('toast.defaultError'), { appearance: 'error' })
        }
      }
    },
  })

  return createRangeMutation
}

export default useCreateRangeMutation
