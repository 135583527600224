import Header from './Header'
import CreateSimulatorSideBar from './CreateSimulatorSideBar/CreateSimulatorSideBar'
import { ManageSimulatorProvider } from './ManageSimulatorProvider'
import FilterToolbar from './FilterToolbar/FilterToolbar'
import SimulatorTabsContainer from './SimulatorTabsContainer/SimulatorTabsContainer'

const ManageSimulators = () => {
  return (
    <ManageSimulatorProvider>
      <CreateSimulatorSideBar />
      <div className="flex h-full flex-col">
        <Header />
        <FilterToolbar />
        <div className={'flex flex-1 items-center justify-center'}>
          <SimulatorTabsContainer />
        </div>
      </div>
    </ManageSimulatorProvider>
  )
}

export default ManageSimulators
