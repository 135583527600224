import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import useSimulators from '@sweetspot/club-portal-legacy/hooks/useSimulators'
import useSimulatorSpaces from '@sweetspot/club-portal-legacy/hooks/useSimulatorSpaces'

import { ManageSimulatorSpacesContextValue, SpaceSideBarMode } from './types'

export const ManageSimulatorSpaces = createContext<ManageSimulatorSpacesContextValue>({
  areSimulatorsLoading: false,
  simulators: [],
  areSpacesLoading: false,
  areSpacesFetching: false,
  spaces: [],
  openSpaceSideBar: false,
  openDiscardDialog: false,
  setSelectedSpace: () => {
    return
  },
  toggleSpaceSideBar: () => {
    return
  },
  refetchSpaces: () => {
    return
  },
  changeSpaceSideBarMode: () => {
    return
  },
  setOpenDiscardDialog: () => {
    return
  },
  refetchSimulators: () => {
    return
  },
  updateSpace: () => {
    return Promise.resolve()
  },
  createSpace: () => {
    return Promise.resolve()
  },
  deleteSpace: () => {
    return Promise.resolve()
  },
})

export const ManageSimulatorSpacesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openSpaceSideBar, setOpenSpaceSideBar] = useState(false)
  const [spaceSideBarMode, setSpaceSideBarMode] = useState<SpaceSideBarMode | undefined>()
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false)
  const {
    simulators,
    areSimulatorsLoading,
    selectedSimulator,
    setSelectedSimulator,
    refetchSimulators,
  } = useSimulators()
  const {
    spaces,
    areSpacesLoading,
    setSelectedSpace,
    selectedSpace,
    refetch: refetchSpaces,
    areSpacesFetching,
    updateSpace,
    createSpace,
    deleteSpace,
    checkForErrors,
  } = useSimulatorSpaces({
    selectedSimulatorId: selectedSimulator?.id,
  })

  const toggleSpaceSideBar = useCallback(
    (mode?: SpaceSideBarMode) => {
      setSpaceSideBarMode(openSpaceSideBar ? undefined : mode)
      setOpenSpaceSideBar((prev) => !prev)
    },
    [openSpaceSideBar]
  )

  const value = useMemo(
    () => ({
      simulators,
      areSimulatorsLoading,
      selectedSimulator,
      setSelectedSimulator,
      refetchSimulators,
      spaces,
      areSpacesLoading,
      areSpacesFetching,
      setSelectedSpace,
      selectedSpace,
      openSpaceSideBar,
      toggleSpaceSideBar,
      refetchSpaces,
      spaceSideBarMode,
      changeSpaceSideBarMode: setSpaceSideBarMode,
      openDiscardDialog,
      setOpenDiscardDialog,
      updateSpace,
      createSpace,
      deleteSpace,
      checkForErrors,
    }),
    [
      areSimulatorsLoading,
      simulators,
      selectedSimulator,
      setSelectedSimulator,
      refetchSimulators,
      spaces,
      areSpacesFetching,
      areSpacesLoading,
      setSelectedSpace,
      selectedSpace,
      openSpaceSideBar,
      toggleSpaceSideBar,
      refetchSpaces,
      spaceSideBarMode,
      setSpaceSideBarMode,
      openDiscardDialog,
      setOpenDiscardDialog,
      updateSpace,
      createSpace,
      deleteSpace,
      checkForErrors,
    ]
  )

  return <ManageSimulatorSpaces.Provider value={value}>{children}</ManageSimulatorSpaces.Provider>
}

export const useManageSimulatorSpacesContext = () => useContext(ManageSimulatorSpaces)
