import { useTranslation } from 'react-i18next'

import {
  Input,
  InputBase,
  InputContainer,
  InputHint,
  InputLabelContainer,
  InputTrailingContainer,
} from '@sweetspot/scramble-ds'

type DetailsProps = {
  name: string
  vat: string
  setName: (name: string) => void
  setVat: (vat: string) => void
}

const Details = ({ name, setName, vat, setVat }: DetailsProps) => {
  const { t } = useTranslation()

  return (
    <div className="pt-md pb-xl flex flex-row flex-wrap gap-6">
      <InputBase>
        <InputLabelContainer>{t('settings.name')}</InputLabelContainer>
        <InputContainer>
          <Input
            className="text-content-base h-8 border"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs items-center [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.nameHint', { type: t('words.drivingRange').toLowerCase() })}
        />
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('settings.vatBookings')}</InputLabelContainer>
        <InputContainer>
          <Input
            className="text-content-base h-8 border"
            value={vat}
            onChange={(e) => setVat(e.target.value)}
          />
          <InputTrailingContainer className="right-2">
            <p>%</p>
          </InputTrailingContainer>
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs items-center [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.vatHint')}
        />
      </InputBase>

      <div className="mt-2 flex w-full flex-col">
        <p className="text-content-base font-bold">
          {t('settings.bottomTextTitle', { type: t('words.range_one').toLowerCase() })}
        </p>
        <p className="text-content-sm text-text-subtle">
          {t('settings.bottomTextDescription', { type: t('words.range_one').toLowerCase() })}
        </p>
      </div>
    </div>
  )
}

export default Details
