import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { _deleteGolfCourse } from '@sweetspot/club-portal-legacy/services/golfCourseApi'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'

import { useManageAcademyContext } from '../ManageAcademyProvider'

const useDeleteAcademyMutation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()
  const { refetchAcademies, academies } = useManageAcademyContext()

  const deleteAcademyMutation = useMutation((id: number) => _deleteGolfCourse(id), {
    onSuccess: (_, id) => {
      addToast(t('toast.courseDeleted', { type: t('words.academy') }), {
        appearance: 'success',
      })
      const path = history.location.pathname
      const selectedAcademy = academies.find((academy) => academy.id !== id)
      history.push(`${path}#${selectedAcademy?.id}`)
      // the corresponding course needs a bit time to be created after the academy is created
      setTimeout(async () => {
        refetchAcademies()
        dispatch({
          type: CONSTANTS.GOLF_COURSE.DELETE,
          payload: id,
        })
      }, 2000)
    },
    onError: () => {
      addToast(t('toast.defaultError'), { appearance: 'error' })
    },
  })

  return deleteAcademyMutation
}

export default useDeleteAcademyMutation
