import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Toggle,
} from '@sweetspot/scramble-ds'
import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import { GolfCourse } from '@sweetspot/shared/types'

import { AcademyTabs as Tabs } from '../types'
import GeneralTab from './GeneralTab/GeneralTab'
import BookingTab from './BookingTab/BookingTab'
import PaymentTab from './PaymentTab/PaymentTab'
import useUpdateAcademy from '../hooks/useUpdateAcademy'
import ActivateDialog from './ActivateDialog'
import { useManageAcademyContext } from '../ManageAcademyProvider'
import DeleteDialog from './DeleteDialog'
import useDeleteAcademyMutation from '../hooks/useDeleteAcademyMutation'

type AcademyTabsProps = {
  currentCourse?: GolfCourse
  refetchCourses: () => void
}

const AcademyTabs = ({ currentCourse, refetchCourses }: AcademyTabsProps) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.GENERAL)
  const [isAcademyActive, setIsAcademyActive] = useState<boolean>(false)
  const [openActivateDialog, setOpenActivateDialog] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const history = useHistory()
  const { updateAcademy } = useUpdateAcademy({ currentCourse })
  const { discardDialogCallbacks, openDiscardDialog } = useManageAcademyContext()
  const deleteAcademyMutation = useDeleteAcademyMutation()

  useEffect(() => {
    setIsAcademyActive(currentCourse?.is_active ?? false)
  }, [currentCourse?.is_active])

  // set active tab based on hash
  useEffect(() => {
    const hash = (history.location.hash?.slice(1) as Tabs) || Tabs.GENERAL
    if (hash && Object.values(Tabs).includes(hash)) setActiveTab(hash)
  }, [history.location.hash])

  const toggleActive = useCallback(() => {
    const isActive = !isAcademyActive
    setOpenActivateDialog(false)
    setIsAcademyActive(isActive)
    updateAcademy({ isActive })
    refetchCourses()
  }, [updateAcademy, refetchCourses, isAcademyActive])

  const onDelete = useCallback(async () => {
    if (currentCourse?.id) {
      deleteAcademyMutation.mutateAsync(currentCourse.id).then(() => {
        setOpenDeleteDialog(false)
      })
    }
  }, [currentCourse?.id, deleteAcademyMutation])

  const renderContent = useCallback(() => {
    if (activeTab === Tabs.GENERAL)
      return <GeneralTab currentCourse={currentCourse} refetchCourses={refetchCourses} />
    if (activeTab === Tabs.BOOKING)
      return <BookingTab currentCourse={currentCourse} refetchCourses={refetchCourses} />
    if (activeTab === Tabs.PAYMENT)
      return <PaymentTab currentCourse={currentCourse} refetchCourses={refetchCourses} />
    return null
  }, [activeTab, currentCourse, refetchCourses])

  return (
    <div className="flex h-full flex-1 flex-col gap-8">
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => discardDialogCallbacks?.onClose()}
        onDiscard={() => discardDialogCallbacks?.onDiscard()}
        onSave={() => discardDialogCallbacks?.onSave()}
      />
      <ActivateDialog
        open={openActivateDialog}
        setOpen={setOpenActivateDialog}
        isAcademyActive={isAcademyActive}
        onSave={toggleActive}
      />
      <DeleteDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} onDelete={onDelete} />
      <div className="border-b pt-2">
        <div className="flex items-center justify-between px-6">
          <SimpleTabs
            className="h-8 !w-auto gap-0.5 pb-0 [&_span]:!hidden"
            labelClassName="w-[106px] after:!scale-x-100 !mb-[-1px]"
            activeLabelClassName="!font-bold !text-text-dark after:!border-[#171717]"
            inActiveLabelClassName="!font-medium !text-text-subtle after:!border-[#D1D5DB]"
            currentItem={activeTab}
            onClick={(tab) => history.push(`#${tab}`)}
            items={[
              { id: Tabs.GENERAL, label: t('words.general') },
              { id: Tabs.BOOKING, label: t('words.booking_one') },
              { id: Tabs.PAYMENT, label: t('words.payment_one') },
            ]}
          />

          <div className="flex w-fit items-center gap-4">
            <div className="pl-md flex min-w-fit items-center gap-2">
              <p className="text-content-sm text-text-dark">
                <span className="font-medium">
                  {isAcademyActive
                    ? t('settings.courseActive', { type: t('words.academy') })
                    : t('settings.courseInactive', { type: t('words.academy') })}
                </span>
                {isAcademyActive
                  ? ` (${t('settings.courseVisible').toLowerCase()})`
                  : ` (${t('settings.courseNotVisible').toLowerCase()})`}
              </p>
              <Toggle
                checked={isAcademyActive}
                onCheckedChange={() => setOpenActivateDialog(true)}
              />
            </div>

            <Popover open={isDropdownOpen}>
              <PopoverTrigger asChild>
                <div
                  className="border-border-stroke-clean cursor-pointer border-l px-4"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <i className="fa-regular fa-ellipsis" />
                </div>
              </PopoverTrigger>
              <PopoverContent
                align="end"
                className="w-fit p-2 drop-shadow"
                onInteractOutside={() => setIsDropdownOpen(false)}
              >
                <ListItem
                  className="text-text-dark w-full min-w-fit cursor-pointer gap-5 px-2 py-3 hover:bg-gray-50"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  <ListItemLeading className="px-0">
                    <i className="fa-regular fa-trash-can" />
                  </ListItemLeading>
                  <ListItemMainContainer>
                    <ListItemMainContent className="py-0">
                      <ListItemMainLabel className="h-auto min-h-6 font-bold">
                        {t('sentences.deleteCourse', { type: t('words.academy').toLowerCase() })}
                      </ListItemMainLabel>
                    </ListItemMainContent>
                  </ListItemMainContainer>
                </ListItem>

                <PopoverArrow className="fill-white" width={16} height={10} />
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
      <div className="px-6 pb-[88px]">{renderContent()}</div>
    </div>
  )
}

export default AcademyTabs
