import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { ItemSlotInfoItem } from './ItemSlotInfoItem'

const SpaceBox = ({
  hasOwner,
  space,
  currencyCode,
  className,
  onRemove,
  removeDisabled,
  timezone,
  canRemove,
  teeTimes,
}) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.headerContainer)}>
        <h3 className={cx(styles.spaceTitle)}>{space.name}</h3>
        {canRemove && (
          <div
            className={cx(styles.removeIconContainer, removeDisabled ? styles.removeDisabled : '')}
          >
            <FontAwesomeIcon
              onClick={() => !removeDisabled && onRemove()}
              icon={faTrashCan}
              className={cx(styles.removeIcon)}
            />
          </div>
        )}
      </div>
      {teeTimes && (
        <div className={'flex flex-col gap-2'}>
          {teeTimes.map((teeTime) => (
            <ItemSlotInfoItem
              teeTime={teeTime}
              key={teeTime.uuid}
              timezone={timezone}
              hasOwner={hasOwner}
              currencyCode={currencyCode}
              bookedSlots={teeTime?.players ?? 0}
            />
          ))}
        </div>
      )}
    </div>
  )
}

SpaceBox.propTypes = {
  className: PropTypes.string,
  space: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  checkboxDisabled: PropTypes.bool,
}

SpaceBox.defaultProps = {
  className: '',
  space: null,
  isSelected: false,
  checkboxDisabled: true,
}

export default SpaceBox
