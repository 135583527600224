import { DiscardDialogCallbacks } from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/types'
import {
  OutletActivationPaylaod,
  OutletStatus,
  RangeWithBallDispensers,
} from '@sweetspot/shared/data-access/api-platform'
import { Range } from '@sweetspot/shared/types'

export type BallDispensersContextValue = {
  areRangesLoading: boolean
  selectedRange?: Range
  setSelectedRange?: (range: Range) => void
  ranges: Range[]
  openDiscardDialog?: boolean
  setOpenDiscardDialog?: (open: boolean) => void
  statusInitialData?: StatusBallDispenser[]
  setupInitialData: SetupFormFields
  integrationInitialData: IntegrationFormFields
  handleSetupSave?: (values: SetupFormFields) => Promise<RangeWithBallDispensers> | undefined
  handleIntegrationSave?: (
    values: IntegrationFormFields
  ) => Promise<RangeWithBallDispensers> | undefined
  handleOutletActivation: (values: OutletActivationPaylaod) => void
  areBallDispensersFetching: boolean
  discardDialogCallbacks?: DiscardDialogCallbacks
  setDiscardDialogCallbacks?: (callbacks: DiscardDialogCallbacks) => void
}

export type StatusBallDispenser = {
  name?: string
  dispenserIndex?: number
  outlet?: string | null
  outletId?: string
  status?: boolean
  outletStatus?: OutletStatus
  terminalStatus?: TerminalStatus | string
  ballQuantity?: BallQuantity | string
  assetId?: string
}

export type SetupFormFields = {
  ballDispensers: SetupBallDispenser[]
}

export type SetupBallDispenser = {
  id?: string
  name?: string
  brand: string
  nrOfOutlets: number
  outletOneName?: string
  outletTwoName?: string
}

export type IntegrationFormFields = {
  siteId?: string
  ballDispensers: IntegrationBallDispenser[]
}

export type IntegrationBallDispenser = {
  id?: string
  name?: string
  outlets: Outlet[]
}

export type Outlet = {
  id?: string
  name?: string
  terminalModel?: string
  terminalSerialNumber?: string
  assetId?: string
}

export enum Tabs {
  STATUS = 'status',
  SETUP = 'setup',
  INTEGRATIONS = 'integrations',
}

export enum TerminalStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  INACTIVE = 'inactive',
}

export enum BallQuantity {
  LOADED = 'loaded',
  LOW = 'low',
}
