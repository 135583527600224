import { useManageSimulatorSpacesContext } from '../ManageSimulatorSpacesProvider'
import { SpaceSideBarMode } from '../types'
import CreateSpace from './CreateSpace'
import UpdateSpace from './UpdateSpace'
import SpaceDetails from './SpaceDetails'

const SpacesSideBar = () => {
  const { spaceSideBarMode } = useManageSimulatorSpacesContext()

  if (spaceSideBarMode === SpaceSideBarMode.VIEW) {
    return <SpaceDetails />
  }

  if (spaceSideBarMode === SpaceSideBarMode.CREATE) {
    return <CreateSpace />
  }

  if (spaceSideBarMode === SpaceSideBarMode.EDIT) {
    return <UpdateSpace />
  }

  return null
}

export default SpacesSideBar
