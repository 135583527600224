import { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import {
  Button,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { CreateRangePayload } from '@sweetspot/sweetspot-js/features/ranges/services'
import { useManageRangeContext } from '../ManageRangeProvider'
import { DEFAULT_CANCEL_POINT } from '../constants'
import Details from './Details'
import useCreateRangeMutation from '../hooks/useCreateRangeMutation'

const CreateRangeSideBar = () => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [vat, setVat] = useState<string>('')
  const { addToast } = useToasts()
  const { toggleCreateRangeSideBar, openCreateRangeSidebar } = useManageRangeContext()
  const createRangeMutation = useCreateRangeMutation()
  const currentClubUuid = useSelector((state: RootState) => {
    const clubUuId = state?.golfClub?.list?.find(
      (club) => club.id === state?.golfClub?.selectedId || ''
    )?.uuid
    return clubUuId as string
  })

  const resetForm = useCallback(() => {
    setName('')
    setVat('')
  }, [])

  useEffect(() => {
    // reset form when the sidebar is closed
    resetForm()
  }, [openCreateRangeSidebar, resetForm])

  const checkForErrors = useCallback(
    ({ name, vat }: Pick<CreateRangePayload, 'name' | 'vat'>): string => {
      if (name?.trim() === '') return 'settings.nameRequired'
      if (!vat || vat < 1 || vat > 100)
        return `${t('words.vat')}: ${t('errors.valueMustBeBetweenThese', { this: 1, that: 100 })}`
      return ''
    },
    [t]
  )

  const handleCreateRange = useCallback(async () => {
    const payload: CreateRangePayload = {
      organizationId: currentClubUuid,
      name,
      vat: parseFloat(vat),
      displayTeeTimeDays: 30,
      isActive: false,
      isCanPay: true,
      isArrivalRegistration: false,
      isArrivalRegistrationAfterSchedule: false,
      bookingCancellationLimitHours: DEFAULT_CANCEL_POINT,
      nonConfirmedBookingAutoCancellationEnabled: false,
      nonConfirmedBookingAutoCancellationDuration: null,
      unpaidBookingAutoCancellationEnabled: false,
      isStubPlayersEnabled: false,
      isPayOnSiteEnabled: false,
      is_enabled: false,
      membershipSignUpSettings: {
        isEnabled: false,
        memberships: [],
      },
    }
    const errorText = checkForErrors(payload)

    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else {
      createRangeMutation.mutate(payload)
    }
  }, [name, vat, currentClubUuid, addToast, t, checkForErrors, createRangeMutation])

  return (
    <Sheet open={openCreateRangeSidebar} onOpenChange={toggleCreateRangeSideBar}>
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={toggleCreateRangeSideBar}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>
            {t('settings.createNewCourse', { type: t('words.range_one').toLowerCase() })}
          </SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <Details name={name} setName={setName} vat={vat} setVat={setVat} />
        </SheetCustomContent>
        <SheetFooter>
          <Button onClick={handleCreateRange} className="min-h-touch-height-lg w-full">
            {t('create')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default CreateRangeSideBar
