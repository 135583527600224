import { useMemo } from 'react'
import { useManageSimulatorContext } from '../ManageSimulatorProvider'
import SimulatorSelector from './SimulatorSelector'

const FilterToolbar = () => {
  const { simulators } = useManageSimulatorContext()
  const hasSimulators = useMemo(() => simulators?.length > 0, [simulators])

  if (!hasSimulators) return null

  return (
    <div className={'flex items-end justify-between px-6 py-4'}>
      <div className={'w-[398px]'}>
        <SimulatorSelector />
      </div>
    </div>
  )
}

export default FilterToolbar
