/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import { useStore } from 'zustand'
import { tv, type VariantProps } from 'tailwind-variants'
import { cn } from '../../../../utils'
import { useInputBaseStoreContext } from '../store'

const inputVariants = tv({
  base: cn(
    'p-md text-content-lg flex h-12 w-full border-2 bg-white font-normal ring-offset-white',
    'focus:border-border-stroke-subtle focus:outline-none',
    'hover:bg-background-base-neutral',
    'placeholder:text-content-base placeholder:text-text-medium placeholder:italic placeholder:text-muted-foreground'
  ),
  variants: {
    state: {
      default: 'border-border-stroke-clean',
      error: 'border-border-stroke-danger focus:border-border-stroke-danger',
      disabled:
        'disabled:cursor-not-allowed bg-background-base-neutral border-border-stroke-neutral text-text-medium',
    },
    rounding: {
      rectangular: 'rounded-md',
      pill: 'rounded-full',
    },
  },
  defaultVariants: {
    state: 'default',
    rounding: 'rectangular',
  },
})

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ state, rounding, className, children, type, ...props }, ref) => {
    const inputBaseStore = useInputBaseStoreContext()
    const { error, disabled } = useStore(inputBaseStore, (inputBaseState) => inputBaseState)

    const getState = () => {
      if (state) return state
      if (error) return 'error'
      if (disabled) return 'disabled'
      return 'default'
    }

    const inputState = getState()

    // If we have children that means we are using this Input for InputSelectMulti
    const hasChildren = React.Children.count(children) > 0
    if (hasChildren) {
      return (
        <div
          ref={ref as React.Ref<HTMLDivElement>}
          role="button"
          tabIndex={0}
          className={cn(inputVariants({ state: inputState, rounding }), className)}
          onClick={(e: React.MouseEvent) => {
            if (disabled) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
        >
          {children}
        </div>
      )
    }

    return (
      <input
        data-testid="scramble-input"
        type={type}
        className={cn(
          inputVariants({ state: inputState, rounding }),
          'read-only:border-border-stroke-neutral read-only:border',
          className
        )}
        disabled={inputState === 'disabled'}
        ref={ref}
        {...props}
      />
    )
  }
)

Input.displayName = 'Input'

export { Input, InputProps }
