type FormatOptions = {
  locale?: string
  currencyCode?: string | null
  useGrouping?: boolean
}

/**
 * Formats a number to a specific locale and appends a currency symbol.
 *
 * @param value The number to be formatted.
 * @param options Formatting options including locale, currencySymbol, and useGrouping.
 * @returns Formatted string.
 */
export function formatCurrency(value: number, options: FormatOptions = {}): string {
  const { locale = 'sv-SE', currencyCode = 'kr', useGrouping = true } = options

  const formattedNumber = new Intl.NumberFormat(locale, { useGrouping }).format(value)

  return `${formattedNumber} ${currencyCode}`
}

/**
 * Connects numbers in the following format:
 * given more than 2 consecutive numbers, it will return the first and last number separated by a dash.
 * Example: [1, 2, 3, 5, 6, 7, 9] => '1-3, 5-7 & 9'
 * If there are only 2 consecutive numbers, it will return them separated by a coma.
 * Example: [1, 2, 3, 5, 6, 9, 10] => '1-3, 5, 6, 9 & 10'
 * Last number will be connected to the previous one with and.
 *
 * @param numbers Array of numbers
 * @returns Formatted string.
 */
export const connectNumbers = (numbers: number[]): string => {
  const connectedNumbers: string[] = []

  numbers?.forEach((nr, index) => {
    if (!connectedNumbers.length) {
      connectedNumbers.push(`${nr}`)
    } else {
      const lastNr = numbers[index - 1]
      // number is connected to the previous one
      if (nr === lastNr + 1) {
        // previous number is already connected
        const connectedNumbersLast = connectedNumbers[connectedNumbers.length - 1]
        if (connectedNumbersLast.includes('-')) {
          connectedNumbers.pop()
          connectedNumbers.push(connectedNumbersLast.slice(0, -1) + nr)
        }
        // previous number should connect to its previous one
        else if (lastNr === numbers[index - 2] + 1) {
          connectedNumbers.pop()
          connectedNumbers.pop()
          connectedNumbers.push(`${numbers[index - 2]}-${nr}`)
        }
        // should not connect
        else {
          connectedNumbers.push(`${nr}`)
        }
      }
      // number is not connected to the previous one
      else {
        connectedNumbers.push(`${nr}`)
      }
    }
  })

  let nrText = ''
  if (connectedNumbers.length === 1) {
    nrText = connectedNumbers[0]
  } else if (connectedNumbers.length > 1) {
    const lastNr = connectedNumbers.pop()
    nrText = [connectedNumbers.join(', '), lastNr].join(' & ')
  }

  return nrText
}
