import { priceToLocal } from '@sweetspot/shared/util/functions'

export default class MembershipHelper {
  static formatMemberships(memberships, currentClubCurrency) {
    const membershipList = []
    let membershipFee, fees, playerFee, extraFee, seats_limit

    memberships.forEach((membership) => {
      fees = membership.fees
      extraFee = 0
      membershipFee = fees.find((fee) => fee.fee_unit.id === 1)
      playerFee = fees.find((fee) => fee.fee_unit.id === 2)
      fees
        .filter((fee) => fee.fee_unit.id !== 1 && fee.fee_unit.id !== 2)
        .forEach((fee) => (extraFee += fee.price / 100))

      const status = {
        text:
          membership.state === 'draft' ? '.draft' : membership.is_active ? '.active' : '.inActive',
        state:
          membership.state === 'draft' ? 'neutral' : membership.is_active ? 'active' : 'inActive',
      }
      if (
        !membership?.membership_member_limit?.current_members ||
        !membership?.membership_member_limit?.max_members ||
        !membership?.membership_member_limit?.is_active
      ) {
        seats_limit = '-'
      }

      if (
        membership?.membership_member_limit?.is_active &&
        membership?.membership_member_limit?.current_members >= 0 &&
        membership?.membership_member_limit?.max_members
      ) {
        seats_limit = `${membership?.membership_member_limit?.current_members}/${membership?.membership_member_limit?.max_members}`
      }

      membershipList.push({
        _id: membership.id,
        uuid: membership.uuid,
        name: membership.name,
        type: membership.type === 'membership' ? '.words.membership_one' : '.words.pass_one',
        status,
        state: membership.state,
        gitSync: membership.is_git_sync ? '.active' : '-',
        seats_limit,
        playRights: membership.play_right_only ? '.yes' : '.no',
        isSellMembershipEnabled: membership.is_sell_membership_enabled ? '.yes' : '.no',
        price: membershipFee ? membershipFee.price / 100 : '-',
        playerFee: playerFee ? playerFee.price / 100 : '-',
        extraFee,
        totalPrice: membership.total / 100,
        totalMonthly: membership.total_monthly / 100,
        totalPriceFormatted: priceToLocal(membership.total, currentClubCurrency, true),
        totalMonthlyFormatted: priceToLocal(membership.total_monthly, currentClubCurrency, true),
        duration: membership.duration.options.map((item) => `.membership.overview.${item}`),
      })
    })

    return membershipList
  }
}
