import { useMemo } from 'react'
import { useManageAcademyContext } from '../ManageAcademyProvider'
import AcademySelector from './AcademySelector'

const FilterToolbar = () => {
  const { academies } = useManageAcademyContext()
  const hasAcademies = useMemo(() => academies?.length > 0, [academies])

  if (!hasAcademies) return null

  return (
    <div className={'flex items-end justify-between px-6 py-4'}>
      <div className={'w-[398px]'}>
        <AcademySelector />
      </div>
    </div>
  )
}

export default FilterToolbar
