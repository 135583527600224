import { useTranslation } from 'react-i18next'

import {
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'

import { useAvailabilityAndPricingContext } from '../../AvailabilityAndPricingProvider'
import PricingPeriodTabs from './PricingPeriodTabs'

const PricingForm = () => {
  const { t } = useTranslation()
  const { bays: bayOptions, setSelectedBayIds, selectedBayIds } = useAvailabilityAndPricingContext()

  return (
    <div className="w-full pt-8">
      {/* TODO: Add BaysRadio once the bay groups feature is implemented
          <BaysRadio />
      */}
      <InputBase>
        <InputLabelContainer>{capitalize(t('words.bay_other'))}</InputLabelContainer>
        <InputSelect
          value={selectedBayIds[0]}
          onValueChange={(bay) =>
            setSelectedBayIds([bayOptions.find((b) => b.id === bay)?.id || ''])
          }
        >
          <InputSelectTrigger asChild>
            <InputContainer className="w-full">
              <InputSelectStatefulInput
                className={'text-content-base h-8 border-[1px] py-1'}
                value={
                  selectedBayIds[0] &&
                  capitalize(
                    `${t('words.bay_one')} ${
                      bayOptions.find((b) => b.id === selectedBayIds[0])?.bay_number
                    }`
                  )
                }
                placeholder={t('words.select')}
              />
              <InputSelectTrailingContainer className="right-2" />
            </InputContainer>
          </InputSelectTrigger>
          <InputSelectContent>
            <InputSelectContentView>
              {bayOptions?.map((bay) => (
                <InputSelectItem key={bay.id} value={bay.id}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {capitalize(`${t('words.bay_one')} ${bay?.bay_number}`)}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      </InputBase>

      <PricingPeriodTabs />
    </div>
  )
}

export default PricingForm
