import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { ManageSimulatorContextValue } from './types'
import useSimulators from '@sweetspot/club-portal-legacy/hooks/useSimulators'
import { DiscardDialogCallbacks } from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/types'

export const ManageSimulatorContext = createContext<ManageSimulatorContextValue>({
  areSimulatorsLoading: false,
  simulators: [],
  openCreateSimulatorSidebar: false,
  refetchSimulators: () => {
    return
  },
  toggleCreateSimulatorSideBar: () => {
    return
  },
})

export const ManageSimulatorProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openCreateSimulatorSidebar, setOpenBaySideBar] = useState(false)
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false)
  const [discardDialogCallbacks, setDiscardDialogCallbacks] = useState<DiscardDialogCallbacks>()
  const {
    simulators,
    areSimulatorsLoading,
    selectedSimulator,
    setSelectedSimulator,
    refetchSimulators,
  } = useSimulators()
  const history = useHistory()

  // set openCreateSimulatorSidebar tab based on hash
  useEffect(() => {
    const hash = history.location.hash
    setOpenBaySideBar(hash === '#new')
  }, [history.location.hash])

  const toggleCreateSimulatorSideBar = useCallback(() => {
    const isOpen = !openCreateSimulatorSidebar
    const path = history.location.pathname
    history.push(isOpen ? `${path}#new` : path)
  }, [openCreateSimulatorSidebar, history])

  const value = useMemo(
    () => ({
      areSimulatorsLoading,
      simulators,
      selectedSimulator,
      setSelectedSimulator,
      refetchSimulators,
      openCreateSimulatorSidebar,
      toggleCreateSimulatorSideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    }),
    [
      areSimulatorsLoading,
      simulators,
      selectedSimulator,
      setSelectedSimulator,
      refetchSimulators,
      openCreateSimulatorSidebar,
      toggleCreateSimulatorSideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    ]
  )

  return <ManageSimulatorContext.Provider value={value}>{children}</ManageSimulatorContext.Provider>
}

export const useManageSimulatorContext = () => useContext(ManageSimulatorContext)
