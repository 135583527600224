import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import Period from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/Period'

const Body = () => {
  const { t } = useTranslation()
  const { periods, arePeriodsLoading, ranges, areRangesLoading, toggleCreatePeriodSideBar } =
    useAvailabilityAndPricingContext()
  const isLoading = useMemo(
    () => arePeriodsLoading || areRangesLoading,
    [areRangesLoading, arePeriodsLoading]
  )

  const emptyStateKey = `loading-${areRangesLoading}-ranges-${ranges?.length || 0}-periods`

  if (isLoading) {
    return (
      <EmptyState
        key={emptyStateKey}
        iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
        loading
      >
        <EmptyStateTitle>{t('words.loading')}</EmptyStateTitle>
      </EmptyState>
    )
  }

  if (!ranges?.length) {
    return (
      <EmptyState
        key={emptyStateKey}
        iconName="fa-light fa-flip-both fa-circle-exclamation bg-background-mono-lighter mb-4 flex rounded-full p-4"
      >
        <EmptyStateTitle>{t('settings.bays.noRangesCreated')}</EmptyStateTitle>
        <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
          <Link
            className="height-full flex w-full items-center justify-center"
            to={'/settings/manage-range'}
            target={'_blank'}
          >
            <i className="fa-regular fa-arrow-up-right-from-square mr-2"></i>
            {t('settings.bays.createFirstRange')}
          </Link>
        </EmptyStateButton>
      </EmptyState>
    )
  }

  if (!periods?.length) {
    return (
      <EmptyState
        key={`${emptyStateKey}-noPeriods`}
        iconName="fa-regular fa-calendar-range"
        className="max-w-[372px] px-6"
        iconClassName={
          'mb-lg bg-background-mono-lighter rounded-full p-4 w-[92px] h-[92px] flex justify-center items-center'
        }
      >
        <EmptyStateTitle>{t('sentences.noPeriodsCreated')}</EmptyStateTitle>
        <EmptyStateDescription>
          <div className="mb-4 text-center">{t('sentences.noPeriodsCreatedDescription')}</div>
        </EmptyStateDescription>
        <EmptyStateButton
          className="text-content-sm font-medium"
          onClick={toggleCreatePeriodSideBar}
        >
          {t('sentences.createPeriod')}
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return <Period />
}

export default Body
