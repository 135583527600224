import React from 'react'

import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  Allocation,
  BayScreenDisplay,
  BayScreenMode,
  Stance,
  TrackingTech,
} from '@sweetspot/shared/types'

import { BayTableRowData } from '../types'

type RenderDisplayType = {
  display?: BayScreenDisplay | null
  trackingTech?: TrackingTech | null
  t: (key: string) => string
}

type RenderScreenModeType = RenderDisplayType & {
  screenMode?: BayScreenMode
}

const renderDisplay = ({ display, trackingTech, t }: RenderDisplayType) => {
  if (trackingTech !== TrackingTech.NONE && display) {
    if (display === BayScreenDisplay.PLAYER_DEVICE) {
      return t('settings.bays.playerDevice')
    } else if (display === BayScreenDisplay.TOPTRACER_SCREEN) {
      return t('settings.bays.toptracerScreen')
    }
  }
  return '-'
}

const renderScreenMode = ({ screenMode, display, trackingTech, t }: RenderScreenModeType) => {
  if (
    trackingTech !== TrackingTech.NONE &&
    display === BayScreenDisplay.TOPTRACER_SCREEN &&
    screenMode
  ) {
    if (screenMode === BayScreenMode.LOCKED) {
      return t('words.locked')
    } else if (screenMode === BayScreenMode.OPEN) {
      return t('words.unlocked')
    }
  }
  return '-'
}

const getHeaders = (
  t: (key: string) => string,
  getAllocationLabel: (allocation: Allocation) => string | undefined
) => ({
  bay_number: {
    name: '.settings.bays.bayNumber',
    type: 'label',
    valueType: 'number',
    width: '150px',
    selected: true,
    disabled: true,
    isSortable: true,
  },
  floor: {
    name: '.settings.bays.floor',
    type: 'label',
    valueType: 'number',
    width: '180px',
    selected: true,
  },
  allocation: {
    name: '.settings.bays.allocation',
    type: 'label',
    valueType: 'string',
    width: '180px',
    selected: true,
    render: (allocation: Allocation) => getAllocationLabel(allocation),
  },
  tracking_technology: {
    name: '.settings.bays.trackingTech',
    type: 'label',
    valueType: 'string',
    width: '180px',
    selected: true,
    render: (trackingTech: string) => trackingTech && capitalize(trackingTech),
  },
  tracking_technology_display: {
    name: '.settings.bays.display',
    type: 'label',
    valueType: 'string',
    width: '180px',
    selected: true,
    render: (display: BayScreenDisplay, _: unknown, row: BayTableRowData) =>
      renderDisplay({ display, trackingTech: row.tracking_technology, t }),
  },
  tracking_technology_screen_mode: {
    name: '.settings.bays.screenMode',
    type: 'label',
    valueType: 'string',
    width: '180px',
    selected: true,
    render: (screenMode: BayScreenMode, _: unknown, row: BayTableRowData) =>
      renderScreenMode({
        screenMode,
        trackingTech: row.tracking_technology,
        display: row.tracking_technology_display,
        t,
      }),
  },
  roof: {
    name: '.settings.bays.roof',
    type: 'label',
    valueType: 'boolean',
    width: '180px',
    selected: true,
    render: (roof: boolean) =>
      roof ? (
        <i key={`roof - ${roof}`} className="fa-regular fa-check" />
      ) : (
        <i key={`roof - ${roof}`} className="fa-regular fa-xmark" />
      ),
  },
  stance: {
    name: '.settings.bays.stance',
    type: 'label',
    valueType: 'string',
    width: '180px',
    selected: true,
    render: (stance: Stance) => t(`settings.bays.${stance}`),
  },
})

export default getHeaders
