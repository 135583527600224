import React from 'react'
import { useTranslation } from 'react-i18next'

import { Divider, InputBase, InputLabelContainer, Tag } from '@sweetspot/scramble-ds'

type Props = {
  name: string
  description?: string
  floor?: number
}

const DetailsView = ({ name, description, floor }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="pt-md pl-2">
          <p className="text-content-sm font-bold">{t('words.name')}</p>
          <p className="text-content-base font-regular">{name}</p>
        </div>

        <div className="pl-2">
          <p className="text-content-sm font-bold">{t('words.description')}</p>
          <p className="text-content-base font-regular">{description}</p>
        </div>

        <Divider />

        <InputBase>
          <InputLabelContainer>{t('words.floor')}</InputLabelContainer>
          <Tag size="large" variant="inverted" className="mt-sm px-2">
            <i className="fa-regular fa-layer-group" />
            <span className="px-sm">{floor}</span>
          </Tag>
        </InputBase>
      </div>
    </div>
  )
}

export default DetailsView
