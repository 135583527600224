import { addDays, endOfWeek, isAfter, isBefore, startOfWeek } from 'date-fns'
import { utcToZonedTime, format } from 'date-fns-tz'

const MONDAY = 1
const FULL_WEEK_LENGTH = 7

export const divideIntoWeeks = (startDate?: string, endDate?: string) => {
  if (!startDate || !endDate) return []
  // Parse the ISO string dates as Date objects in UTC
  const start = utcToZonedTime(startDate, 'UTC')
  const end = utcToZonedTime(endDate, 'UTC')

  // Move the start date back to the nearest Monday
  let currentWeekStart = startOfWeek(start, { weekStartsOn: MONDAY }) // Monday start
  const weeks = []

  while (isBefore(currentWeekStart, end) || currentWeekStart.getTime() === end.getTime()) {
    // Get the end of the current week (Sunday)
    const currentWeekEnd = endOfWeek(currentWeekStart, { weekStartsOn: MONDAY })

    // Ensure the current week's end does not go beyond the specified end date
    const weekEnd = isAfter(currentWeekEnd, end) ? end : currentWeekEnd

    // Format dates as 'YYYY-MM-DD' without relying on timezone conversions
    weeks.push({
      startDate: format(currentWeekStart, 'MMM d'),
      endDate: format(weekEnd, 'd'),
    })

    // Move to the next week
    currentWeekStart = addDays(currentWeekStart, FULL_WEEK_LENGTH)
  }

  return weeks
}
