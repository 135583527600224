import { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  Button,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { VenuesIcon } from '@sweetspot/club-portal/feature/venues'
import { GolfCourse, GolfCourseTypes } from '@sweetspot/shared/types'

import { useManageSimulatorContext } from '../ManageSimulatorProvider'

const SimulatorSelector = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { simulators, selectedSimulator, setSelectedSimulator, toggleCreateSimulatorSideBar } =
    useManageSimulatorContext()

  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!!setSelectedSimulator && !selectedSimulator && simulators?.length) {
      setSelectedSimulator(simulators[0])
    }
  }, [simulators, selectedSimulator, setSelectedSimulator])

  // set selectedSimulator based on hash
  useEffect(() => {
    const hash = history.location.hash
    const selected = simulators.find((simulator) => `#${simulator.id}` === hash)
    if (setSelectedSimulator && selected) {
      setSelectedSimulator(selected)
      setSearch('')
    }
  }, [history.location.hash, simulators, setSelectedSimulator])

  const academiesToRender = useMemo(
    () =>
      simulators?.filter(
        (simulator) => simulator.name.toLowerCase().includes(search.toLowerCase()),
        [search, simulators]
      ),
    [search, simulators]
  )

  const handleValueChange = useCallback(
    (newValue: string) => {
      if (newValue === 'new') {
        toggleCreateSimulatorSideBar()
        return
      }
      const selected = simulators.find((simulator: GolfCourse) => `${simulator.id}` === newValue)
      if (setSelectedSimulator && selected) {
        const path = history.location.pathname
        history.push(`${path}#${newValue}`)
      }
    },
    [simulators, setSelectedSimulator, toggleCreateSimulatorSideBar, history]
  )

  const value = useMemo(
    () => simulators.find((simulator) => simulator.id === selectedSimulator?.id)?.name,
    [simulators, selectedSimulator?.id]
  )

  return (
    <InputSelect value={value} onValueChange={handleValueChange}>
      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('words.simulator_one')}</InputLabelContainer>
        <InputSelectTrigger asChild>
          <InputContainer className="cursor-pointer">
            <InputLeadingContainer>
              <VenuesIcon courseType={GolfCourseTypes.SIMULATOR} type="default" />
            </InputLeadingContainer>
            <InputSelectStatefulInput
              value={value}
              onChange={() => null}
              onClick={() => setSearch('')}
              className="text-content-base text-ellipsis px-10 "
              readOnly
            />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectTrigger>
      </InputBase>

      <InputSelectContent>
        <InputSelectContentView>
          {academiesToRender?.map((simulator) => (
            <InputSelectItem key={simulator.id} value={`${simulator.id}`}>
              <ListItem className="ml-4 h-full w-[376px] justify-center gap-2">
                <ListItemMainContent className="flex-row justify-between pr-2">
                  <ListItemParagraph className="text-content-base">
                    {simulator.name}
                  </ListItemParagraph>
                  <ListItemTrailing>
                    {selectedSimulator?.id === simulator.id && (
                      <i className="fa-regular fa-check h-4 w-4" />
                    )}
                  </ListItemTrailing>
                </ListItemMainContent>
              </ListItem>
            </InputSelectItem>
          ))}
        </InputSelectContentView>
        <InputSelectContentView className="mt-0.5 min-h-12">
          <InputSelectItem value="new" className="flex justify-center">
            <Button
              variant="link-dark"
              className="text-content-lg text-text-dark h-12 w-full font-medium hover:no-underline focus:no-underline"
            >
              <i className="fa-regular fa-circle-plus " />
              {t('words.new')}
            </Button>
          </InputSelectItem>
        </InputSelectContentView>
      </InputSelectContent>
    </InputSelect>
  )
}

export default SimulatorSelector
