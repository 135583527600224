import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import CategoryModal from '@sweetspot/club-portal-legacy/modals/CategoryModal'

import HEADERS from './headers'

import style from './style.module.scss'
import { getTranslatedTeeTimeCategories } from '@sweetspot/sweetspot-js/features/teeTimeCategories/js/getTranslatedTeeTimeCategories'

const actions = [
  {
    id: 1,
    label: 'createNew',
    action: 'createNew',
  },
]

const TeeTimeCategory = () => {
  const [isLoading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [totalRows, setTotalRows] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(1)
  const history = useHistory()
  const location = useLocation()
  const clubId = useSelector((state) => state.golfClub.selectedId)

  useEffect(() => {
    getCategories()
  }, [clubId])

  useEffect(() => {
    if (location?.state?.shouldReload) {
      getCategories()
    }
  }, [location?.state])

  const getCategories = (pageNum = 1) => {
    setLoading(true)
    getTranslatedTeeTimeCategories({
      clubId,
      page: pageNum,
      allPages: false,
      hydraResponse: true,
    })
      .then((res) => {
        setLoading(false)
        setCategories(res['hydra:member'])
        setTotalRows(res['hydra:totalItems'])
        setItemsPerPage(res['hydra:itemsPerPage'])
      })
      .catch(() => {
        setCategories([])
        setTotalRows(0)
        setItemsPerPage(1)
        setLoading(false)
      })
  }

  const handleActions = (action) => {
    if (action.id === 1) {
      history.push('/settings/tee-time-category/new')
    }
  }

  const handlePageChange = (page) => {
    getCategories(page)
  }

  return (
    <div className={style.container}>
      <Grid
        isLoading={isLoading}
        pageTitle={'teeTimeCategory.title'}
        actionsEnabled
        shouldHideColumnEdit
        pagination
        displayRangeOfRows
        headers={HEADERS}
        actions={actions}
        values={categories}
        totalPages={Math.ceil(totalRows / itemsPerPage)}
        totalRows={totalRows}
        rowsPerPage={itemsPerPage}
        onActionConfirm={handleActions}
        setCurrentPage={handlePageChange}
        onRowClick={(row) => history.push(`/settings/tee-time-category/${row.id}`)}
      />
      <Switch>
        <Route exact path="/settings/tee-time-category/:id" component={CategoryModal} />
      </Switch>
    </div>
  )
}

export default TeeTimeCategory
