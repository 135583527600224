import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { useEffect, useState } from 'react'
import _isEqual from 'lodash/isEqual'
import { GolfCourse, GolfCourseTypes } from '@sweetspot/shared/types'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'

const useSimulators = () => {
  const currentClubId = useSelector((state: RootState) => state.golfClub.selectedId)
  const [selectedSimulator, setSelectedSimulator] = useState<GolfCourse | undefined>()

  const {
    data,
    isLoading: areSimulatorsLoading,
    refetch,
  } = useQuery(
    [CLUB_QUERIES.COURSES, GolfCourseTypes.SIMULATOR, currentClubId],
    () => queryCourses({ 'club.id': currentClubId, type: GolfCourseTypes.SIMULATOR }),
    {
      enabled: !!currentClubId,
    }
  )

  // if simulators are updated, update the selected simulator if needed
  useEffect(() => {
    if (selectedSimulator) {
      const updatedSimulator = data?.find(
        (simulator: GolfCourse) => simulator.id === selectedSimulator.id
      )
      if (updatedSimulator && !_isEqual(selectedSimulator, updatedSimulator)) {
        setSelectedSimulator(updatedSimulator)
      }
    }
  }, [data, selectedSimulator])

  return {
    simulators: (data as GolfCourse[]) || [],
    areSimulatorsLoading,
    selectedSimulator,
    setSelectedSimulator,
    refetchSimulators: refetch,
  }
}

export default useSimulators
