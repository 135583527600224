import React, { useState } from 'react'
import styles from './styles.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import produce from 'immer'
import { useTranslation } from 'react-i18next'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import TextInputOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputOptionsSelect'
import TimeOffset from '../TimeOffset'

const SpacesAndObjectsTable = ({ isLoading, spaces, errors, courses, config, saveObjects }) => {
  const { t } = useTranslation()
  const [mappedObjects, setMappedObjects] = useState(config?.mappedObjects || [])
  const [offsets, setOffsets] = useState({
    light_offset: { ...config?.light_offset },
    door_offset: { ...config?.door_offset },
  })

  const handleOffsetSelect = (category, key, value) => {
    setOffsets(
      produce((draft) => {
        draft[category][key] = value
      })
    )
  }

  const handleSetOjbect = (space, object) => {
    setMappedObjects(
      produce((draft) => {
        const index = draft.findIndex((x) => x?.space === space.uuid)
        if (index === -1) {
          draft.push({ space: space.uuid, object })
        } else draft[index] = { ...draft[index], object }
      })
    )
  }

  const getCourseName = (id) => {
    const course = courses.find((x) => x?.id === id)
    return course?.name || ''
  }

  const getObjectName = (id) => {
    if (!id) return ''
    return config?.objects.find((x) => x.id === id)?.name || ''
  }

  return (
    <div className={cx(styles.container)}>
      <TimeOffset
        onSelect={handleOffsetSelect}
        doorOffsets={offsets.door_offset}
        lightOffsets={offsets.light_offset}
      />
      <div className={cx(styles.spacesTable)}>
        <div className={cx(styles.tableHeader)}>
          <span className={cx(styles.infoText)}>{`${spaces?.length || 0} ${t(
            'words.space_other'
          )}`}</span>
          <span className={cx(styles.objectLabel)}>{'QT object'}</span>
        </div>
        <div className={cx(styles.tablebody)}>
          {spaces && courses
            ? spaces.map((space) => (
                <div className={cx(styles.spaceRow)} key={space?.uuid}>
                  <span>{space?.name}</span>
                  <span>{getCourseName(space?.course?.id)?.name}</span>
                  <div className={cx(styles.objectSelect)}>
                    <TextInputOptionsSelect
                      value={getObjectName(
                        mappedObjects.find((x) => x?.space === space.uuid)?.object
                      )}
                      options={config?.objects?.map((x) => ({ ...x, label: x?.name })) || []}
                      onOptionSelect={(option) => handleSetOjbect(space, option.id)}
                      containerWidth="full"
                      containerClassName={cx(styles.dropdown)}
                      placeholder={t('words.select')}
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      {errors ? <p className={cx(styles.statusRow, styles.error)}>{errors}</p> : null}

      <Button
        onClick={() => saveObjects({ map: [...mappedObjects], ...offsets })}
        text={t('words.save')}
        type="submit"
        width="auto"
        size="medium"
        className={cx(styles.button)}
        disabled={isLoading}
      />
    </div>
  )
}

SpacesAndObjectsTable.propTypes = {
  isLoading: PropTypes.bool,
  errors: PropTypes.string,
  courses: PropTypes.array,
  spaces: PropTypes.array,
  config: PropTypes.object,
  saveObjects: PropTypes.func,
}

SpacesAndObjectsTable.defaultProps = {}

export default SpacesAndObjectsTable
