import { getAllPagesRequest } from '@sweetspot/sweetspot-js/common/functions/getAllPagesRequest'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  queryTeeTimeCategories,
  queryTeeTimeCategoriesTranslations,
} from '../services/api-platform'

export const getTranslatedTeeTimeCategories = async (
  args = {
    clubId: null,
    allPages: true,
    hydraResponse: false,
    page: 1,
    limit: 50,
  }
) => {
  const { clubId, allPages, hydraResponse, page, limit } = {
    clubId: null,
    allPages: true,
    hydraResponse: false,
    page: 1,
    limit: 50,
    ...args,
  }

  if (allPages && hydraResponse) {
    console.warn(
      'getTranslatedTeeTimeCategories: hydraResponse is not supported when allPages is true, option will be ignored'
    )
  }

  if (!clubId) {
    throw new Error('getTranslatedTeeTimeCategories: clubId is required')
  }

  const [categoriesRes, categoriesErr] = await to(
    allPages === true
      ? getAllPagesRequest(queryTeeTimeCategories, { 'club.id': clubId, page: page, limit: limit })
      : queryTeeTimeCategories(
          { 'club.id': clubId, page: page, limit: limit },
          { accept: 'application/ld+json' }
        )
  )

  if (categoriesErr) {
    throw new Error('getTranslatedTeeTimeCategories: got error from queryTeeTimeCategories')
  }

  const categories = allPages ? categoriesRes : categoriesRes['hydra:member']

  const [categoriesTranslationsRes, categoriesTranslationsErr] = await to(
    categories?.length
      ? getAllPagesRequest(queryTeeTimeCategoriesTranslations, {
          'translatable.uuid[]': categories.map((cat) => cat.uuid),
        })
      : async () => []
  )

  if (categoriesTranslationsErr) {
    throw new Error(
      'getTranslatedTeeTimeCategories: got error from queryTeeTimeCategoriesTranslations'
    )
  }

  if (!categoriesTranslationsRes?.length || !categories?.length) {
    if (hydraResponse) {
      return categoriesRes
    } else {
      return []
    }
  }

  if (hydraResponse) {
    return {
      ...categoriesRes,
      'hydra:member': categories.map((cat) => ({
        ...cat,
        ...categoriesTranslationsRes.find((c) => c.id === cat.id),
      })),
    }
  } else {
    return categories.map((cat) => ({
      ...cat,
      ...categoriesTranslationsRes.find((c) => c.id === cat.id),
    }))
  }
}
