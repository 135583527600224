import { Route, Switch, useRouteMatch } from 'react-router'
import MembershipGrid from './MembershipGrid'
import { FailedPayments } from './FailedPayments'
import { MembershipImport } from './MembershipImport'
import { PendingInvites } from './PendingInvites'
import { MembershipSignupsGrid } from './MembershipSignupsGrid'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'

const Memberships = () => {
  const { path } = useRouteMatch()
  const isSubscriptionsEnabled = useFlag(FlagNames.Subscriptions)

  return (
    <Switch>
      <Route exact path={`${path}/failed-payments`} component={FailedPayments} />
      <Route
        exact
        path={`${path}/import-members/start-membership-import`}
        component={MembershipImport}
      />
      <Route
        exact
        path={`${path}/import-members/pending-membership-invites`}
        component={PendingInvites}
      />
      <Route path={`${path}/membership-signups`} component={MembershipSignupsGrid} />
      <Route
        path={`${path}`}
        render={(props) => (
          <MembershipGrid {...props} isSubscriptionsEnabled={isSubscriptionsEnabled} />
        )}
      />
    </Switch>
  )
}

export { Memberships }
