import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Toggle,
} from '@sweetspot/scramble-ds'
import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import ActivateCourseDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/ActivateCourseDialog/ActivateCourseDialog'

import { RangeTabs as Tabs } from '../types'
import GeneralTab from './GeneralTab/GeneralTab'
import BookingTab from './BookingTab/BookingTab'
import PaymentTab from './PaymentTab/PaymentTab'
import useUpdateRange from '../hooks/useUpdateRange'
import { useManageRangeContext } from '../ManageRangeProvider'

type RangeTabsProps = {
  currentCourse?: GolfCourseItem
  refetchCourses: () => void
}

const RangeTabs = ({ currentCourse, refetchCourses }: RangeTabsProps) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.GENERAL)
  const [isRangeActive, setIsRangeActive] = useState<boolean>(false)
  const [openActivateDialog, setOpenActivateDialog] = useState<boolean>(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const history = useHistory()
  const { updateRange } = useUpdateRange({ currentCourse })
  const { discardDialogCallbacks, openDiscardDialog } = useManageRangeContext()

  useEffect(() => {
    setIsRangeActive(currentCourse?.is_active ?? false)
  }, [currentCourse?.is_active])

  // set active tab based on hash
  useEffect(() => {
    const hash = (history.location.hash?.slice(1) as Tabs) || Tabs.GENERAL
    if (hash && Object.values(Tabs).includes(hash)) setActiveTab(hash)
  }, [history.location.hash])

  const activateDialogTexts = useMemo(
    () => ({
      title: isRangeActive
        ? t('settings.deactivateDialogTitle', { type: t('words.range_one').toLowerCase() })
        : t('settings.activateDialogTitle', { type: t('words.range_one').toLowerCase() }),
      description: isRangeActive
        ? t('settings.range.deactivateDialogText')
        : t('settings.range.activateDialogText'),
    }),
    [t, isRangeActive]
  )

  const toggleActive = useCallback(() => {
    const isActive = !isRangeActive
    setOpenActivateDialog(false)
    setIsRangeActive(isActive)
    updateRange({ isActive })
    refetchCourses()
  }, [updateRange, refetchCourses, isRangeActive])

  const renderContent = useCallback(() => {
    if (activeTab === Tabs.GENERAL)
      return <GeneralTab currentCourse={currentCourse} refetchCourses={refetchCourses} />
    if (activeTab === Tabs.BOOKING)
      return <BookingTab currentCourse={currentCourse} refetchCourses={refetchCourses} />
    if (activeTab === Tabs.PAYMENT)
      return <PaymentTab currentCourse={currentCourse} refetchCourses={refetchCourses} />
    return null
  }, [activeTab, currentCourse, refetchCourses])

  return (
    <div className="flex h-full flex-1 flex-col gap-8">
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => discardDialogCallbacks?.onClose()}
        onDiscard={() => discardDialogCallbacks?.onDiscard()}
        onSave={() => discardDialogCallbacks?.onSave()}
      />
      <ActivateCourseDialog
        open={openActivateDialog}
        setOpen={setOpenActivateDialog}
        onSave={toggleActive}
        title={activateDialogTexts.title}
        description={activateDialogTexts.description}
      />
      <div className="border-b pt-2">
        <div className="flex items-center justify-between px-6">
          <SimpleTabs
            className="h-8 !w-auto gap-0.5 pb-0 [&_span]:!hidden"
            labelClassName="w-[106px] after:!scale-x-100 !mb-[-1px]"
            activeLabelClassName="!font-bold !text-text-dark after:!border-[#171717]"
            inActiveLabelClassName="!font-medium !text-text-subtle after:!border-[#D1D5DB]"
            currentItem={activeTab}
            onClick={(tab) => history.push(`#${tab}`)}
            items={[
              { id: Tabs.GENERAL, label: t('words.general') },
              { id: Tabs.BOOKING, label: t('words.booking_one') },
              { id: Tabs.PAYMENT, label: t('words.payment_one') },
            ]}
          />

          <div className="flex w-fit items-center gap-4">
            <div className="px-md flex min-w-fit items-center gap-2">
              <p className="text-content-sm text-text-dark">
                <span className="font-medium">
                  {isRangeActive
                    ? t('settings.courseActive', { type: t('words.range_one') })
                    : t('settings.courseInactive', { type: t('words.range_one') })}
                </span>
                {isRangeActive
                  ? ` (${t('settings.courseVisible').toLowerCase()})`
                  : ` (${t('settings.courseNotVisible').toLowerCase()})`}
              </p>
              <Toggle checked={isRangeActive} onCheckedChange={() => setOpenActivateDialog(true)} />
            </div>

            <Popover open={isDropdownOpen}>
              <PopoverTrigger asChild>
                <div
                  className="border-border-stroke-clean cursor-pointer border-l px-4"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <i className="fa-regular fa-ellipsis" />
                </div>
              </PopoverTrigger>
              <PopoverContent
                align="end"
                className="w-fit p-2 drop-shadow"
                onInteractOutside={() => setIsDropdownOpen(false)}
              >
                <ListItem className="text-text-pale w-full min-w-fit cursor-not-allowed gap-5 px-2 py-3">
                  <ListItemLeading className="px-0">
                    <i className="fa-regular fa-trash-can" />
                  </ListItemLeading>
                  <ListItemMainContainer>
                    <ListItemMainContent className="py-0">
                      <ListItemMainLabel className="h-auto min-h-6 font-bold">
                        {t('sentences.deleteCourse', { type: t('words.range_one').toLowerCase() })}
                      </ListItemMainLabel>
                    </ListItemMainContent>
                  </ListItemMainContainer>
                </ListItem>

                <PopoverArrow className="fill-white" width={16} height={10} />
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
      <div className="px-6 pb-[88px]">{renderContent()}</div>
    </div>
  )
}

export default RangeTabs
