import {
  CreateBayBulkPayload,
  CreateBayPayload,
  UpdateBayBulkPayload,
  UpdateBayPayload,
} from '@sweetspot/shared/data-access/api-platform'
import {
  Range,
  BayTypeV2,
  BayType,
  BayScreenDisplay,
  BayScreenMode,
  BayAmenities,
  BallTrackingTechnology,
  Allocation,
} from '@sweetspot/shared/types'

export enum BaySideBarMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  EDIT_BULK = 'EDIT_BULK',
  VIEW = 'VIEW',
}

export type BaysContextValue = {
  areRangesLoading: boolean
  selectedRange?: Range
  setSelectedRange?: (range: Range) => void
  ranges: Range[]
  refetchRanges: () => void
  bays: BayTypeV2[]
  areBaysLoading: boolean
  areBaysFetching: boolean
  selectedBays?: BayTypeV2[]
  setSelectedBays: (bays: BayTypeV2[]) => void
  openBaySideBar: boolean
  toggleBaySideBar: (mode?: BaySideBarMode) => void
  changeBaySideBarMode: (mode: BaySideBarMode) => void
  reFetchBays: () => void
  baySideBarMode?: BaySideBarMode
  openDiscardDialog: boolean
  setOpenDiscardDialog: (open: boolean) => void
  updateBay?: (bay: UpdateBayPayload & { siteId?: string }) => Promise<void[]>
  createBay?: (
    bay: (CreateBayPayload | CreateBayBulkPayload) & { siteId?: string }
  ) => Promise<void[]>
  updateBayBulk?: (bays: UpdateBayBulkPayload & { siteId?: string }) => Promise<void[]>
  checkForErrors?: (
    payload?: (
      | CreateBayPayload
      | UpdateBayPayload
      | CreateBayBulkPayload
      | UpdateBayBulkPayload
    ) & { siteId?: string }
  ) => string
  mergeBays: (bays?: BayTypeV2[]) => MergedBaysType
  constructUpdateBayBulkPayload?: (
    values: MergedBaysType & { siteId?: string }
  ) => UpdateBayBulkPayload & { siteId?: string }
  getAllocationLabel: (value?: Allocation) => string | undefined
}

export type BayTableRowData = Omit<BayType, 'drivingRangeUuid'> & {
  tracking_technology_display?: BayScreenDisplay | null
  tracking_technology_screen_mode?: BayScreenMode | null
}

export type MergedBaysType = {
  floor?: { value?: BayTypeV2['floor']; isMixed?: boolean }
  stance?: { value?: BayTypeV2['stance']; isMixed?: boolean }
  allocation?: { value?: BayTypeV2['allocation']; isMixed?: boolean }
  roof?: { value?: BayAmenities['roof']; isMixed?: boolean }
  trackingTech?: { value?: BallTrackingTechnology['tracking_provider']; isMixed?: boolean }
  display?: { value?: BallTrackingTechnology['display']; isMixed?: boolean }
  screenMode?: { value?: BallTrackingTechnology['screen_mode']; isMixed?: boolean }
  trmsNumbers?: { [key: number]: BallTrackingTechnology['api_details']['trms_bay_number'] }
}
