import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'

import useAcademies from '@sweetspot/club-portal-legacy/hooks/useAcademies'
import { DiscardDialogCallbacks } from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/types'

import { ManageAcademyContextValue } from './types'

export const ManageAcademyContext = createContext<ManageAcademyContextValue>({
  areAcademiesLoading: false,
  academies: [],
  openCreateAcademySidebar: false,
  refetchAcademies: () => {
    return
  },
  toggleCreateAcademySideBar: () => {
    return
  },
})

export const ManageAcademyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openCreateAcademySidebar, setOpenBaySideBar] = useState(false)
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false)
  const [discardDialogCallbacks, setDiscardDialogCallbacks] = useState<DiscardDialogCallbacks>()
  const { academies, areAcademiesLoading, selectedAcademy, setSelectedAcademy, refetchAcademies } =
    useAcademies()
  const history = useHistory()

  // set openCreateAcademySidebar tab based on hash
  useEffect(() => {
    const hash = history.location.hash
    setOpenBaySideBar(hash === '#new')
  }, [history.location.hash])

  const toggleCreateAcademySideBar = useCallback(() => {
    const isOpen = !openCreateAcademySidebar
    const path = history.location.pathname
    history.push(isOpen ? `${path}#new` : path)
  }, [openCreateAcademySidebar, history])

  const value = useMemo(
    () => ({
      areAcademiesLoading,
      academies,
      selectedAcademy,
      setSelectedAcademy,
      refetchAcademies,
      openCreateAcademySidebar,
      toggleCreateAcademySideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    }),
    [
      areAcademiesLoading,
      academies,
      selectedAcademy,
      setSelectedAcademy,
      refetchAcademies,
      openCreateAcademySidebar,
      toggleCreateAcademySideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    ]
  )

  return <ManageAcademyContext.Provider value={value}>{children}</ManageAcademyContext.Provider>
}

export const useManageAcademyContext = () => useContext(ManageAcademyContext)
