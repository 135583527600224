import { SpacePayload } from '@sweetspot/club-portal-legacy/hooks/types'
import { Space } from '@sweetspot/shared/types'
import { GolfCourse } from '@sweetspot/shared/types'

export enum SpaceSideBarMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export type ManageSimulatorSpacesContextValue = {
  areSimulatorsLoading: boolean
  selectedSimulator?: GolfCourse
  setSelectedSimulator?: (simulator: GolfCourse) => void
  simulators: GolfCourse[]
  refetchSimulators: () => void
  spaces: Space[]
  areSpacesLoading: boolean
  areSpacesFetching: boolean
  selectedSpace?: Space
  setSelectedSpace: (space?: Space) => void
  openSpaceSideBar: boolean
  toggleSpaceSideBar: (mode?: SpaceSideBarMode) => void
  changeSpaceSideBarMode: (mode: SpaceSideBarMode) => void
  refetchSpaces: () => void
  spaceSideBarMode?: SpaceSideBarMode
  openDiscardDialog: boolean
  setOpenDiscardDialog: (open: boolean) => void
  updateSpace: (space: SpacePayload) => Promise<unknown>
  createSpace: (space: SpacePayload) => Promise<unknown>
  deleteSpace: (uuid: string) => Promise<unknown>
  checkForErrors?: (payload?: SpacePayload) => string
}
