import { useTranslation } from 'react-i18next'

type BallsBoxProps = {
  nrOfBalls: number
  totalNrOfBalls: number
}

const BallsBox = ({ nrOfBalls, totalNrOfBalls }: BallsBoxProps) => {
  const { t } = useTranslation()

  return (
    <div className="pb-4 pt-5">
      <div className="text-content-lg mb-3 font-bold">{t('words.balls')}</div>
      <div className="border-border-stroke-clean flex items-center gap-4 rounded-md border p-2">
        <i className="fa-solid fa-ball-pile text-text-dark h-6 w-6 px-1 text-center" />
        <div className="py-2">
          <div className="text-text-dark font-bold">
            {t('sentences.ballsIncluded', { count: totalNrOfBalls })}
          </div>
          <div className="text-text-subtle text-content-sm">
            {t('sentences.ballsIncludedHint', { count: nrOfBalls })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BallsBox
