import React, { useCallback } from 'react'

import { Space } from '@sweetspot/shared/types'
import Table from '@sweetspot/club-portal-legacy/components/Table'

import { HEADERS } from './headers'
import { SpaceSideBarMode } from '../types'
import { useManageSimulatorSpacesContext } from '../ManageSimulatorSpacesProvider'

type SpacesTableProps = {
  spaceData: Space[]
}

const SpacesTable = ({ spaceData }: SpacesTableProps) => {
  const { setSelectedSpace, toggleSpaceSideBar, spaces } = useManageSimulatorSpacesContext()

  const handleRowClick = useCallback(
    (selectedValue: Space) => {
      setSelectedSpace(spaces.find((space) => space.id === selectedValue.id))
      toggleSpaceSideBar(SpaceSideBarMode.VIEW)
    },
    [setSelectedSpace, toggleSpaceSideBar, spaces]
  )

  return (
    <div className="h-full w-full">
      <Table
        headers={HEADERS}
        values={spaceData}
        fullWidth
        fullHeight
        hideScrollbar
        onRowClick={handleRowClick}
      />
    </div>
  )
}

export default SpacesTable
