import Header from './Header'
import FilterToolbar from './FilterToolbar/FilterToolbar'
import SpacesTableContainer from './SpacesTable/SpacesTableContainer'
import SpacesSideBar from './SpacesSideBar/SpacesSideBar'
import { ManageSimulatorSpacesProvider } from './ManageSimulatorSpacesProvider'

const ManageSimulatorSpaces = () => {
  return (
    <ManageSimulatorSpacesProvider>
      <SpacesSideBar />
      <div className="flex h-full flex-col">
        <Header />
        <div className={'py-4'}>
          <FilterToolbar />
        </div>
        <div className={'flex flex-1 items-center justify-center px-6'}>
          <SpacesTableContainer />
        </div>
      </div>
    </ManageSimulatorSpacesProvider>
  )
}

export default ManageSimulatorSpaces
