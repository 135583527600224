import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

import { localizedDaysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import generateTableData from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useWeekPeriod/generate-table-data'
import { Period } from '@sweetspot/shared/types'
import getWeekPeriodOptions from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useOptionsForSelects/getWeekPeriodOptions'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import getTeeTimes from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useWeekPeriod/getTeeTimes'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

import { AllCheckboxValues, PeriodPricingTableData, WeekPeriodOption } from '../types'
import { divideIntoWeeks } from '../utils'

type UsePeriodsPricingProps = {
  selectedPeriod: Period | null
  currentCourse: GolfCourseItem | null
}

const usePeriodsPricing = ({ selectedPeriod, currentCourse }: UsePeriodsPricingProps) => {
  const { t } = useTranslation()
  const [selectedBayIds, setSelectedBayIds] = useState<string[]>([])
  const [price, setPrice] = useState<number>(0)
  const [pricePerExtraPlayer, setPricePerExtraPlayer] = useState<number>(0)
  const [selectedCells, setSelectedCells] = useState<Set<string>>(new Set())
  const [allCells, setAllCells] = useState<Set<string> | null>(null)
  const [allCheckbox, setAllCheckbox] = useState<AllCheckboxValues | null>(null)
  const [tableData, setTableData] = useState<PeriodPricingTableData | null>(null)
  const [activePeriodTab, setActivePeriodTab] = useState<string>()

  const [currency] = useClubCurrency()

  // if form has been updated
  const hasUpdated = false

  const weekPeriodOptions: WeekPeriodOption[] = useMemo(() => {
    const weeks = divideIntoWeeks(selectedPeriod?.start_date, selectedPeriod?.end_date)
    return getWeekPeriodOptions(selectedPeriod, t, currentCourse?.timezone).map(
      (option: WeekPeriodOption, index: number) => {
        return {
          ...option,
          name:
            option.id === -1 ? option.name : `${weeks[index].startDate} - ${weeks[index].endDate}`,
        }
      }
    )
  }, [selectedPeriod, t, currentCourse])

  const selectedWeek = useMemo(
    () =>
      weekPeriodOptions.find((option) => `${option.id}` === activePeriodTab) ||
      weekPeriodOptions[0] ||
      {},
    [weekPeriodOptions, activePeriodTab]
  )

  const { data: teeTimes, isLoading } = useQuery(
    ['TEE_TIMES', selectedPeriod?.id, selectedWeek?.id, selectedBayIds[0]],
    () => {
      // setValue('hasUpdated', true)
      return getTeeTimes(currentCourse, selectedWeek, selectedBayIds[0])
    }
  )

  // set checkbox initial valus
  useEffect(() => {
    const allCheckbox =
      allCells && selectedCells.size === allCells.size
        ? AllCheckboxValues.ALL
        : selectedCells.size > 0
        ? AllCheckboxValues.SOME
        : null
    setAllCheckbox(allCheckbox)
  }, [allCells, selectedCells])

  // populate table with data
  useEffect(() => {
    if (teeTimes && (!tableData || hasUpdated)) {
      // setValue('hasUpdated', false)
      setTableData(
        generateTableData({
          timePeriod: selectedPeriod,
          teeTimes,
          selectedWeek,
          space: selectedBayIds[0],
          currency,
          timezone: currentCourse?.timezone,
        })
      )
    }
  }, [
    selectedPeriod,
    teeTimes,
    selectedWeek,
    selectedBayIds,
    hasUpdated,
    tableData,
    currency,
    currentCourse?.timezone,
  ])

  const DAYS_OF_WEEK = useMemo(() => localizedDaysOfWeek('en'), [])

  return {
    pricings: [],
    groupOptions: [],
    selectedBayIds,
    setSelectedBayIds,
    price,
    setPrice,
    pricePerExtraPlayer,
    setPricePerExtraPlayer,
    selectedCells,
    setSelectedCells,
    allCheckbox,
    setAllCheckbox,
    DAYS_OF_WEEK,
    activePeriodTab,
    setActivePeriodTab,
    weekPeriodOptions,
    timeSlots: tableData?.timeSlots || [],
  }
}

export default usePeriodsPricing
