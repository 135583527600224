import { useTranslation } from 'react-i18next'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'

import { useAvailabilityAndPricingContext } from '../../AvailabilityAndPricingProvider'

const AdjustmentsTab = () => {
  const { t } = useTranslation()
  const { adjustments, toggleCreateAdjustmentSideBar } = useAvailabilityAndPricingContext()

  if (!adjustments.length) {
    return (
      <EmptyState
        key="no-adjustments"
        iconName="fa-regular fa-calendar-pen"
        className="max-w-[372px] px-6"
        iconClassName={
          'mb-lg bg-background-mono-lighter rounded-full p-4 w-[92px] h-[92px] flex justify-center items-center'
        }
      >
        <EmptyStateTitle>{t('sentences.noPeriodAdjustments')}</EmptyStateTitle>
        <EmptyStateDescription>
          <div className="mb-4 text-center">{t('sentences.noPeriodAdjustmentsDescription')}</div>
        </EmptyStateDescription>
        <EmptyStateButton
          className="text-content-sm font-medium"
          onClick={toggleCreateAdjustmentSideBar}
        >
          {t('sentences.createAdjustment')}
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return <div>AdjustmentsTab</div>
}

export default AdjustmentsTab
