import { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { localizedDaysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { Check, cn } from '@sweetspot/scramble-ds'
import { GolfCourseTypes } from '@sweetspot/shared/types'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { useAvailabilityAndPricingContext } from '../../../AvailabilityAndPricingProvider'
import { AllCheckboxValues } from '../../../types'

const PRICE_TABLE_CELL_HEIGHT = 40
// const PRICE_TABLE_OFFSET = 50
const DEFAULT_PRICE_BODY_CONTAINER_HEIGHT = 400

const PricingTable = () => {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLInputElement>(null)
  const lang = useSelector((state: RootState) => state.auth.me.lang)
  const [priceTableBodyContainerHeight, setPriceTableBodyContainerHeight] = useState<
    number | string | undefined
  >()
  const {
    currentCourse,
    selectedCells,
    allCheckbox,
    onAllCheckboxChange,
    DAYS_OF_WEEK,
    timeSlots,
    tableData,
    selectedCols,
    onColumnClick,
    selectedRows,
    onRowClick,
    onCellClick,
  } = useAvailabilityAndPricingContext()

  const daysMap: {
    [key: string]: string
  } = {}
  const formState: {
    week?: number
  } = {}

  const DAYS_OF_WEEK_LOCALE = localizedDaysOfWeek(lang, 1)

  const updateContainerHeight = useCallback(() => {
    setPriceTableBodyContainerHeight(
      containerRef.current?.offsetTop
        ? Math.max(
            window.innerHeight - containerRef.current?.offsetTop - 195,
            DEFAULT_PRICE_BODY_CONTAINER_HEIGHT
          )
        : DEFAULT_PRICE_BODY_CONTAINER_HEIGHT
    )
  }, [])

  useEffect(() => {
    const containerElement = containerRef.current

    const resizeObserver = new ResizeObserver(() => {
      updateContainerHeight()
    })

    if (containerElement) {
      resizeObserver.observe(containerElement)
    }

    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
  }, [updateContainerHeight])

  const hasExtraPlayers = useMemo(
    () => currentCourse?.type !== GolfCourseTypes.COURSE,
    [currentCourse?.type]
  )

  return (
    <div ref={containerRef} className="mb-10 flex w-[100%] flex-1 overflow-x-auto">
      <div className="relative flex flex-col justify-stretch">
        <div>
          <table className="border-separate border-spacing-0">
            <thead>
              <tr>
                <th
                  className={cn(
                    'bg-background-mono-lighter w-[96px] cursor-pointer text-center',
                    'border-border-stroke-pale rounded-tl-md border'
                  )}
                  style={{ height: PRICE_TABLE_CELL_HEIGHT }}
                >
                  <Check
                    onCheckedChange={onAllCheckboxChange}
                    label={t('words.all')}
                    checked={allCheckbox === AllCheckboxValues.ALL}
                    labelClassName="!mt-0 text-text-dark"
                    className="ml-auto mr-auto h-auto items-center gap-2 px-0"
                    indeterminate={allCheckbox === AllCheckboxValues.SOME}
                  />
                </th>
                {DAYS_OF_WEEK_LOCALE.map((day, dayIndex) => {
                  return (
                    <th
                      className={cn(
                        'bg-background-mono-lighter text-text-dark w-[154px] cursor-pointer text-center transition-colors',
                        'border-border-stroke-pale border border-l-0 last:rounded-tr-md',
                        selectedCols?.[dayIndex] &&
                          tableData?.[day] &&
                          'bg-surface-secondary text-white'
                      )}
                      style={{ height: PRICE_TABLE_CELL_HEIGHT }}
                      key={day}
                      onClick={() => onColumnClick(dayIndex, selectedCols?.[dayIndex])}
                      data-disabled={!tableData?.[day]}
                    >
                      <div>
                        <span className="text-content-sm font-semibold uppercase">
                          {day.substr(0, 3)}
                        </span>
                        {formState.week !== -1 && (
                          <span className="text-content-xs">{daysMap[day]}</span>
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
          </table>
        </div>
        <div
          className="scrollbar-hide overflow-y-auto"
          style={{ height: priceTableBodyContainerHeight }}
        >
          <table className="border-separate border-spacing-0">
            <thead>
              <tr>
                {new Array(8).fill(null).map((_, idx) => (
                  <th key={idx}></th>
                ))}
              </tr>
            </thead>
            <tbody>
              {timeSlots.map((timeSlot, timeSlotIndex) => {
                const isLastRow = timeSlotIndex === timeSlots.length - 1

                return (
                  <tr key={timeSlotIndex}>
                    <th
                      onClick={() => onRowClick(timeSlotIndex, selectedRows?.[timeSlotIndex])}
                      className={cn(
                        'bg-background-mono-lighter text-text-dark w-[96px] cursor-pointer text-center transition-colors',
                        'border-border-stroke-pale border border-t-0',
                        isLastRow && 'rounded-bl-md',
                        selectedRows?.[timeSlotIndex] && 'bg-surface-secondary text-white'
                      )}
                    >
                      <span className="text-content-sm font-semibold">{timeSlot}</span>
                    </th>
                    {DAYS_OF_WEEK.map((day, dayIndex) => {
                      const cellId = `${timeSlotIndex}-${dayIndex}`
                      const timeSlot = tableData?.[day] && tableData[day][timeSlotIndex]
                      return (
                        <td
                          id={timeSlot ? cellId : ''}
                          key={dayIndex}
                          className={cn(
                            'bg-background-base-clean w-[154px] transition-colors',
                            'border-border-stroke-pale border border-l-0 border-t-0',
                            isLastRow && 'last:rounded-br-md',
                            selectedCells.has(cellId) && tableData?.[day] && 'bg-blue-50'
                          )}
                        >
                          <div
                            className="flex h-10 cursor-pointer items-center justify-center gap-2"
                            onClick={() => onCellClick(cellId, selectedCells.has(cellId))}
                          >
                            <span className="text-content-sm text-text-dark font-semibold">
                              {/* {timeSlot
                                ? timeSlot.price?.amount === 0
                                  ? timeSlot.price?.amount
                                  : timeSlot.price?.amount / 100 || '-'
                                : null} */}
                              -
                            </span>
                            {hasExtraPlayers && (
                              <span className="text-content-xs text-text-subtle pt-[1px] font-medium">
                                {/* {timeSlot
                                  ? timeSlot.price?.amount === 0
                                    ? timeSlot.price?.amount
                                    : timeSlot?.price_per_extra_player / 100 || '-'
                                  : null} */}
                                -
                              </span>
                            )}
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PricingTable
