import { differenceInMinutes, parse as parseTime, parseISO, isValid } from 'date-fns'
import {
  PeriodFormError,
  PeriodFormValue,
} from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'

const validateForm = ({
  name,
  startDate,
  endDate,
  startTimeFrom,
  startTimeTo,
  interval,
}: PeriodFormValue): PeriodFormError => {
  const errors: PeriodFormError = {}

  // ─────────────────────────────────────────────────────────────────
  // Validate 'name' length (1–40 characters)
  // ─────────────────────────────────────────────────────────────────
  if (!name || name.trim().length < 1 || name.trim().length > 40) {
    errors.name = 'sentences.invalidPeriodName'
  }

  // ─────────────────────────────────────────────────────────────────
  // Validate date range: endDate must be >= startDate
  // ─────────────────────────────────────────────────────────────────
  if (startDate && endDate) {
    const parsedStart = parseISO(startDate)
    const parsedEnd = parseISO(endDate)

    if (!isValid(parsedStart) || !isValid(parsedEnd)) {
      errors.dateRange = 'sentences.invalidDateFormat'
    } else if (parsedEnd < parsedStart) {
      errors.dateRange = 'sentences.startEndDateValidationError'
    }
  }

  // ─────────────────────────────────────────────────────────────────
  // Time difference must be a multiple of interval
  // ─────────────────────────────────────────────────────────────────
  if (startTimeFrom && startTimeTo && interval) {
    let diffInMinutes = 0
    try {
      const parsedFrom = parseTime(startTimeFrom, 'HH:mm:ss', new Date())
      const parsedTo = parseTime(startTimeTo, 'HH:mm:ss', new Date())
      diffInMinutes = differenceInMinutes(parsedTo, parsedFrom)
    } catch (error) {
      errors.openingHours = 'sentences.invalidTimeFormat'
      return errors
    }

    if (diffInMinutes < 0) {
      errors.openingHours = 'sentences.startEndTimeValidationError'
    } else if (diffInMinutes % interval !== 0) {
      errors.openingHours = 'sentences.startEndTimeAndIntervalConflict'
    }
  }

  return errors
}

export default validateForm
