import { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import {
  Button,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { GolfCourseTypes } from '@sweetspot/shared/types'
import {
  DEFAULT_BOOKING_CANCEL_POINT,
  DEFAULT_BOOKING_DISPLAY_TEE_TIME_DAYS,
} from '@sweetspot/shared/util/constants'

import { useManageSimulatorContext } from '../ManageSimulatorProvider'
import Details from './Details'
import useCreateSimulatorMutation from '../hooks/useCreateSimulatorMutation'
import { CreateSimulatorPayload } from '../types'

const CreateSimulatorSideBar = () => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [vat, setVat] = useState<string>('')
  const { addToast } = useToasts()
  const { toggleCreateSimulatorSideBar, openCreateSimulatorSidebar } = useManageSimulatorContext()
  const createSimulatorMutation = useCreateSimulatorMutation()
  const currentClubUuid = useSelector((state: RootState) => {
    const clubUuId = state?.golfClub?.list?.find(
      (club) => club.id === state?.golfClub?.selectedId || ''
    )?.uuid
    return clubUuId as string
  })

  const resetForm = useCallback(() => {
    setName('')
    setVat('')
  }, [])

  useEffect(() => {
    // reset form when the sidebar is closed
    resetForm()
  }, [openCreateSimulatorSidebar, resetForm])

  const checkForErrors = useCallback(
    ({ name, vat }: Pick<CreateSimulatorPayload, 'name' | 'vat'>): string => {
      if (name?.trim() === '') return 'settings.nameRequired'
      if (!vat || vat < 1 || vat > 100)
        return `${t('words.vat')}: ${t('errors.valueMustBeBetweenThese', { this: 1, that: 100 })}`
      return ''
    },
    [t]
  )

  const handleCreateSimulator = useCallback(async () => {
    const payload: CreateSimulatorPayload = {
      organizationId: currentClubUuid,
      name,
      vat: parseFloat(vat),
      displayTeeTimeDays: DEFAULT_BOOKING_DISPLAY_TEE_TIME_DAYS,
      isActive: false,
      isCanPay: true,
      isArrivalRegistration: false,
      isArrivalRegistrationAfterSchedule: false,
      bookingCancellationLimitHours: DEFAULT_BOOKING_CANCEL_POINT,
      nonConfirmedBookingAutoCancellationEnabled: false,
      nonConfirmedBookingAutoCancellationDuration: null,
      unpaidBookingAutoCancellationEnabled: false,
      isStubPlayersEnabled: false,
      isPayOnSiteEnabled: false,
      is_enabled: false,
      membershipSignUpSettings: {
        isEnabled: false,
        memberships: [],
      },
      type: GolfCourseTypes.SIMULATOR,
      isUseDynamicPricing: false,
      bookingType: -1,
    }
    const errorText = checkForErrors(payload)

    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else {
      createSimulatorMutation.mutate(payload)
    }
  }, [name, vat, currentClubUuid, addToast, t, checkForErrors, createSimulatorMutation])

  return (
    <Sheet open={openCreateSimulatorSidebar} onOpenChange={toggleCreateSimulatorSideBar}>
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={toggleCreateSimulatorSideBar}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>
            {t('settings.createNewCourse', { type: t('words.simulator_one').toLowerCase() })}
          </SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <Details name={name} setName={setName} vat={vat} setVat={setVat} />
        </SheetCustomContent>
        <SheetFooter>
          <Button onClick={handleCreateSimulator} className="min-h-touch-height-lg w-full">
            {t('create')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default CreateSimulatorSideBar
