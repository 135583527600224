import { apiPlatformClient } from '@sweetspot/shared/data-access/api-platform'
import { Period } from '@sweetspot/shared/types'
import { QueryPeriodsPayload } from '@sweetspot/sweetspot-js/features/period/services/types'

export const queryPeriods = async (payload: QueryPeriodsPayload): Promise<{ data: Period[] }> => {
  const { courseId, page, limit, orderStartDate = 'desc', pagination = false } = payload

  // @ts-expect-error-next-line
  return apiPlatformClient().GET('/api/periods', {
    params: {
      query: {
        'course.id': courseId,
        page,
        limit,
        'order[start_date]': orderStartDate,
        pagination,
      },
    },
  })
}
