import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

const HEADERS = {
  name: {
    name: '.name',
    type: 'label',
    valueType: 'string',
    width: '250px',
    selected: true,
    disabled: true,
    isSortable: true,
  },
  age: {
    name: '.age',
    type: 'label',
    valueType: 'number',
    width: '70px',
    selected: true,
    isSortable: true,
  },
  email: {
    name: '.email',
    type: 'label',
    valueType: 'string',
    width: '250px',
    selected: true,
  },
  phone: {
    name: '.phone',
    type: 'label',
    valueType: 'string',
    width: '180px',
    selected: true,
  },
  isRegistered: {
    name: '.SSUser',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    isSortable: true,
  },
  gender: {
    name: '.settings.playerCard.gender',
    type: 'label',
    valueType: 'string',
    selected: true,
  },
  golfId: {
    name: '.golfId',
    type: 'label',
    valueType: 'string',
    width: '150px',
    selected: true,
  },
  hcp: {
    name: '.hcp',
    type: 'label',
    valueType: 'number',
    display: (hcp) => Utilities.displayHCP(hcp),
    selected: true,
    isSortable: true,
  },
  lockState: {
    name: {
      text: '',
      icon: <i class="fa fa-lock fa-solid" aria-hidden="true" />,
    },
    type: 'lockState',
    valueType: 'string',
    width: '50px',
    selected: true,
  },
  membership: {
    name: '.membershipPassText',
    type: 'membershipList',
    valueType: 'array',
    width: '300px',
    selected: true,
  },
  federationMembership: {
    name: '.gitMembership',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    isSortable: true,
  },
  playRight: {
    name: '.playRight',
    type: 'label',
    valueType: 'string',
    width: '100px',
    selected: true,
    isSortable: true,
  },
  homeClub: {
    name: '.homeClub',
    type: 'label',
    valueType: 'string',
    width: '250px',
    selected: true,
    isSortable: true,
  },
  playValue: {
    name: '.playValue',
    type: 'label',
    valueType: 'string',
    width: '120px',
    selected: true,
    isSortable: true,
  },
  numberOfRounds: {
    name: '.numberOfRounds',
    type: 'label',
    valueType: 'string',
    width: '80px',
    selected: true,
    isSortable: true,
  },
  paidValue: {
    name: '.paidValue',
    type: 'label',
    valueType: 'status',
    width: '120px',
    selected: true,
    isSortable: true,
  },
  lastPlayed: {
    name: '.lastPlayed',
    type: 'label',
    valueType: 'string',
    width: '150px',
    selected: true,
    isSortable: true,
  },
}

export default HEADERS
