import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import MaskedInput from 'react-text-mask'

import styles from './styles.module.scss'

const InputField = (props) => {
  const {
    hasPrefix,
    hasSuffix,
    maskOptions,
    autoFocus,
    onEnterPress,
    onTabPress,
    theme,
    colorTheme,
    className,
    onFocus,
    onBlur,
    isExternalFocused,
    reducePaddingRight,
    innerRef,
    step,
    lessDarker,
    calendarPrefix,
    placeholder,
    readOnly,
    ...rest
  } = props
  const inputRef = useRef()
  const blurViaEnter = useRef(false)

  useEffect(() => {
    if (innerRef) {
      innerRef.current = inputRef.current
    }
  }, [innerRef])

  useEffect(() => {
    const current = maskOptions ? inputRef?.current?.inputElement : inputRef.current
    if (autoFocus) {
      if (current?.focus) current.focus()
    } else {
      if (current?.blur) current.blur()
    }
  }, [autoFocus])

  useEffect(() => {
    const current = maskOptions ? inputRef?.current?.inputElement : inputRef.current

    const handleOnEnterInInput = (e) => {
      if (
        (e?.keyCode === 13 || e?.which === 13 || e?.key === 'Enter' || e?.code === 'Enter') &&
        onEnterPress
      ) {
        e.preventDefault()
        e.stopPropagation()
        onEnterPress(e.target.value)
        blurViaEnter.current = true
        if (current?.blur) current.blur()
        blurViaEnter.current = false
        return false
      }
    }

    if (current?.addEventListener) {
      current.addEventListener('keydown', handleOnEnterInInput)

      return () => current.removeEventListener('keydown', handleOnEnterInInput)
    }
  }, [inputRef, onEnterPress])

  const handleBlur = (e) => {
    if (onBlur) onBlur(e, blurViaEnter.current)
  }

  const handleFocus = (e) => {
    if (onFocus) onFocus(e)
  }

  if (maskOptions) {
    return (
      <MaskedInput
        autoComplete="off"
        ref={inputRef}
        readOnly={readOnly}
        placeholder={placeholder}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...rest}
        className={cx(
          styles.textInput,
          styles.defaultHeight,
          styles.borderDefault,
          {
            [styles.borderError]: props.error,
            [styles.borderSuccess]: props.success,
            [styles.borderDisabled]: props.disabled,
            [styles.textInputDisabled]: props.disabled,
            [styles.borderFilled]: props.value,
          },
          {
            [styles.hasPrefix]: hasPrefix,
            [styles.hasSuffix]: hasSuffix,
          },
          {
            [styles.reducePaddingRight]: rest.type === 'number' || reducePaddingRight,
          },
          {
            [styles.clubTheme]: theme === 'club',
            [styles.webAppTheme]: theme === 'webApp' && !lessDarker,
            [styles.lessDarker]: theme === 'webApp' && lessDarker,
          },
          {
            [styles.whiteOutlineTheme]: colorTheme === 'white-outline',
          },
          {
            [styles.isFocused]: isExternalFocused === true,
          },
          {
            [styles.withCalendarPrefix]: calendarPrefix,
          },

          className,
          'no-override'
        )}
        onKeyDown={(e) =>
          rest.type === 'number' && ['e', 'E'].includes(e.key) && e.preventDefault()
        }
        {...maskOptions}
      />
    )
  }

  const handleKeyDown = (e) => {
    if (rest.type === 'number' && ['e', 'E'].includes(e.key)) e.preventDefault()

    if (e.key === 'Tab' && onTabPress) onTabPress()
  }

  return (
    <input
      autoComplete="off"
      step={step}
      ref={inputRef}
      readOnly={readOnly}
      placeholder={placeholder}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...rest}
      className={cx(
        styles.textInput,
        styles.defaultHeight,
        styles.borderDefault,
        {
          [styles.borderError]: props.error,
          [styles.borderSuccess]: props.success,
          [styles.borderDisabled]: props.disabled,
          [styles.textInputDisabled]: props.disabled,
          [styles.borderFilled]: props.value,
        },
        {
          [styles.hasPrefix]: hasPrefix,
          [styles.hasSuffix]: hasSuffix,
        },
        {
          [styles.reducePaddingRight]: rest.type === 'number' || reducePaddingRight,
        },
        {
          [styles.clubTheme]: theme === 'club',
          [styles.webAppTheme]: theme === 'webApp' && !lessDarker,
          [styles.lessDarker]: theme === 'webApp' && lessDarker,
          [styles.ppTheme]: theme === 'pp',
          [styles.loginTheme]: theme === 'login',
        },
        {
          [styles.whiteOutlineTheme]: colorTheme === 'white-outline',
        },
        {
          [styles.readOnly]: rest?.readOnly === true,
        },
        {
          [styles.isFocused]: isExternalFocused === true,
        },
        className,
        'no-override'
      )}
      onKeyDown={handleKeyDown}
    />
  )
}

InputField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  success: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'date']),
  hasPrefix: PropTypes.bool,
  hasSuffix: PropTypes.bool,
  maskOptions: PropTypes.object,
  autoFocus: PropTypes.bool,
  onEnterPress: PropTypes.func,
  theme: PropTypes.oneOf(['webApp', 'club', 'pp', 'voucherA', 'voucherB', 'login']),
  colorTheme: PropTypes.oneOf(['white-outline', 'default', 'login']),
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onTabPress: PropTypes.func,
  isExternalFocused: PropTypes.bool,
  reducePaddingRight: PropTypes.bool,
  lessDarker: PropTypes.bool,
  calendarPrefix: PropTypes.bool,
  readOnly: PropTypes.bool,
}
InputField.defaultProps = {
  error: '',
  success: '',
  disabled: false,
  onChange: () => {},
  placeholder: '',
  type: 'text',
  hasPrefix: false,
  hasSuffix: false,
  maskOptions: null,
  autoFocus: false,
  theme: 'club',
  colorTheme: 'default',
  onTabPress: null,
  isExternalFocused: false,
  reducePaddingRight: false,
  lessDarker: false,
  calendarPrefix: false,
  readOnly: false,
}

export default InputField
