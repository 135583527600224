import { format } from 'date-fns'
import { priceToLocal } from '@sweetspot/shared/util/functions'

const formatDate = (dateValue, row) => {
  const date = new Date(dateValue || row?.paid_at)
  const formattedDate = format(date, 'dd MMM')

  return formattedDate
}

const formatMonthTerm = (month, term) => {
  if (!month || !term) return '1/1'

  return `${month}/${term}`
}

const headers = {
  'installment_loan.payment_date': {
    name: '.words.date',
    type: 'label',
    valueType: 'string',
    display: (date, row) => formatDate(date, row),
    selected: true,
    disabled: true,
  },
  'amount.currency': {
    name: '.words.amount',
    type: 'label',
    valueType: 'string',
    display: (currency, row) => priceToLocal(row.amount.value, currency, true),
    selected: true,
    disabled: true,
  },
  'installment_loan.term': {
    name: '.words.NR',
    type: 'label',
    valueType: 'string',
    display: (month, row) => formatMonthTerm(row.installment_loan.month, row.installment_loan.term),
    selected: true,
    disabled: true,
  },
  state: {
    name: '.words.status',
    type: 'label',
    valueType: 'string',
    display: (state) => state[0].toUpperCase() + state.slice(1),
    selected: true,
    disabled: true,
  },
}

export default headers
