import React, { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
} from '@sweetspot/scramble-ds'
import { useMutation } from 'react-query'
import { _deleteBookingPeriod } from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'
import { ViolationError } from '@sweetspot/shared/types'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import { useToasts } from 'react-toast-notifications'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

type DeleteDialogProps = {
  periodUuid: string
}

const DeleteActionItem = ({ periodUuid }: DeleteDialogProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { t } = useTranslation()

  const { refetchPeriods, autoSelectPeriod } = useAvailabilityAndPricingContext()

  const { addToast } = useToasts()

  const { lang } = useSelector((state: RootState) => state.auth.me)

  const deletePeriodMutation = useMutation(() => _deleteBookingPeriod(periodUuid, lang), {
    onSuccess: async () => {
      // Wait 1 second
      await new Promise((resolve) => setTimeout(resolve, 1000))
      // Then refetch periods
      await refetchPeriods()
      // And then autoSelectPeriod
      autoSelectPeriod()
      setIsDeleteDialogOpen(false)
    },
    onError: (err: ViolationError) => {
      let errMsg = 'deletePeriodError'
      if (err?.violations?.length) {
        errMsg = err.violations[0].message
      }
      addToast(t(errMsg), { appearance: 'error' })
      setIsDeleteDialogOpen(false)
    },
  })

  const onDeleteClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDeleteDialogOpen(true)
  }

  const deletePeriod = () => {
    deletePeriodMutation.mutate()
  }
  return (
    <>
      <ListItem
        onClick={onDeleteClick}
        className="text-text-dark text-content-sm h-8 min-h-8 w-full min-w-fit cursor-pointer gap-2 p-1 font-medium"
      >
        <ListItemLeading className="px-0">
          <i className="fa-regular fa-trash-can [&>svg]:h-4 [&>svg]:w-4" />
        </ListItemLeading>
        <ListItemMainContainer>
          <ListItemMainContent className="py-0">
            <ListItemMainLabel className="h-auto min-h-6 w-[73px]">
              {t('words.delete')}
            </ListItemMainLabel>
          </ListItemMainContent>
        </ListItemMainContainer>
      </ListItem>
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent variant="default">
          <DialogHeader>
            <DialogTitle>{t('sentences.deletePeriod')}</DialogTitle>
            <DialogDescription>
              {t('settings.deleteCourseTitle', { type: t('words.period').toLowerCase() })}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                variant="ghost-dark"
                type="button"
                size="large"
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                {t('words.cancel')}
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button variant="primary" size="large" onClick={deletePeriod}>
                {t('words.delete')} {t('words.period').toLowerCase()}
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DeleteActionItem
