import { Membership, MembershipCard, MembershipCardPayment } from '@sweetspot/shared/types'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { hasAccess } from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { MembershipActions } from '@sweetspot/club-portal-legacy/modals/PlayerCard/types'

const MEMBERSHIP_INSTALLMENT_LOAN = 'installment_loan'

export const sortMemberships = (memberships: MembershipCard[]) =>
  memberships.sort((a, b) => {
    if (a.status === 'active') return -1
    if (b.status === 'active') return 1
    if (a.status === 'upcoming') return -1
    if (b.status === 'upcoming') return 1
    if (a.state === 'paid') return -1
    if (b.state === 'paid') return 1
    return a.status.localeCompare(b.status)
  })

export const getAvailableActions = (
  membership: MembershipCard,
  membershipList: Membership[],
  role: string,
  isThereUpcomingPayments: boolean | undefined,
  paymentsData: MembershipCardPayment[] | undefined,
  isMembershipLocked: boolean,
  playRight?: boolean
) => {
  const isPaid =
    membership.is_paid ||
    membership.status === 'old' ||
    (paymentsData && paymentsData[0]?.state === 'paid')

  const isCancelled = membership.status === 'canceled'
  const isUpcomingPaymentsCancelled = paymentsData?.some((payment) => payment.state === 'canceled')
  const needToRefundPaidUpcomingPayments = paymentsData?.some(
    (paymentObj) => paymentObj?.state === 'paid' && paymentObj?.type === MEMBERSHIP_INSTALLMENT_LOAN
  )

  const shouldDisableCancelOrRefundButtom =
    !needToRefundPaidUpcomingPayments &&
    !isThereUpcomingPayments &&
    !isPaid &&
    (isCancelled || membership?.state === 'new')

  const actions: {
    id: number
    label: string
    disabled?: boolean
    subOptions?: {
      id: number
      name: string
      disabled?: boolean
    }[]
    accessKey?: string
  }[] = [
    {
      id: MembershipActions.ADD_MEMBERSHIP,
      label: 'players.addToMembershipPass',
      disabled: !membershipList.length,
      subOptions: membershipList
        .filter((item) => item.state !== 'draft' && item.is_active)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => ({
          id: item.id,
          name: item.name,
          disabled: !playRight && item.play_right_only,
        })),
      accessKey: ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.ADD,
    },
    {
      id: MembershipActions.MARK_AS_PAID,
      label: 'players.markAsPaid',
      accessKey: ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.MARK_AS_PAID,
      disabled: isPaid || isCancelled,
    },
    {
      id: MembershipActions.REMOVE_MEMBERSHIP,
      label: 'players.removeMembership',
      disabled: isPaid || isUpcomingPaymentsCancelled || isCancelled,
      accessKey: ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.REMOVE_FROM_MEMBERSHIP,
    },
    {
      id: MembershipActions.LOCK_UNLOCK_MEMBERSHIP,
      label: isMembershipLocked ? 'players.unlockMembershipPass' : 'players.lockMembershipPass',
      disabled: !isPaid,
      accessKey: ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.LOCK_UNLOCK_MEMBERSHIP,
    },
    {
      id: MembershipActions.CANCEL_OR_REFUND,
      label: 'players.cancelOrRefund',
      disabled: shouldDisableCancelOrRefundButtom,
      accessKey: ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.REFUND,
    },
  ].filter((option) => hasAccess(option.accessKey, role))

  if (membership?.membership?.channels?.length) {
    actions.push({
      id: MembershipActions.SEND_PAYMENT_LINK,
      label: 'players.sendPaymentLink',
      disabled: isPaid,
    })
  }

  return actions
}
