import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { useToasts } from 'react-toast-notifications'

import { EmptyState, EmptyStateTitle } from '@sweetspot/scramble-ds'
import { MAX_IMAGE_SIZE } from '@sweetspot/shared/util/constants'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'

import useUpdateRange from '../../hooks/useUpdateRange'

type UploadImageProps = {
  currentCourse?: GolfCourseItem
  setImage: (image?: string) => void
  setValue: (imageId?: number) => void
  image?: string
}

const UploadImage = ({ currentCourse, setImage, setValue, image }: UploadImageProps) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { uploadImage, isImageLoading } = useUpdateRange({ currentCourse })

  const handleImageUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const imageToUpload = e.target.files?.[0]
      if (!imageToUpload) return
      const types = ['image/jpeg', 'image/png']
      if (types.every((type) => imageToUpload.type !== type)) {
        addToast(t('settings.imageTypeError'), { appearance: 'error' })
      } else if (imageToUpload.size >= MAX_IMAGE_SIZE.BYTES) {
        addToast(t('toast.imageSizeError', { size: MAX_IMAGE_SIZE.TEXT }), { appearance: 'error' })
      } else {
        uploadImage(imageToUpload).then((res) => {
          setImage(res?.content_url)
          setValue(res?.id)
        })
      }
    },
    [addToast, t, uploadImage, setValue, setImage]
  )

  const renderImage = useCallback(() => {
    if (isImageLoading)
      return (
        <EmptyState
          iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-5"
          loading
        >
          <EmptyStateTitle>{t('words.loading')}</EmptyStateTitle>
        </EmptyState>
      )
    if (!image)
      return (
        <EmptyState iconName="fa-light fa-image bg-background-mono-lighter mb-4 flex rounded-full p-5">
          <EmptyStateTitle>
            {t('settings.noImage', { type: t('words.range_one').toLowerCase() })}
          </EmptyStateTitle>
        </EmptyState>
      )
    if (image) return <img src={image} alt="rangeImage" />
  }, [image, isImageLoading, t])

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-4 pb-4">
        <div>
          <div className="text-content-sm flex min-h-5 items-end self-stretch text-ellipsis font-bold">
            {t('settings.range.rangeImage')}
          </div>
          <div className="text-content-xs text-text-dark">
            {t('settings.imageHint', { size: MAX_IMAGE_SIZE.TEXT })}
          </div>
        </div>
        <label
          htmlFor="image-upload"
          className={cn(
            'h-touch-height-sm text-content-sm font-medium',
            'text-text-dark ring-border-stroke-subtle bg-transparent ring-2 ring-inset',
            'hover:bg-surface-clean-hover hover:text-text-dark hover:shadow-lg',
            'active:bg-surface-clean active:text-text-dark active:ring-0',
            'disabled:bg-surface-clean disabled:text-text-clean disabled:ring-border-stroke-clean disabled:ring-2 disabled:ring-inset',
            'px-lg inline-flex items-center justify-center gap-x-2 whitespace-nowrap transition-all disabled:pointer-events-none',
            'focus-visible:ring-border-stroke-focused outline-none focus-visible:ring-2 focus-visible:ring-inset',
            'cursor-pointer rounded-full'
          )}
        >
          <input id="image-upload" className="hidden" type="file" onChange={handleImageUpload} />
          <i className="fa-regular fa-plus" />
          {t('settings.uploadNew')}
        </label>
      </div>

      <div className="text-content-xs text-text-dark pb-2 font-bold">
        {t('settings.currentImage')}
      </div>

      <div className="bg-background-base-neutral flex h-[191px] w-full flex-col items-center justify-center overflow-hidden rounded-md">
        {renderImage()}
      </div>
    </div>
  )
}

export default UploadImage
