import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import SpacesTable from './Table'
import { useManageSimulatorSpacesContext } from '../ManageSimulatorSpacesProvider'
import { SpaceSideBarMode } from '../types'

const SpacesTableContainer = () => {
  const { t } = useTranslation()
  const {
    simulators,
    spaces,
    areSimulatorsLoading,
    areSpacesLoading,
    areSpacesFetching,
    toggleSpaceSideBar,
  } = useManageSimulatorSpacesContext()
  const currentClubName = useSelector((state: RootState) => {
    const currentClub = state?.golfClub?.list?.find(
      (club) => club.id === state?.golfClub?.selectedId || ''
    )
    return currentClub?.name as string
  })

  const emptyStateKey = `loading-${areSpacesLoading || areSimulatorsLoading}-simulators-${
    simulators?.length || 0
  }-spaces-${spaces?.length || 0}`

  if (areSpacesFetching || areSpacesLoading || areSimulatorsLoading) {
    return (
      <EmptyState
        key={`${emptyStateKey}-loading`}
        iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
        loading
      >
        <EmptyStateTitle>{t('sentences.loadingSpaces')}</EmptyStateTitle>
        <EmptyStateDescription>{t('settings.bays.hangOnTight')}</EmptyStateDescription>
      </EmptyState>
    )
  }

  if (!simulators?.length) {
    return (
      <EmptyState
        key={`${emptyStateKey}-noSimulators`}
        iconName="fa-light fa-flip-both fa-circle-exclamation bg-background-mono-lighter mb-4 flex rounded-full p-4"
      >
        <EmptyStateTitle>
          {t('settings.noCoursesCreated', { type: t('words.simulator_one').toLowerCase() })}
        </EmptyStateTitle>
        <EmptyStateDescription>
          {t('settings.createAtLeastOneCourse', {
            name: currentClubName,
            type: t('words.simulator_one').toLowerCase(),
          })}
        </EmptyStateDescription>
        <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
          <Link
            className="height-full flex w-full items-center justify-center"
            to={'/settings/simulators/manage'}
            target={'_blank'}
          >
            <i className="fa-regular fa-arrow-up-right-from-square mr-2"></i>
            {t('settings.createFirstCourse', { type: t('words.simulator_one').toLowerCase() })}
          </Link>
        </EmptyStateButton>
      </EmptyState>
    )
  }

  if (!spaces?.length) {
    return (
      <EmptyState key={`${emptyStateKey}-noSpaces`} iconName="fa-kit fa-sim-light">
        <EmptyStateTitle>{t('settings.spaces.noSpacesCreated')}</EmptyStateTitle>
        <EmptyStateDescription>
          <div className="mb-4 text-center">{t('sentences.createSpace_one')}</div>
        </EmptyStateDescription>
        <EmptyStateButton
          onClick={() => toggleSpaceSideBar(SpaceSideBarMode.CREATE)}
          className="text-content-sm font-medium"
        >
          {t('settings.spaces.createFirstSpace')}
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return <SpacesTable spaceData={spaces} />
}

export default SpacesTableContainer
