import React, { FC, useEffect, useMemo } from 'react'

import RadioGroup from '@sweetspot/sweetspot-js/common/components/FormElements/RadioGroup'
import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'
import moment from 'moment'
import { ANNUAL_DURATION } from '@sweetspot/club-portal-legacy/modals/PlayerCard/constants'
import { Membership } from '@sweetspot/shared/types'
import { usePlayerCardContext } from '@sweetspot/club-portal/data-access/context-providers'
import { Button } from '@sweetspot/scramble-ds'

const MONTH_12 = 2
const PERIOD = 3

const currentYear = new Date().getFullYear()
const yearOptions = [
  {
    value: currentYear,
    label: currentYear.toString(),
  },
  {
    value: currentYear + 1,
    label: (currentYear + 1).toString(),
  },
]

type Props = {
  disabled: boolean
  playerName: string
  membership?: Membership | null
  onConfirm: () => void
  onCancel: () => void
}

const EmailConfirm: FC<Props> = ({ disabled, playerName, membership, onConfirm, onCancel }) => {
  const { duration, setDuration, year, setYear } = usePlayerCardContext()

  useEffect(() => {
    if (
      !membership?.duration.options.filter((duration) => duration.includes('annual_duration'))
        .length
    ) {
      setDuration(MONTH_12)
    }
  }, [membership, setDuration])

  const durationOptions = useMemo(
    () =>
      membership?.duration.options.map((item) => ({
        value: item.includes('annual_duration')
          ? ANNUAL_DURATION
          : item.includes('period')
          ? PERIOD
          : MONTH_12,
        label: `.membership.overview.${item}`,
      })),
    [membership]
  )

  const getPeriodText = useMemo(() => {
    const fromText = membership?.duration.from
      ? moment(membership.duration.from).format('YYYY-MM-DD')
      : ''
    const toText = membership?.duration.to
      ? moment(membership.duration.to).format('YYYY-MM-DD')
      : '.membership.options.noEnd'

    return (
      <div className={style.durationPeriod}>
        <Text textId={fromText} /> - <Text textId={toText} />
      </div>
    )
  }, [membership?.duration.from, membership?.duration.to])

  return (
    <OverlayContainer>
      <div className={style.container}>
        <h5>
          <Text textId="players.assign" />
          &nbsp;
          <div>{membership?.name}</div>
          &nbsp;
          <Text textId="players.to" />
          &nbsp;
          <div>{playerName}?</div>
        </h5>

        <div className={style.choiceContainer}>
          <div className={style.duration}>
            <h4>
              <Text textId="players.selectDuration" />
            </h4>
            <RadioGroup
              value={duration}
              disabled={disabled}
              onChange={(value) => setDuration(value)}
              options={durationOptions}
            />
          </div>
          {duration === 1 && (
            <div className={style.duration}>
              <h4>
                <Text textId="players.selectYear" />
              </h4>
              <RadioGroup
                value={year}
                disabled={disabled}
                onChange={(value) => setYear(value)}
                options={yearOptions}
              />
            </div>
          )}
          {duration === 3 && (
            <div className={style.duration}>
              <h4>
                <Text textId="membership.options.period" />
              </h4>
              {getPeriodText}
            </div>
          )}
        </div>
        <div className="flex w-full justify-end gap-[10px]">
          <Button size="medium" variant="ghost-dark" onClick={!disabled ? onCancel : undefined}>
            <Text className="text-base" textId="cancel" />
          </Button>
          <Button size="medium" onClick={!disabled ? onConfirm : undefined}>
            <Text className="text-base" textId="words.assign" />
          </Button>
        </div>
      </div>
    </OverlayContainer>
  )
}

export default EmailConfirm
