import PricingTable from './PricingTable'
import PricingTableFilters from './PricingTableFilters'

const PricingPeriodTabContent = () => {
  return (
    <div className="mt-10 flex h-full flex-col gap-4">
      <PricingTableFilters />
      <PricingTable />
    </div>
  )
}

export default PricingPeriodTabContent
