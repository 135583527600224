import React, { useMemo } from 'react'
import {
  Check,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@sweetspot/scramble-ds'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import MiniCalendar from '@sweetspot/club-portal-legacy/components/MiniCalendar'
import { Trans, useTranslation } from 'react-i18next'
import {
  formatDateInTimeZone,
  formatEndOfDayInTimeZone,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import cx from 'classnames'

type DatePickerProps = {
  date: string
  formattedDate: string
  label: string
  onChange: (date: string) => void
  disabled?: boolean
}

const DatePicker = ({ date, formattedDate, label, onChange, disabled }: DatePickerProps) => {
  return (
    <div className="flex flex-col gap-0.5">
      <p
        className={`text-text-${
          disabled ? 'pale' : 'dark'
        } text-content-xs flex h-5 items-end px-2 font-bold`}
      >
        {label}
      </p>
      <TextInputField
        containerClassName={
          'border-border-stroke-clean bg-background-base-clean flex h-8 cursor-pointer items-center gap-1 rounded-md border pl-4 pr-2'
        }
        inputFieldClassName={`${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } p-0 border-none max-h-[30px] ${
          disabled ? 'text-text-medium' : 'text-text-dark'
        } placeholder:text-text-medium placeholder:text-content-base placeholder:italic`}
        type="calendar"
        value={date}
        placeholder="yyyy-mm-dd"
        calendarSettings={{
          selectedDate: formattedDate,
          returnDateFormat: 'YYYY-MM-DD',
        }}
        onChange={(value) => onChange(value)}
        CalendarComponent={MiniCalendar}
        calendarPrefix={false}
        noMargin
        readOnly
        disabled={disabled}
      />
    </div>
  )
}

type PeriodDatesProps = {
  startDate: string
  endDate: string
  timezone: string
  onStartDateChange: (date: string) => void
  onEndDateChange: (date: string) => void
  showWholePeriodOption?: boolean
  isWholePeriodChecked?: boolean
  onWholePeriodChange?: (checked: boolean) => void
  readOnly?: boolean
  tooltipContent?: string
}

const PeriodDates = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  timezone,
  showWholePeriodOption,
  isWholePeriodChecked,
  onWholePeriodChange,
  readOnly,
  tooltipContent,
}: PeriodDatesProps) => {
  const { t } = useTranslation()

  const [startDateInProvidedTimezone, endDateInProvidedTimezone] = useMemo(
    () => [formatDateInTimeZone(startDate, timezone), formatEndOfDayInTimeZone(endDate, timezone)],
    [endDate, startDate, timezone]
  )

  return (
    <div className="p-md bg-background-base-neutral gap-1 rounded-md border-0 ">
      <div className="px-sm flex justify-between gap-4 py-2">
        <p className="text-text-dark text-content-base overflow-hidden text-ellipsis whitespace-nowrap font-bold">
          {t('words.dates')}
        </p>
        {tooltipContent && (
          <div className="flex h-6 w-6 items-center justify-end p-1">
            <TooltipProvider key="datesTooltip">
              <Tooltip>
                <React.Fragment key="datesTooltip">
                  <TooltipTrigger asChild>
                    <FontAwesomeIcon icon={faCircleInfo} className="text-text-subtle" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="flex w-[263px] flex-col gap-4 py-2">
                      <p className="text-content-sm text-text-light font-bold">
                        {t('words.dates')}
                      </p>
                      <p className="text-content-sm text-text-light font-regular">
                        <Trans i18nKey={tooltipContent} components={{ br: <br /> }} />
                      </p>
                    </div>
                    <TooltipArrow />
                  </TooltipContent>
                </React.Fragment>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>
      <div className="itemc-center flex justify-between gap-4">
        <DatePicker
          date={startDate}
          formattedDate={startDateInProvidedTimezone}
          label={t('words.from')}
          onChange={onStartDateChange}
          disabled={readOnly}
        />
        <DatePicker
          date={endDate}
          formattedDate={endDateInProvidedTimezone}
          label={t('words.to')}
          onChange={onEndDateChange}
          disabled={readOnly}
        />
      </div>
      {showWholePeriodOption && (
        <div className="mt-1 flex h-12 items-center">
          <Check
            className="h-auto gap-2 p-2 py-0"
            checked={!!isWholePeriodChecked}
            label={t(`editPeriodsNew.allPeriod`)}
            labelClassName="text-text-dark text-content-base font-regular"
            onCheckedChange={onWholePeriodChange}
          />
        </div>
      )}
    </div>
  )
}

export default PeriodDates
