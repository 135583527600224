import React from 'react'
import { Input, InputBase, InputContainer, InputLabelContainer } from '@sweetspot/scramble-ds'

const NameField = ({
  name,
  label,
  placeholder,
  onChange,
}: {
  name: string
  label: string
  placeholder: string
  onChange: (newName: string) => void
}) => (
  <InputBase>
    {label && <InputLabelContainer>{label}</InputLabelContainer>}
    <InputContainer>
      <Input
        value={name}
        onChange={(event) => onChange(event.target.value)}
        className={'pr-md h-8 w-full gap-2 border'}
        placeholder={placeholder}
      />
    </InputContainer>
  </InputBase>
)

export default NameField
