import Header from './Header'
import CreateAcademySideBar from './CreateAcademySideBar/CreateAcademySideBar'
import { ManageAcademyProvider } from './ManageAcademyProvider'
import FilterToolbar from './FilterToolbar/FilterToolbar'
import AcademyTabsContainer from './AcademyTabsContainer/AcademyTabsContainer'

const ManageAcademy = () => {
  return (
    <ManageAcademyProvider>
      <CreateAcademySideBar />
      <div className="flex h-full flex-col">
        <Header />
        <FilterToolbar />
        <div className={'flex flex-1 items-center justify-center'}>
          <AcademyTabsContainer />
        </div>
      </div>
    </ManageAcademyProvider>
  )
}

export default ManageAcademy
