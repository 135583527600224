import React from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { t } from 'i18next'

import { getLang } from '@sweetspot/club-portal-legacy/languages'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import SettingComponent from '@sweetspot/club-portal-legacy/pages/Settings/components/SettingComponent'

import {
  deleteGolfCourse,
  getGolfCoursesFromGolfClub,
} from '@sweetspot/club-portal-legacy/store/actions'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'

class DeleteGolfCourse extends SettingComponent {
  state = {
    golfCourseList: [],
    selectedGolfClub: null,
    selectedGolfCourse: null,
    isLoading: false,
  }

  confirmDelete = () => {
    const { auth, lang } = this.props
    if (!auth || !auth.loggedIn) return

    const text = this._confirm.value.trim().toLowerCase()
    if (text !== 'delete') {
      alert(getLang(lang)['settings']['confirmDelete'])
      return false
    }
    return true
  }

  handleDelete = () => {
    if (this.confirmDelete()) {
      let settings = {
        token: this.props.token,
        golfcourse_id: this.state.selectedGolfCourse,
      }

      this.props.deleteGolfCourse(...Object.values(settings)).then(() => {
        this._confirm.value = ''
      })
    }
  }

  renderSelects = () => {
    return (
      <div>
        <div className="ss-form-group">
          <label className="ss-label">{t('golfclub')}</label>
          <DropdownSelect
            showBorder
            width="100%"
            values={this.props.golfClubList}
            onSelect={(id) => {
              let club = this.props.golfClubList.find((club) => club.id === id)
              if (!club) return

              this.setState({
                selectedGolfClub: id,
              })

              this.props.getGolfCoursesFromGolfClub(club.id).then((golfCourses) => {
                // Filter out the courses that are part of a range context
                const filteredCourses = golfCourses?.filter(
                  (course) =>
                    !course.belongs_to_range_context && course.type !== COURSE_TYPES.PRO.value
                )
                this.setState({
                  golfCourseList: filteredCourses,
                })
              })
            }}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('golfcourse')}</label>
          <DropdownSelect
            showBorder
            width="100%"
            values={this.state.golfCourseList}
            onSelect={(value) => {
              this.setState({
                selectedGolfCourse: value,
              })
            }}
          />
        </div>
      </div>
    )
  }

  renderForm = () => {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="ss-form-group">
          <label className="ss-label">{t('settings.typeDelete')}</label>
          <input className="ss-input" type="text" ref={(input) => (this._confirm = input)} />
        </div>
        <div>
          <button
            className={`system-button danger md-32 ${style.formButtons}`}
            onClick={this.handleDelete}
          >
            {t('settings.delete')}
          </button>
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.label}>
          <Text textId="settings.deleteGolfcourse" />
          <div>
            <PulseLoader fillHeight showIf={this.state.isLoading} />
          </div>
        </div>
        {this.renderSelects()}
        {this.renderForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    golfClubList: state.golfClub.list,
    lang: state.auth.me.lang,
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGolfCoursesFromGolfClub: (id) => dispatch(getGolfCoursesFromGolfClub(id)),
    deleteGolfCourse: (token, id) => dispatch(deleteGolfCourse(token, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteGolfCourse))

DeleteGolfCourse.propTypes = {
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  golfClubList: PropTypes.array.isRequired,
  getGolfCoursesFromGolfClub: PropTypes.func.isRequired,
  deleteGolfCourse: PropTypes.func.isRequired,
}
