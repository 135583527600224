import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@sweetspot/scramble-ds'

type ActivateCourseDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  description: string
  onSave: () => void
}

const ActivateCourseDialog = ({
  open,
  setOpen,
  title,
  description,
  onSave,
}: ActivateCourseDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent variant="default">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost-dark" type="button" size="large" onClick={() => setOpen(false)}>
              {t('words.cancel')}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="primary" size="large" onClick={onSave}>
              {t('words.save')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ActivateCourseDialog
