export const GUEST = 'GUEST'
export const MEMBER = 'MEMBER'
export const PARTNER = 'PARTNER'
export const PASS = 'PASS'

export const MAN = 'man'
export const WOMAN = 'woman'
export const OTHER = 'other'

export const TABS = {
  GENERAL: 'GENERAL',
  RESERVATION_POLICY: 'RESERVATION_POLICY',
  PARTICIPANT_POLICY: 'PARTICIPANT_POLICY',
}

export const USER_TYPES = [
  {
    id: 1,
    name: GUEST,
    label: GUEST.toLowerCase(),
    value: GUEST,
  },
  {
    id: 2,
    name: MEMBER,
    label: MEMBER.toLowerCase(),
    value: MEMBER,
  },
  {
    id: 3,
    name: PASS,
    label: 'words.pass_one',
    value: PASS,
  },
  {
    id: 4,
    name: PARTNER,
    label: PARTNER.toLowerCase(),
    value: PARTNER,
  },
]

export const POLICY_CHECKERS = {
  USER_TYPE: 'user_type_checker',
  GENDER_TYPE: 'gender_checker',
  TOTAL_HCP: 'total_hcp_checker',
  PLAYER_HCP: 'hcp_per_player_checker',
  BOOKING_WINDOW: 'tee_time_category_booking_window_checker',
}

export const POLICY_TYPES = [
  {
    id: 1,
    name: 'userTypes',
    label: 'teeTimeCategory.userType',
    value: 'user_type',
  },
  {
    id: 2,
    name: 'genderTypes',
    label: 'settings.playerCard.gender',
    value: 'gender_type',
  },
  {
    id: 3,
    name: 'totalHcp',
    label: 'teeTimeCategory.totalHCP',
    value: 'hcp',
  },
  {
    id: 4,
    name: 'playerHcp',
    label: 'teeTimeCategory.playerHCP',
    value: 'hcp',
  },
  {
    id: 5,
    name: 'bookingWindow',
    label: 'teeTimeCategory.bookingWindow',
    value: 'booking_window',
  },
]

export const BOOKING_WINDOW_OPTIONS = [
  {
    id: 1,
    label: 'days',
    value: 'day',
  },
  {
    id: 2,
    label: 'hours',
    value: 'hour',
  },
]
