import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelect from '@sweetspot/sweetspot-js/common/components/MultiSelect'

import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import { MEMBER, PASS } from '../../../constants'

import style from '../style.module.scss'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryMemberships } from '@sweetspot/sweetspot-js/features/memberships/services/api-platform'

const Membership = ({ policy, onSetMemberships, isPass }) => {
  const clubId = useSelector((state) => state?.golfClub?.selectedId)

  const { data: memberships, isFetching: isFetchingMemberships } = useQuery(
    [
      CLUB_QUERIES.MEMBERSHIPS,
      { page: 1, limit: 199, 'club.id': clubId, type: isPass ? 'pass' : 'membership' },
    ],
    () =>
      queryMemberships({
        'club.id': clubId,
        page: 1,
        limit: 199,
        type: isPass ? 'pass' : 'membership',
      }),
    {
      enabled: !!clubId,
    }
  )

  const { t } = useTranslation()

  return (
    <div className={style.membershipContainer}>
      <MultiSelect
        loading={isFetchingMemberships}
        width="full"
        noSelectedLabel={
          isPass ? t('teeTimeCategory.allPasses') : t('teeTimeCategory.allMemberships')
        }
        buttonLabel={isPass ? t('words.pass_one') : t('words.membership_one')}
        options={
          memberships?.length && !isFetchingMemberships
            ? memberships.map((item) => ({
                label: item.name,
                value: item.uuid,
                id: item.id,
                uuid: item.uuid,
              }))
            : []
        }
        selectedOptions={
          policy.userTypes.find((item) => item.type === (isPass ? PASS : MEMBER))?.value
        }
        handleChangeSelected={onSetMemberships}
      />
    </div>
  )
}

Membership.propTypes = {
  policy: PropTypes.object,
  onSetMemberships: PropTypes.func,
  isPass: PropTypes.bool,
}

export default Membership
