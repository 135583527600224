import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  InputSelect,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipArrow,
  TooltipProvider,
} from '@sweetspot/scramble-ds'
import { bookingDurationOptions } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'

type BookingDurationProps = {
  bookingDuration?: number
  onBookingDurationChange: (duration: number) => void
}

const BookingDuration = ({ bookingDuration, onBookingDurationChange }: BookingDurationProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-end justify-between">
      <InputBase>
        <InputLabelContainer>{t('sentences.bookingDuration')}</InputLabelContainer>
        <InputSelect
          value={String(bookingDuration)}
          onValueChange={(value) => onBookingDurationChange(+value)}
        >
          <InputSelectTrigger asChild>
            <InputContainer>
              <InputSelectStatefulInput
                className={'text-content-base h-8 border-[1px] py-1'}
                value={
                  bookingDurationOptions.find((option) => +option.value === bookingDuration)?.label
                }
                placeholder={t('words.select')}
              />
              <InputSelectTrailingContainer className="right-2" />
            </InputContainer>
          </InputSelectTrigger>
          <InputSelectContent>
            <InputSelectContentView>
              {bookingDurationOptions?.map((option) => (
                <InputSelectItem key={option.value} value={option.value}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {option.label}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      </InputBase>
      <TooltipProvider key="datesTooltip">
        <Tooltip>
          <React.Fragment key="datesTooltip">
            <TooltipTrigger asChild>
              <div className="flex h-8 w-8 items-center justify-end p-1">
                <FontAwesomeIcon icon={faCircleInfo} className="text-text-subtle" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex w-[263px] flex-col gap-4 py-2">
                <p className="text-content-sm text-text-light font-bold">
                  {t('sentences.openingHours')}
                </p>
                <p className="text-content-sm text-text-light font-regular">
                  {t('sentences.periodOpeningHoursTooltipDescriptionOne')}
                </p>
                <p className="text-content-sm font-regular text-text-light">
                  <Trans
                    i18nKey={'sentences.periodOpeningHoursTooltipDescriptionTwo'}
                    components={{ strong: <strong /> }}
                  />
                </p>
              </div>
              <TooltipArrow />
            </TooltipContent>
          </React.Fragment>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}

export default BookingDuration
