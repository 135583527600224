import { Divider } from '@sweetspot/scramble-ds'
import { AvailabilityAndPricingProvider } from './AvailabilityAndPricingProvider'
import Header from './Header'
import FilterToolbar from './FilterToolbar'
import Body from './Body'
import SideBar from './SideBar'

const AvailabilityAndPricing = () => {
  return (
    <AvailabilityAndPricingProvider>
      <SideBar />
      <div className="flex h-full w-full flex-col">
        <Header />
        <div className={'py-4'}>
          <FilterToolbar />
        </div>
        <Divider className={'mx-6 mb-4 w-auto'} />
        <div className={'flex flex-1 items-center justify-center px-6'}>
          <Body />
        </div>
      </div>
    </AvailabilityAndPricingProvider>
  )
}

export default AvailabilityAndPricing
