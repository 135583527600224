import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Switch, Route } from 'react-router-dom'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import PlayerCardModal from '@sweetspot/club-portal-legacy/modals/PlayerCard'
import ExternalPlayerModal from '@sweetspot/club-portal-legacy/Partials/Dialogs/ExternalPlayerModal'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

import { getLang } from '@sweetspot/club-portal-legacy/languages'
import {
  getPlayers,
  exportPlayers,
  getMemberships,
  getMoreMemberships,
} from '@sweetspot/club-portal-legacy/store/actions'
import playersHeaders from './headers'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
const MEMBER = 'members'
const GUEST = 'guests'

/*
Future actions that will be supported
const futureActions = [
  {
    id: 1,
    label: 'players.export',
    subOptions: [
      { id: 2, label: 'players.exportSelected', action: 'exportSelected' },
      { id: 3, label: 'players.exportAll', action: 'exportAll' }
    ]
  },
  { id: 2, label: 'players.addToMembership', action: 'addToMembership' },
  {
    id: 3,
    label: 'players.sendPaymentLink',
    confirmDialog: 'players.confirmSendPaymentLink',
    action: 'sendPaymentLink'
  },
  {
    id: 4,
    label: 'players.markAsPaid',
    confirmDialog: [
      'players.confirmMarkAsPaidFirst',
      'replaceByNumber',
      'players.confirmMarkAsPaidSecond'
    ],
    action: 'markAsPaid'
  }
];
*/

class Players extends Component {
  playerTypes = [
    { value: MEMBER, label: getLang(this.props.lang)['players']['member'] },
    { value: GUEST, label: getLang(this.props.lang)['players']['guest'] },
  ]

  state = {
    activeFilters: [],
    currentPage: 1,
    filters: [],
    memberships: [],
    totalPages: 1,
    externalPlayerSearch: '',
    totalRows: 0,
    rowsPerPage: 0,
    isLoading: false,
    playerSearch: '',
    searching: false,
    players: [],
    externalPlayers: [],
    selectedOption: this.playerTypes[0],
    showExternalPlayerModal: false,
    sortItems: [],
    page: 1,
    hasCdhNumber: this.calculateHasCdhNumber(this.props.golfClubs, this.props.golfClubs.selectedId),
  }

  componentDidMount = () => {
    this.formatMemberships()
    this.fetchMemberships()
  }

  componentDidUpdate = (prevProps) => {
    const { location } = this.props
    const { state, pathname } = prevProps.location

    if (this.props.golfClubs.selectedId !== prevProps.golfClubs.selectedId) {
      this.setState({ activeFilters: [] }, () => this.fetchMemberships(this.state.page))
    }
    if (this.props.memberships !== prevProps.memberships) {
      this.setState({ activeFilters: [] }, () => this.formatMemberships())
    }
    if (location && location.state && location.state.shouldReload && !state) {
      this.fetchPlayers()
    }
    if (location?.pathname !== pathname) {
      this.setState({ showExternalPlayerModal: false })
    }
  }

  fetchMemberships = (page = 1) => {
    const {
      getMemberships,
      golfClubs: { selectedId },
    } = this.props
    this.setState({ isLoading: true })

    const query = `?page=${page}&club.id=${selectedId}`
    getMemberships(query)
  }

  formatMemberships = () => {
    const { memberships, federationMemberships } = this.props

    const clubMemberships = memberships
      .filter((membership) => membership.state !== 'draft')
      .map((membership) => {
        return { id: membership._id, name: membership.name, filter: membership._id }
      })

    const filters = [
      {
        id: 1,
        name: 'players.playerType',
        options: [
          { id: 1, name: '.players.member', filter: 'MEMBER' },
          { id: 2, name: '.players.guest', filter: 'GUEST' },
        ],
      },
      {
        id: 2,
        name: 'membershipPassText',
        options: _.orderBy(clubMemberships, 'name', 'asc'),
      },
      {
        id: 3,
        name: 'players.membershipPaid',
        options: [
          { id: 1, name: '.players.paid', filter: 'PAID' },
          { id: 2, name: '.players.notPaid', filter: 'UNPAID' },
        ],
      },
      {
        id: 4,
        name: this.state.hasCdhNumber ? 'players.cdhFilter' : 'players.gitFilter',
        options: [
          {
            id: 1,
            name: this.state.hasCdhNumber ? '.players.cdhMembers' : '.players.gitMembers',
            filter: 'GIT_MEMBERS',
          },
        ],
        divider: true,
      },
      {
        id: 5,
        name: this.state.hasCdhNumber ? 'players.cdhMembership' : 'players.gitMembership',
        options: _.orderBy(
          federationMemberships.map((item) => ({ id: item.id, name: item.name })),
          'name',
          'asc'
        ).map((item) => ({
          ...item,
          filter: {
            name: 'FEDERATION_MEMBERSHIP',
            value: item.id,
          },
        })),
      },
    ]

    this.setState(
      {
        memberships: _.orderBy(clubMemberships, 'name', 'asc'),
        filters,
      },
      () => this.fetchPlayers({ page: 1 })
    )
  }

  setCurrentPage = (page) => {
    this.setState({ currentPage: page })
    this.fetchPlayers({ page })
  }

  fetchPlayers = (searchQuery) => {
    const { getPlayers, token, lang } = this.props
    let queryString

    if (searchQuery) queryString = this.getQueryString(searchQuery)
    else queryString = this.getQueryString()

    this.setState({ isLoading: true })

    const playerList = []
    getPlayers(token, queryString)
      .then((response) => {
        const rowsPerPage = response['hydra:itemsPerPage']
        const totalRows = response['hydra:totalItems']
        const totalPages = totalRows <= rowsPerPage ? 1 : Math.ceil(totalRows / rowsPerPage)
        const players = response['hydra:member']

        if (queryString.includes('search') && players.length === 0)
          playerList.push({ _id: -1, name: getLang(lang)['noSearchResults'], hcp: 0 })
        else {
          players.forEach((player) => {
            const name = `${player.first_name} ${player.last_name}`
            const membership = this.sortMemberships(
              player.first_usable_membership_by_priority,
              player.shown_memberships
            )
            const federationMembership = player.show_player_membership_to_assign
              ? player.show_player_membership_to_assign.membership_name
              : '-'
            const homeClub = player.home_club ? player.home_club.name : null
            const facts = this.findPlayersFacts(player.facts)
            const lastPlayed =
              facts && facts.last_played
                ? DateHelpers.onlyDateString(new Date(facts.last_played))
                : '-'

            playerList.push({
              _id: player.id,
              name,
              email: player.email,
              phone: player.phone,
              golfId: player.golf_id,
              cdhId: player.cdh_id,
              age: player.age?.toString(),
              hcp: Utilities.parseHCP(player.hcp),
              lockState: player?.first_usable_membership_by_priority?.is_locked,
              membership,
              federationMembership,
              playRight: player.has_play_right ? getLang(lang)['yes'] : getLang(lang)['no'],
              homeClub,
              isRegistered: player.is_registered ? getLang(lang)['yes'] : getLang(lang)['no'],
              gender: player.gender ? getLang(lang)['settings']['playerCard'][player.gender] : '-',
              playValue: facts ? Math.round(facts.play_value / 100) : '-',
              numberOfRounds: facts ? facts.number_of_rounds : '-',
              paidValue: facts ? Math.round(facts.paid_value / 100) : '-',
              lastPlayed,
            })
          })
        }
        if (this.state.showExternalPlayerModal) {
          this.setState({
            isLoading: false,
            externalPlayers: playerList,
          })
        } else {
          this.setState({
            isLoading: false,
            players: playerList,
            totalPages,
            totalRows,
            rowsPerPage,
          })
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          players: [],
          totalPages: 1,
          totalRows: 0,
        })
      })
  }

  findPlayersFacts = (facts) => {
    if (!facts || !facts.length) return null

    const selectedGolfClub = this.props.golfClubs.selectedId
    let factsFromClub = facts.find((x) => {
      return x.club.id === selectedGolfClub
    })

    if (!factsFromClub) return null
    return factsFromClub
  }

  sortMemberships = (firstMembership, membershipList) => {
    let sortedMemberships = []
    if (!firstMembership) return null
    if (membershipList.length === 1) {
      if (firstMembership.status !== 'init') {
        sortedMemberships.push(firstMembership)
        return sortedMemberships
      }
      return []
    }

    membershipList = membershipList.filter(
      (membership) => membership.id !== firstMembership.id && membership.status !== 'init'
    )

    sortedMemberships = membershipList.sort((a, b) => {
      let valueA = a.status
      let valueB = b.status

      if (valueA === 'active' && valueB !== 'active') {
        return -1
      }
      if (valueA !== 'active' && valueB === 'active') {
        return 1
      }

      return 0
    })
    sortedMemberships.sort((a, b) => {
      let valueA = a.status
      let valueB = b.status

      if (valueA === 'active') return 0

      if (valueA === 'upcoming' && valueB !== 'upcoming') {
        return -1
      }
      if (valueA !== 'upcoming' && valueB === 'upcoming') {
        return 1
      }

      return 0
    })

    return [firstMembership, ...sortedMemberships]
  }

  exportPlayers = () => {
    const {
      token,
      golfClubs: { selectedId },
      exportPlayers,
    } = this.props

    const filterQuery = this.getFilterQuery()

    exportPlayers(token, selectedId, filterQuery)
      .then(() => console.log('Players were exported'))
      .catch((error) => console.log(error))
  }

  getFilterQuery = () => {
    const { activeFilters, playerSearch } = this.state
    const clubId = this.props.golfClubs.selectedId

    let filters = {
      relatedGolfClub: clubId,
      golfClub: clubId,
    }

    if (playerSearch) filters.search = playerSearch
    const memberships = {}
    activeFilters.forEach((filter) => {
      const memLength = Object.keys(memberships).length
      switch (filter) {
        case 'MEMBER':
          filters = { ...filters, type: { ...filters.type, members: clubId } }
          break
        case 'GUEST':
          filters = { ...filters, type: { ...filters.type, guests: clubId } }
          break
        case 'PAID':
          filters = { ...filters, membershipPaid: { ...filters.membershipPaid, paid: clubId } }
          break
        case 'UNPAID':
          filters = { ...filters, membershipPaid: { ...filters.membershipPaid, not_paid: clubId } }
          break
        case 'GIT_MEMBERS':
          filters = { ...filters, gitOnlyMembership: clubId }
          break
        default:
          memberships[memLength] = filter
          break
      }
    })
    filters.membership = { ...memberships, golf_club_id: clubId }

    return filters
  }

  getQueryString = (query) => {
    const {
      activeFilters,
      externalPlayerSearch,
      playerSearch,
      currentPage,
      searching,
      sortItems,
      showExternalPlayerModal,
    } = this.state
    const clubId = this.props.golfClubs.selectedId

    if (query === 'search' && !playerSearch) this.setState({ searching: false })
    let queryString = `?golfClub=${clubId}`
    let page = query?.page ? query.page : currentPage

    if (activeFilters && !externalPlayerSearch && !showExternalPlayerModal) {
      activeFilters.forEach((filter) => {
        switch (filter) {
          case 'MEMBER':
            queryString += `&type[members]=${clubId}`
            break
          case 'GUEST':
            queryString += `&type[guests]=${clubId}`
            break
          case 'PAID':
            queryString += `&membershipPaid[paid]=${clubId}`
            break
          case 'UNPAID':
            queryString += `&membershipPaid[not_paid]=${clubId}`
            break
          case 'GIT_MEMBERS':
            queryString += `&gitOnlyMembership=${clubId}`
            break
          default:
            if (filter.name === 'FEDERATION_MEMBERSHIP') {
              queryString += `&federationMembership[]=${filter.value}`
            } else {
              queryString += `&membership[golf_club_id]=${clubId}&membership[]=${filter}`
            }
            break
        }
      })
    }

    sortItems.forEach((sort) => {
      if (sort.name === 'homeClub') {
        queryString += `&order[homeClub.name]=${sort.type}`
      } else if (sort.type) {
        queryString += `&order[${sort.name === 'name' ? 'fullName' : sort.name}]=${sort.type}`
      }
    })
    if (query === 'exportAllPlayers') {
      queryString += '&export'
    }

    if ((query === 'search' || searching) && playerSearch && !showExternalPlayerModal) {
      queryString += `&search=${playerSearch}&page=${!searching ? 1 : page}`
      this.setState({ searching: true })
    } else if (showExternalPlayerModal) {
      queryString = `?externalPlayer=${clubId}&search=${externalPlayerSearch}&page=${page}`
    } else queryString += `&relatedGolfClub=${clubId}&page=${page}`

    return queryString
  }

  handleSearchForPlayer = (playerSearch) => {
    this.setState({ playerSearch })
  }

  handleSearchForExternalPlayer = (externalPlayerSearch) => {
    this.setState({ externalPlayerSearch })
  }

  handleRowClick = (row) => {
    this.setState({ cardInfo: row })
    this.props.history.push(`players/${row._id}`)
  }

  handleFilters = (filter) => {
    if (!filter) {
      this.setState({ activeFilters: [], playerSearch: '' }, () => this.fetchPlayers({ page: 1 }))
      return null
    }
    let activeFilters = [...this.state.activeFilters]

    if (!activeFilters.includes(filter)) {
      activeFilters.push(filter)
    } else activeFilters.splice(activeFilters.indexOf(filter), 1)
    this.setState({ activeFilters }, () => this.fetchPlayers({ page: 1 }))
  }

  handleActionConfirm = (action) => {
    switch (action.action) {
      case 'addExternalPlayer':
        this.toggleExternalPlayerModal()
        break
      case 'exportAllPlayers':
        this.exportPlayers()
        break
      case 'exportSelected':
        console.log('Export selected players')
        break
      case 'markAsPaid':
        console.log('Mark selected players memberships as paid')
        break
      default:
        break
    }
  }

  handleSort = (sortItems) => {
    const item = sortItems?.length ? sortItems.slice(sortItems.length - 1) : []
    this.setState({ sortItems: item }, () => this.fetchPlayers(this.state.currentPage))
  }

  handleShowMore = () => {
    this.setState({
      page: this.state.page + 1,
    })

    const query = `?page=${this.state.page + 1}&club.id=${this.props.golfClubs.selectedId}`

    this.props.getMoreMemberships(query)
  }

  handleColumnUpdate = (tableColumns) => {
    const { sortItems } = this.state

    const unselectedColumns = tableColumns
      .filter((column) => !column.selected)
      .map((column) => column.id)
    this.setState(
      {
        sortItems: sortItems.filter((item) => !unselectedColumns.includes(item.name)),
      },
      () => this.fetchPlayers(this.state.currentPage)
    )
  }

  toggleExternalPlayerModal = () => {
    const modalOpen = this.state.showExternalPlayerModal
    const query = this.state.playerSearch ? 'search' : null
    this.setState(
      {
        showExternalPlayerModal: !modalOpen,
        externalPlayerSearch: '',
        externalPlayers: [],
      },
      () => modalOpen && this.fetchPlayers(query)
    )
  }

  calculateHasCdhNumber(golfClubs, selectedId) {
    const currentGolfClub = golfClubs.list.find((club) => club.id === selectedId)
    return !!currentGolfClub?.cdh_id
  }

  getPlayerHeaders() {
    const playersHeader = {}

    for (let key in playersHeaders) {
      if (playersHeaders[key].selected) {
        if (this.state.hasCdhNumber && key === 'golfId') {
          playersHeader.cdhId = {
            name: '.cdhNumber',
            type: 'label',
            valueType: 'string',
            width: '150px',
            selected: true,
          }
        } else if (this.state.hasCdhNumber && key === 'federationMembership') {
          playersHeader.federationMembership = {
            name: '.cdhMembership',
            type: 'label',
            valueType: 'string',
            width: '150px',
            selected: true,
          }
        } else {
          playersHeader[key] = { ...playersHeaders[key] }
        }
      }
    }

    return playersHeader
  }

  renderExternalPlayerSearch = () => {
    const { isLoading, showExternalPlayerModal, externalPlayers } = this.state

    if (!showExternalPlayerModal) return

    return (
      <ExternalPlayerModal
        lang={this.props.lang}
        onClose={this.toggleExternalPlayerModal}
        onRowClick={this.handleRowClick}
        onSearchChange={this.handleSearchForExternalPlayer}
        onSearch={this.fetchPlayers}
        isLoading={isLoading}
        players={externalPlayers}
        hasCdhNumber={this.state.hasCdhNumber}
      />
    )
  }

  render() {
    const {
      activeFilters,
      isLoading,
      filters,
      players,
      totalPages,
      totalRows,
      rowsPerPage,
      showExternalPlayerModal,
      selectedOption,
      sortItems,
      page,
    } = this.state

    const { role, totalItems, itemsPerPage } = this.props

    return (
      <Container>
        <div>
          <Grid
            actions={[
              {
                id: 1,
                label: 'players.export',
                alertDialog: 'players.exportSuccess',
                action: 'exportAllPlayers',
                accessKey: ACCESS_KEYS.PAGES.PLAYERS.EXPORT,
              },
              { id: 2, label: 'players.addExternalPlayer', action: 'addExternalPlayer' },
            ].filter((action) =>
              action?.accessKey ? hasAccess(action.accessKey, role?.value) : true
            )}
            actionsEnabled
            dropDownSelectValues={this.playerTypes}
            dropDownOnSelect={this.handleChangePlayerType}
            dropDownInitalValue={selectedOption}
            activeFilters={activeFilters}
            filters={filters}
            filtersEnabled
            filtersTitle={'players.playerFilter'}
            handleFilters={this.handleFilters}
            hasCheckbox
            headers={this.getPlayerHeaders()}
            isLoading={isLoading && !showExternalPlayerModal}
            values={players}
            pagination
            searchEnabled
            searchPlaceholder={'.players.searchPlayer'}
            pageTitle={'playersText'}
            onSearchChange={this.handleSearchForPlayer}
            totalPages={totalPages}
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            displayRangeOfRows
            onActionConfirm={this.handleActionConfirm}
            onSearch={(search) => this.fetchPlayers(search)}
            onRowClick={this.handleRowClick}
            setCurrentPage={(page) => this.setCurrentPage(page)}
            onHeaderClick={this.handleSort}
            sortItems={sortItems}
            onColumnUpdate={this.handleColumnUpdate}
            onShowMore={this.handleShowMore}
            shouldShowMore={page * itemsPerPage < totalItems}
            useSingleColumnSort
          />
        </div>

        <Switch>
          <Route exact path="/players/:id" component={PlayerCardModal} />
        </Switch>
        {this.renderExternalPlayerSearch()}
      </Container>
    )
  }
}

Players.propTypes = {
  history: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfClubs: PropTypes.object.isRequired,
  getPlayers: PropTypes.func.isRequired,
  exportPlayers: PropTypes.func.isRequired,
  memberships: PropTypes.array.isRequired,
  federationMemberships: PropTypes.array.isRequired,
  getMemberships: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfClubs: state.golfClub,
    memberships: state.membership.list,
    totalItems: state.membership.totalItems,
    itemsPerPage: state.membership.itemsPerPage,
    federationMemberships: state.membership.federationMemberships,
    mem: state.membership,
    role: getFirstMatchingRole(state.auth.roles),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPlayers: (token, queryString) => dispatch(getPlayers(token, queryString)),
    exportPlayers: (token, clubId, filterQuery) =>
      dispatch(exportPlayers(token, clubId, filterQuery)),
    getMemberships: (queryString) => dispatch(getMemberships(queryString)),
    getMoreMemberships: (queryString) => dispatch(getMoreMemberships(queryString)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Players)
