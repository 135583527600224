import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { useEffect, useState } from 'react'
import _isEqual from 'lodash/isEqual'
import { GolfCourse, GolfCourseTypes } from '@sweetspot/shared/types'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'

const useAcademies = () => {
  const currentClubId = useSelector((state: RootState) => state.golfClub.selectedId)
  const [selectedAcademy, setSelectedAcademy] = useState<GolfCourse | undefined>()

  const {
    data,
    isLoading: areAcademiesLoading,
    refetch,
  } = useQuery(
    [CLUB_QUERIES.COURSES, GolfCourseTypes.PRO, currentClubId],
    () => queryCourses({ 'club.id': currentClubId, type: GolfCourseTypes.PRO }),
    {
      enabled: !!currentClubId,
    }
  )

  // if academies are updated, update the selected academy if needed
  useEffect(() => {
    if (selectedAcademy) {
      const updatedAcademy = data?.find((academy: GolfCourse) => academy.id === selectedAcademy.id)
      if (updatedAcademy && !_isEqual(selectedAcademy, updatedAcademy)) {
        setSelectedAcademy(updatedAcademy)
      }
    }
  }, [data, selectedAcademy])

  return {
    academies: (data as GolfCourse[]) || [],
    areAcademiesLoading,
    selectedAcademy,
    setSelectedAcademy,
    refetchAcademies: refetch,
  }
}

export default useAcademies
