import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import {
  CreateBayBulkPayload,
  CreateBayPayload,
  UpdateBayBulkPayload,
  UpdateBayPayload,
} from './types'

export function createBay(payload: CreateBayPayload): Promise<void> {
  const { rangeId, bay } = payload

  const URL = `${getApiPlatformBaseUrl()}/v2/ranges/${rangeId}/bays`
  return request(URL, {
    method: 'POST',
    body: {
      floor: bay.floor,
      amenities: bay.amenities,
      stance: bay.stance,
      allocation: bay.allocation,
      ball_tracking_technology: bay.ball_tracking_technology,
    },
  })
}

export function updateBay(payload: UpdateBayPayload): Promise<void> {
  const { rangeId, bayId, bay } = payload

  const URL = `${getApiPlatformBaseUrl()}/v2/ranges/${rangeId}/bays/${bayId}`
  return request(URL, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: bay,
  })
}

export const createBayBulk = async (payload: CreateBayBulkPayload): Promise<void> => {
  const { rangeId, bay, numberOfBays, trmsBayNumbers } = payload

  const URL = `${getApiPlatformBaseUrl()}/v2/ranges/${rangeId}/bays/bulk`
  return request(URL, {
    method: 'POST',
    body: {
      trmsBayNumbers,
      numberOfBays,
      floor: bay.floor,
      amenities: bay.amenities,
      stance: bay.stance,
      allocation: bay.allocation,
      ball_tracking_technology: bay.ball_tracking_technology,
    },
  })
}

export const updateBayBulk = async (payload: UpdateBayBulkPayload): Promise<void> => {
  const { rangeId, bay, bayNumbers, trmsBayNumbers } = payload

  const URL = `${getApiPlatformBaseUrl()}/v2/ranges/${rangeId}/bays/bulk`
  return request(URL, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: {
      bayNumbers,
      trmsBayNumbers,
      ...bay,
    },
  })
}
