import { FeatureFlagValue } from '@sweetspot/shared/data-access/api-platform'

export enum FlagNames {
  DemoSweetspotUser = 'demo-sweetspot-user',
  RangeTeeTimePeriods = 'range-tee-time-periods',
  RangeBallDispensers = 'range-ball-dispensers',
  WebTeeSheetBookings = 'wb-tee-sheet-bookings',
  PaymentLinks = 'payment-links',
  GitClubSelect = 'git-club-select',
  ExperimentalClientKey = 'adyen-experimental-client-key',
  WebBookingApplePay = 'web-booking-apple-pay',
  IncludeBallsInBooking = 'include-balls-in-booking',
  Subscriptions = 'subscriptions',
  SimulatorMenu = 'simulator-menu',
}

// Add other types of flags if needed
export type Flag<T extends FlagNames> = Record<T, FeatureFlagValue>

export type FeatureFlags = {
  [K in FlagNames]: FeatureFlagValue
}
