import React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Tag, TagLabel } from '@sweetspot/scramble-ds'

import styles from './styles.module.scss'
import ItemBox from '../ItemBox'

type StatusHeaderProps = {
  isConfirmed: boolean
  isPaid: boolean
  isAwaitingPayment: boolean
  isDispenseComplete?: boolean
}

const StatusHeader = ({
  isConfirmed,
  isPaid,
  isAwaitingPayment,
  isDispenseComplete,
}: StatusHeaderProps) => {
  const { t } = useTranslation()

  if (!isConfirmed) {
    return (
      <ItemBox className={cx(styles.container)}>
        <Tag variant="inverted" size="medium" className="gap-0">
          <i className="fa-regular fa-timer" />
          <TagLabel>{t('words.unconfirmed')}</TagLabel>
        </Tag>
      </ItemBox>
    )
  }

  return (
    <ItemBox className={cx(styles.container)}>
      {isConfirmed && (
        <Tag variant="success-filled" size="medium" className="gap-0">
          <i className="fa-regular fa-circle-check" />
          <TagLabel>{t('words.confirmed')}</TagLabel>
        </Tag>
      )}

      {isPaid && (
        <Tag variant="success-filled" size="medium" className="gap-0">
          <i className="fa-regular fa-circle-check" />
          <TagLabel>{t('words.paid')}</TagLabel>
        </Tag>
      )}

      {isAwaitingPayment && (
        <Tag variant="inverted" size="medium" className="gap-0">
          <i className="fa-regular fa-timer" />
          <TagLabel>{t('words.unpaid')}</TagLabel>
        </Tag>
      )}

      {isDispenseComplete && (
        <Tag variant="success-filled" size="medium" className="gap-0">
          <i className="fa-regular fa-circle-check" />
          <TagLabel>{t('sentences.dispenseComplete')}</TagLabel>
        </Tag>
      )}
    </ItemBox>
  )
}

export default StatusHeader
