import { SideBarMode } from '../types'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import AllPeriods from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/AllPeriods'
import CreatePeriod from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/CreatePeriod'
import CreateAdjustment from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/CreateAdjustment'

const SideBar = () => {
  const { sideBarMode } = useAvailabilityAndPricingContext()

  if (sideBarMode === SideBarMode.CREATE_PERIOD) {
    return <CreatePeriod />
  }

  if (sideBarMode === SideBarMode.ALL_PERIODS) {
    return <AllPeriods />
  }

  if (sideBarMode === SideBarMode.CREATE_ADJUSTMENT) {
    return <CreateAdjustment />
  }

  return null
}

export default SideBar
