export const HEADERS = {
  name: {
    name: '.settings.name',
    type: 'label',
    valueType: 'string',
    width: '120px',
    selected: true,
    disabled: true,
    isSortable: true,
  },
  description: {
    name: '.words.description',
    type: 'label',
    valueType: 'string',
    width: '250px',
    selected: true,
    disabled: true,
  },
  floor: {
    name: '.settings.bays.floor',
    type: 'label',
    valueType: 'number',
    width: '120px',
    selected: true,
  },
}
