import { useState } from 'react'
import { useQuery } from 'react-query'

import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { Period } from '@sweetspot/shared/types'
import { queryPeriods } from '@sweetspot/sweetspot-js/features/period/services'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'

type UsePeriodsProps = {
  currentCourse: GolfCourseItem | null
}

const usePeriods = ({ currentCourse }: UsePeriodsProps) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null)
  const {
    data,
    isLoading: arePeriodsLoading,
    isFetching: arePeriodsFetching,
    refetch,
  } = useQuery(
    [
      CLUB_QUERIES.PERIODS,
      {
        courseId: currentCourse?.id,
      },
    ],
    () =>
      queryPeriods({
        courseId: currentCourse?.id as number,
      }),
    {
      enabled: !!currentCourse?.id,
    }
  )

  return {
    periods: data?.data || [],
    arePeriodsLoading,
    arePeriodsFetching,
    selectedPeriod,
    setSelectedPeriod,
    refetch,
  }
}

export default usePeriods
