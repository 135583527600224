export const CLUB_QUERIES = {
  PROMOTIONS: 'PROMOTIONS',
  MEMBERSHIPS: 'MEMBERSHIPS',
  MEMBERSHIP_CARDS: 'MEMBERSHIP_CARDS',
  MEMBERSHIP_CARDS_PAYMENT: 'MEMBERSHIP_CARDS_PAYMENT',
  CLUBS: 'CLUBS',
  COURSES: 'COURSES',
  COURSE: 'COURSE',
  RANGES: 'RANGES',
  BAYS: 'BAYS',
  PERIODS: 'PERIODS',
  BUCKET_CONFIGURATION: 'BUCKET_CONFIGURATION',
  RANGE_SCHEDULE: 'RANGE_SCHEDULE',
  DEMAND_MODEL: 'DEMAND_MODEL',
  PRICE_MODEL_TABS: 'PRICE_MODEL_TABS',
  BALL_DISPENSERS: 'BALL_DISPENSERS',
  INCOMPATIBLE_PROMOTIONS: 'INCOMPATIBLE_PROMOTIONS',
  TEE_TIMES: 'TEE_TIMES',
  TEE_TIME: 'TEE_TIME',
  TEE_TIME_PRICE: 'TEE_TIME_PRICE',
  PARTNERSHIPS: 'PARTNERSHIPS',
  PRODUCTS: 'PRODUCTS',
  PRODUCT_AVAILABILITY: 'PRODUCT_AVAILABILITY',
  BOOKINGS: 'BOOKINGS',
  SINGLE_BOOKING: 'SINGLE_BOOKING',
  BOOKING_NOTES: 'BOOKING_NOTES',
  ORDER_BOOKINGS: 'ORDER_BOOKINGS',
  ORDER_BOOKING: 'ORDER_BOOKING',
  PARTNER_TYPES: 'PARTNER_TYPES',
  SPACES: 'SPACES',
  PLAYER_VOUCHERS: 'PLAYER_VOUCHERS',
  PLAYER: 'PLAYER',
  CLUB_USERS: 'CLUB_USERS',
  CLUB_USER_INVITE: 'CLUB_USER_INVITE',
  CLUB_USER_INVITES: 'CLUB_USER_INVITES',
  COOPERATIONS: 'COOPERATIONS',
  APPLICATIONS: 'APPLICATIONS',
  CATEGORIES: 'CATEGORIES',
  CART_AVAILABILITY: 'CART_AVAILABILITY',
  RESERVED_CARTS: 'RESERVED_CARTS',
  BOOKERS: 'BOOKERS',
  PRODUCT_VARIANTS: 'PRODUCT_VARIANTS',
  PRODUCT_VARIANT: 'PRODUCT_VARIANT',
  VOUCHERS: 'VOUCHERS',
  RENTAL_ACTIVITY: 'RENTAL_ACTIVITY',
  COUPONS: 'COUPONS',
  MEMBERSHIP_PAYMENTS: 'MEMBERSHIP_PAYMENTS',
  PAYMENT: 'PAYMENT',
  TIMEZONES: 'TIMEZONES',
  MEMBERSHIP_VIOLATIONS: 'MEMBERSHIP_VIOLATIONS',
  MEMBERSHIP_INSTALLMENT_CALCULATION: 'MEMBERSHIP_INSTALLMENT_CALCULATION',
  UPDATE_CONTRACT_PAYMENT_METHOD: 'UPDATE_CONTRACT_PAYMENT_METHOD',
  WEB_BOOKING_SETTINGS: 'WEB_BOOKING_SETTINGS',
  COURSE_GUIDE: 'COURSE_GUIDE',
  PRICE_BREAKDOWN: 'PRICE_BREAKDOWN',
  NEXT_AVAILABLE_TEE_TIME: 'NEXT_AVAILABLE_TEE_TIME',
}

export const DATA_QUERIES = {
  ...CLUB_QUERIES,
  CALCULATE_PRICE: 'CALCULATE_PRICE',
}

export const QUERY_KEYS = {
  ...CLUB_QUERIES,
}

export const WEB_BOOKING_QUERIES = {
  ...CLUB_QUERIES,
}

export const ONTAG_QUERIES = {
  SCORECARD: 'SCORECARD',
  CREATE_SCORECARD: 'CREATE_SCORECARD',
  CLUBINFO: 'CLUBINFO',
  ACTUALIZE: 'ACTUALIZE',
}
