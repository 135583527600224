import React, { useMemo, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import moment from 'moment'
import InputSelectDateSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectDateSimple'
import InputSelectOptionSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectOptionSimple'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import useQueryParams from '@sweetspot/sweetspot-js/common/hooks/useQueryParams'
import { useHistory } from 'react-router'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  addBookingPeriodOverride,
  getBookingPeriodsByGolfCourseId,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { useToasts } from 'react-toast-notifications'
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import { getTranslatedTeeTimeCategories } from '@sweetspot/sweetspot-js/features/teeTimeCategories/js/getTranslatedTeeTimeCategories'

const EditCategoriesHeader = ({
  className,
  selectedFrom,
  selectedTo,
  selectedSpaces,
  currentDate,
  selectedTeeTimes,
  onCancel,
  isVisible,
  currentVenue,
  setDate,
  resetSheet,
  updateSelected,
}) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const primeTimeValues = useMemo(() => {
    return [
      { id: -1, label: t('sentences.noChange'), value: null },
      { id: 0, label: t('yes'), value: true },
      { id: 1, label: t('no'), value: false },
    ]
  }, [t])

  const defaultCat = {
    label: t('sentences.noChange'),
    icon: null,
    iconColor: 'transparent',
    id: null,
  }

  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(defaultCat)
  const [primeTime, setPrimeTime] = useState(primeTimeValues[0])
  const [isSaving, setIsSaving] = useState(false)
  let query = useQueryParams()
  let history = useHistory()

  const { isFetching: isFetchingCategories } = useQuery(
    [CLUB_QUERIES.CATEGORIES, { clubId: currentVenue?.club?.id }],
    () =>
      getTranslatedTeeTimeCategories({
        clubId: currentVenue?.club?.id,
        allPages: true,
      }),
    {
      enabled: !!currentVenue && !!isVisible,
      onSuccess: (data) => {
        let cats = []
        cats = data?.map((cat) => {
          return { label: cat.name, icon: 'circle', iconColor: cat.color, ...cat }
        })

        cats = [{ ...defaultCat }, ...cats]
        setCategories(cats)
      },
      onError: (error) => {
        console.log(error.toString())
      },
    }
  )

  const handleApply = async () => {
    setIsSaving(true)

    const [periodsRes, periodsErr] = await to(getBookingPeriodsByGolfCourseId(currentVenue?.id))

    if (!periodsRes || periodsErr) {
      addToast(t('sentences.failedToSaveChanges'), { appearance: 'error' })
      return
    }

    const date = new Date(selectedFrom * 1000)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    const ISODate = DateHelpers.toISODateTime(date)

    const activePeriod = periodsRes.find(
      (period) => period.start_date <= ISODate && period.end_date >= ISODate
    )

    if (!activePeriod) {
      addToast(t('sentences.failedToSaveChanges'), { appearance: 'error' })
      return
    }

    let overrideErrors = []
    let overrideResults = []

    for (const space of selectedSpaces) {
      // Exclude next tee time startTime (see FROM/TO fixes)
      const _selectedTo = selectedTo - activePeriod.interval * 60
      const [overrideRes, overrideErr] = await to(
        addBookingPeriodOverride(activePeriod.uuid, {
          repeat_on: 'every_day',
          start_date: DateHelpers.onlyDateString(date),
          end_date: DateHelpers.onlyDateString(date),
          start_time_from: moment(selectedFrom * 1000)
            .tz(currentVenue?.timezone)
            .format('HH:mm:ss'),
          start_time_to: moment(_selectedTo * 1000)
            .tz(currentVenue?.timezone)
            .format('HH:mm:ss'),
          category_id: selectedCategory?.id,
          slots: null,
          is_golf_id_required: null,
          is_prime_time: primeTime?.value,
          space: {
            uuid: space.uuid,
          },
        })
      )
      if (!overrideRes || overrideErr) {
        overrideErrors.push(overrideErr)
      }
      if (overrideRes) {
        overrideResults.push(overrideRes)
      }
    }

    // Check for errors
    if (overrideResults.length > 0) {
      addToast(t('sentences.changeApplied'), { appearance: 'success' })
      updateSelected(overrideResults)
      reset()
    }
    if (overrideErrors.length > 0) {
      addToast(t('sentences.failedToSaveChanges'), { appearance: 'error' })
    }
    setIsSaving(false)
  }

  const canApply = useMemo(() => {
    return (
      (selectedCategory.id || primeTime.value !== null) &&
      selectedTeeTimes?.length > 0 &&
      !isSaving &&
      !isFetchingCategories
    )
  }, [selectedCategory, selectedTeeTimes, isSaving, isFetchingCategories, primeTime])

  const filteredCategories = useMemo(() => {
    return categories.filter((cat) => cat.id !== selectedCategory.id)
  }, [categories, selectedCategory])

  const filteredPrimeTimes = useMemo(() => {
    return primeTimeValues.filter((pt) => pt.id !== primeTime.id)
  }, [primeTimeValues, primeTime])

  const handleSetDate = (date) => {
    reset()
    query.set('date', moment(date).tz(currentVenue.timezone).unix())
    history.replace(`${history.location.pathname}?${query.toString()}`)
    setDate(moment(date).tz(currentVenue.timezone))
  }

  const reset = () => {
    setSelectedCategory(defaultCat)
    setPrimeTime(primeTimeValues[0])
    resetSheet()
  }

  const handleCategorySelect = (value) => {
    setSelectedCategory(value)
  }

  const onPrimeTimeChange = (value) => {
    setPrimeTime(value)
  }

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.visible]: isVisible,
      })}
    >
      <div
        className={cx(
          styles.container,

          className
        )}
      >
        <div className={cx(styles.heading)}>
          {t('words.edit')} {t('words.teeTime').toLowerCase()} {t('words.category').toLowerCase()}
        </div>
        <div className={cx(styles.infoContainer)}>
          <div className={cx(styles.labelRow)}>{t('words.date')}</div>
          <div className={cx(styles.controlRow)}>
            <InputSelectDateSimple
              orientation={'bottomRight'}
              onSetDate={(date) => handleSetDate(date)}
              placeholder={moment(currentDate).format('YYYY-MM-DD')}
              currentDate={currentDate}
              value={moment(currentDate).format('YYYY-MM-DD')}
            />
          </div>
          <div className={cx(styles.labelRow)}>{t('words.category')}</div>
          <div className={cx(styles.controlRow)}>
            <InputSelectOptionSimple
              options={filteredCategories}
              value={selectedCategory?.label}
              onOptionSelect={(value) => handleCategorySelect(value)}
              icon={{ name: selectedCategory?.icon, color: selectedCategory?.iconColor }}
              useToolTip={true}
            />
          </div>
          <div className={cx(styles.labelRow)}>{t('words.primeTime')}</div>
          <div className={cx(styles.controlRow)}>
            <InputSelectOptionSimple
              options={filteredPrimeTimes}
              value={primeTime?.label}
              onOptionSelect={(value) => onPrimeTimeChange(value)}
            />
          </div>
          <div className={cx(styles.controlRow)}>
            <p className={cx(styles.blue, styles.text)}>
              <span className="material-icons md-16 md-dark">schedule</span>
              {
                <React.Fragment>
                  {selectedFrom && selectedTo && currentVenue
                    ? `${moment
                        .unix(selectedFrom)
                        .tz(currentVenue.timezone)
                        .format('HH:mm')} - ${moment
                        .unix(selectedTo)
                        .tz(currentVenue.timezone)
                        .format('HH:mm')}`
                    : t('sentences.selectTeeTime')}
                </React.Fragment>
              }
            </p>
          </div>
        </div>
        <div className={cx(styles.controlsContainer)}>
          <button
            className={cx('system-button md-32 primary-outline', styles.cancelButton)}
            onClick={onCancel}
            disabled={isSaving}
          >
            {t('words.close')}
          </button>
          <button
            className={cx('system-button md-32 primary')}
            onClick={handleApply}
            disabled={!canApply}
          >
            {t('words.apply')}
          </button>
        </div>
      </div>
    </div>
  )
}

EditCategoriesHeader.propTypes = {
  className: PropTypes.string,
  selectedFrom: PropTypes.number,
  selectedTo: PropTypes.number,
  selectedSpaces: PropTypes.array,
  selectedTeeTimes: PropTypes.array,
  onCancel: PropTypes.func,
  onBook: PropTypes.func,
  currentDate: PropTypes.object,
  isVisible: PropTypes.bool,
  setDate: PropTypes.func,
  resetSheet: PropTypes.func,
}

export default EditCategoriesHeader
