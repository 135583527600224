import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { BookingDispense, BookingDispensePayload } from './types'

export const queryBookingDispenses = ({
  rangeId,
  orderId,
}: BookingDispensePayload): Promise<{ [key: string]: BookingDispense }> => {
  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/bookings/${orderId}/dispenses`
  return request(URL) as Promise<{ [key: string]: BookingDispense }>
}

export const createBookingDispense = ({
  rangeId,
  orderId,
}: BookingDispensePayload): Promise<{ [key: string]: BookingDispense }> => {
  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/bookings/dispenses`
  return request(URL, {
    method: 'POST',
    body: { bookingReference: orderId },
  })
}
