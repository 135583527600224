import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import { connect } from 'react-redux'
import _ from 'lodash'
import cx from 'classnames'

import TeetimeStatus from '@sweetspot/club-portal-legacy/components/TeetimeStatus'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import DOMHelpers from '@sweetspot/club-portal-legacy/helpers/DOMHelpers'
import { getLang } from '@sweetspot/club-portal-legacy/languages'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

import { ReactComponent as CloseIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'
import { ReactComponent as CaretIcon } from '@sweetspot/club-portal-legacy/resources/images/caret.svg'
import { ReactComponent as BlueCaretIcon } from '@sweetspot/club-portal-legacy/resources/images/blue_caret.svg'
import FeedbackContainer from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/FeedbackContainer'

/**
 * This component creates an input with a dropdown list of selectable items. The options are
 * searchable by typing in the input
 */
class DropdownSelect extends Component {
  state = {
    isOpen: false,
    searchString: '',
    selectedId: Utilities.isSet(this.props.initialId) ? this.props.initialId : null,
    focusedIndex: 0,
  }

  resultsRefs = []

  componentDidMount() {
    this.initialize()
    document.addEventListener('click', this.handleClick)
    document.addEventListener('keydown', this.handleKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  componentDidUpdate(prevProps, prevState) {
    // empty input value on open, set it to selected option on close
    if (this.state.isOpen !== prevState.isOpen) {
      if (this.state.isOpen) {
        if (!this.props.readOnly) this._input.value = ''
      } else {
        this.setSelectedId(this.state.selectedId)
        this.setState({ focusedIndex: 0 })
      }
    }
    if (prevProps.initialId !== this.props.initialId) {
      this.setSelectedId(this.props.initialId)
    }
    if (!_.isEqual(prevProps.values, this.props.values)) {
      this.setSelectedId(this.props.selectedId)
    }
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
    }
  }

  initialize = () => {
    this._input.onclick = this.toggleOpen
    this._arrow.onclick = this.toggleOpen
    this._input.oninput = this.handleChange
    this._input.setAttribute('spellcheck', false)

    if (this.state.selectedId !== null) {
      let selectedValue = this.props.values.find((value) => value.id === this.state.selectedId)
      if (selectedValue && !selectedValue.color) {
        let name = selectedValue.name
        if (this.props.singleLetter) name = name.charAt(0)
        this._input.value = name
      }
    }
  }

  handleKeyPress = (e) => {
    const { focusedIndex, isOpen } = this.state
    let { hideSelf, selectedId, values } = this.props
    if (hideSelf) {
      values = values.filter((value) => value.id !== selectedId)
    }

    if (!isOpen) return
    if (e?.keyCode === 40 || e?.which === 40 || e?.key === 'ArrowDown' || e?.code === 'ArrowDown') {
      const newIndex = focusedIndex > values.length - 2 ? 0 : focusedIndex + 1
      this.setState({ focusedIndex: newIndex })
      if (this.resultsRefs?.[newIndex]?.scrollIntoView) {
        this.resultsRefs[newIndex].scrollIntoView({ behavior: 'smooth' })
      }
    }
    if (e?.keyCode === 38 || e?.which === 38 || e?.key === 'ArrowUp' || e?.code === 'ArrowUp') {
      const newIndex = focusedIndex < 1 ? values.length - 1 : focusedIndex - 1
      this.setState({ focusedIndex: newIndex })
      if (this.resultsRefs?.[newIndex]?.scrollIntoView) {
        this.resultsRefs[newIndex].scrollIntoView({ behavior: 'smooth' })
      }
    }
    if (e?.keyCode === 13 || e?.which === 13 || e?.key === 'Enter' || e?.code === 'Enter') {
      if (values[0]) {
        document.activeElement.blur()
        this.setState({
          isOpen: false,
          focusedIndex: 0,
          searchString: '',
        })
        this.handleSelect(values[focusedIndex].id)
      }
    }
    if (e?.keyCode === 27 || e?.which === 27) {
      this.setState({ isOpen: false })
    }
  }

  toggleOpen = () => {
    if (this.props.disabled) return
    if (document.activeElement === this._input) return

    this.setState({ isOpen: !this.state.isOpen })
  }

  /**
   * Sets the selected option by ID
   *
   * @param {Number} id
   * @public
   */
  setSelectedId = (id) => {
    this.setState({ selectedId: id })

    const selectedValue = this.props.values.find((value) => value.id === id)
    if (selectedValue && !selectedValue.color) {
      let name = selectedValue.name
      if (this.props.singleLetter) name = name.charAt(0)
      this._input.value = name
    } else {
      this._input.value = ''
    }
  }

  /**
   * Returns the ID of the selected option
   *
   * @returns {Number} selected ID
   * @public
   */
  getSelectedId = () => {
    return this.state.selectedId
  }

  /**
   * Returns placeholder of the input box with localization
   *
   * @returns {String} placeholder
   * @public
   */
  getPlaceholder = (placeholder) => {
    let text = Utilities.objectValueFromDotNotation(getLang(this.props.lang), placeholder)

    if (!text && placeholder) text = placeholder

    return text
  }

  handleChange = (event) => {
    this.setState({
      searchString: event.target.value,
    })
  }

  handleClick = (event) => {
    if (!DOMHelpers.didClickInsideArray([this._input, this._listContainer], event)) {
      this.setState({
        searchString: '',
        isOpen: false,
      })
    }
  }

  handleSelect = (id) => {
    setTimeout(() => {
      this.setState({ selectedId: id, isOpen: false }, () => {
        let selectedValue = this.props.values.find((value) => value.id === this.state.selectedId)
        if (selectedValue && !selectedValue.color) {
          this._input.value = selectedValue.name
        }
      })
    }, 100)
    if (this.props.onSelect) this.props.onSelect(id)
  }

  handleBlur = () => {
    setTimeout(() => {
      this.setState({ isOpen: false })
    }, 150)
  }

  renderSubOption = (desc) => {
    return (
      <div className={style.desc}>
        <Text textId={desc?.text} />
      </div>
    )
  }

  renderList() {
    const { isOpen, searchString: search, focusedIndex } = this.state
    let {
      values,
      hideSelf,
      selectedId,
      hiddenValues,
      noOtherOption,
      dropdownAnchor,
      optionRenderer,
      noMargin,
      withoutFocusedStyle,
    } = this.props

    if (hideSelf) values = values.filter((value) => value.id !== selectedId)
    if (hiddenValues) values = values.filter((value) => !hiddenValues.includes(value.id))

    return (
      <div
        className={cx(
          style.listContainer,
          dropdownAnchor === 'top' && style.anchorTop,
          noMargin && style.noMargin,
          isOpen ? style.displayBlock : style.displayNone
        )}
        ref={(container) => (this._listContainer = container)}
      >
        <div className={style.list}>
          {values
            .filter(({ name }) => !search || name.toLowerCase().includes(search.toLowerCase()))
            .map(({ id, name, desc, color, bold }, index) => (
              <div
                key={id}
                ref={(ref) => (this.resultsRefs[index] = ref)}
                className={cx(style.listItem, {
                  [style.focused]: !withoutFocusedStyle && focusedIndex === index,
                })}
                onClick={() => this.handleSelect(id)}
              >
                {optionRenderer ? (
                  <span className={style.name}>
                    {optionRenderer({ id, name, desc, color }, index)}
                  </span>
                ) : color ? (
                  <TeetimeStatus color={color} name={name} />
                ) : (
                  <span className={style.name} title={name}>
                    {name}
                  </span>
                )}
                {desc && this.renderSubOption(desc)}
              </div>
            ))}
          {!values.length && hideSelf && noOtherOption && (
            <div className={style.listItem}>
              <Text textId={noOtherOption} />
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { isOpen, selectedId } = this.state
    const {
      values,
      disabled,
      styleDisabled,
      noStrikethroughWhenDisabled,
      className,
      placeholder,
      width,
      largeText,
      hideArrow,
      readOnly,
      showBorder,
      blueStyle,
      error,
      fixTextLayout,
      containerClassName: cName,
      statusClassName,
      isClearable,
    } = this.props

    const containerClassName = [style.container]
    if (isOpen) containerClassName.push(style.open)
    if (largeText) containerClassName.push(style.largeText)
    if (hideArrow) containerClassName.push(style.hideArrow)
    if (showBorder) containerClassName.push(style.showBorder)
    if (blueStyle) containerClassName.push(style.blueStyle)
    if (disabled) containerClassName.push(style.disabled)
    if (styleDisabled && disabled) containerClassName.push(style.disabledStyled)
    if (noStrikethroughWhenDisabled) containerClassName.push(style.noStrikethroughWhenDisabled)
    if (cName) containerClassName.push(cName)
    if (fixTextLayout) containerClassName.push(style.fixTextLayout)

    const inputClassName = []
    if (className) {
      className.split(' ').forEach((item) => {
        inputClassName.push(style[item])
      })
    }
    const containerWidth = `${width}px` ? width : '140px'
    const selectedValue = values?.find((item) => item.id === selectedId)

    return (
      <div style={{ width: containerWidth }} className={containerClassName.join(' ')}>
        <input
          type="text"
          disabled={disabled}
          readOnly={!isOpen || readOnly}
          placeholder={this.getPlaceholder(placeholder)}
          className={['ss-input', ...inputClassName].join(' ')}
          ref={(input) => (this._input = input)}
          onFocus={() => this.setState({ isOpen: true })}
          onBlur={this.handleBlur}
        />
        {isClearable && selectedId && (
          <CloseIcon className={style.clearButton} onClick={() => this.handleSelect(null)} />
        )}
        {selectedValue?.color && (
          <div className={cx(style.status, statusClassName)} onClick={this.toggleOpen}>
            <TeetimeStatus color={selectedValue.color} name={selectedValue.name} />
          </div>
        )}
        {selectedValue?.desc && (
          <div className={style.selectedDesc}>{this.renderSubOption(selectedValue.desc)}</div>
        )}
        {blueStyle ? (
          <BlueCaretIcon
            className={inputClassName.join(' ')}
            ref={(icon) => (this._arrow = icon)}
          />
        ) : (
          <CaretIcon className={inputClassName.join(' ')} ref={(icon) => (this._arrow = icon)} />
        )}
        <FeedbackContainer error={error} />
        {this.renderList()}
      </div>
    )
  }
}

DropdownSelect.propTypes = {
  /**
   * List of options in the format `[{ name: String, id: Number }, ...]`
   *
   * If the name starts with a dot (.) it will be used as a *textId* in a **Text** component
   */
  values: PropTypes.array.isRequired,
  /**
   * Optional error
   */
  error: PropTypes.string,
  /**
   * Array of value ids to hide, in format [ Number ]
   */
  hiddenValues: PropTypes.array,
  /**
   * ID of the initially selected option (optional)
   */
  initialId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * ID of the selected option (optional)
   */
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * width of the select box (optional)
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Classname (optional)
   */
  className: PropTypes.string,
  /**
   * Classname for container (optional)
   */
  containerClassName: PropTypes.string,
  /**
   * Boolean to disable FormControl (optional)
   */
  disabled: PropTypes.bool,
  /**
   * Boolean to make FormControl read-only(optional)
   */
  readOnly: PropTypes.bool,
  /**
   * Boolean to hide placeholder (optional)
   */
  placeholder: PropTypes.string,
  /**
   * Boolean to set large size of text
   */
  largeText: PropTypes.bool,
  /**
   * User language
   *
   * **This props does not need to be provided**
   */
  lang: PropTypes.string.isRequired,
  /**
   * Bool to hide drop down arrow
   */
  hideArrow: PropTypes.bool,
  /**
   * Bool to hide selected drop down select item
   */
  hideSelf: PropTypes.bool,
  /**
   * Function to run when selecting an option
   *
   * @param {Number} ID selected ID
   */
  onSelect: PropTypes.func,
  /**
   * Bool to set if only first letter should be display in the input field (optional)
   */
  singleLetter: PropTypes.bool,
  /**
   * Bool to update isOpen state (optional)
   */
  isOpen: PropTypes.bool,
  /**
   * Bool to set if input should have border (optional)
   */
  showBorder: PropTypes.bool,
  /**
   * String to show message when there is no other option to select (optional) : Should be set with hideSelf option
   */
  noOtherOption: PropTypes.string,
  /**
   * Bool to set if input border is blue (optional)
   */
  blueStyle: PropTypes.bool,

  dropdownAnchor: PropTypes.string,
  statusClassName: PropTypes.string,

  /* avoid strike through when disabled */
  noStrikethroughWhenDisabled: PropTypes.bool,
  /* clear the margin from the bottom to the listContainer */
  noMargin: PropTypes.bool,
  isClearable: PropTypes.bool,
  /**
   * Bool to show/hide focused style (optional)
   */
  withoutFocusedStyle: PropTypes.bool,
}

DropdownSelect.defaultProps = {
  // showArrow: true
  hiddenValues: [],
  error: '',
  dropdownAnchor: 'bottom',
  statusClassName: '',
  isClearable: false,
  withoutFocusedStyle: false,
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.me.lang,
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  DropdownSelect
)
