import { useCallback, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  Button,
  Input,
  InputBase,
  InputContainer,
  InputHint,
  InputLabelContainer,
  InputTrailingContainer,
} from '@sweetspot/scramble-ds'
import ButtonDock from '@sweetspot/club-portal-legacy/components/ButtonDock/ButtonDock'

import { IntegrationFormFields } from '../types'
import BallDispensersEmptyState from './BallDispensersEmptyState'
import { useBallDispensersContext } from '../BallDispensersProvider'
import useRoutingBlock from '@sweetspot/club-portal-legacy/hooks/useRoutingBlock'

const IntegrationsTab = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const {
    integrationInitialData,
    handleIntegrationSave,
    setDiscardDialogCallbacks,
    setOpenDiscardDialog,
  } = useBallDispensersContext()
  const { register, watch, formState, reset, control } = useForm<IntegrationFormFields>({
    defaultValues: integrationInitialData,
  })
  const { fields } = useFieldArray({
    control,
    name: 'ballDispensers',
  })
  const formValues = watch()
  const isDirty = formState?.isDirty

  // reset form initialData
  useEffect(() => {
    reset(integrationInitialData)
  }, [integrationInitialData, reset])

  const handleFormReset = useCallback(() => {
    reset()
  }, [reset])

  const onSubmit = useCallback(() => {
    handleIntegrationSave?.(formValues)?.then(() => {
      addToast(t('toast.integrationUpdatedSuccess'), { appearance: 'success' })
    })
  }, [formValues, handleIntegrationSave, addToast, t])

  // block navigation when form is dirty
  useRoutingBlock({
    isDirty,
    onReset: reset,
    onSubmit,
    setDiscardDialogCallbacks,
    setOpenDiscardDialog,
  })

  return (
    <form onSubmit={(e) => e.preventDefault()} className="flex flex-col gap-6 pb-16 pt-4">
      <div className="max-w-[496px] rounded-md border p-4">
        <InputBase required>
          <InputLabelContainer className="text-text-dark mb-0.5">
            {t('settings.ballDispensers.siteIdLabel')}
          </InputLabelContainer>
          <InputContainer>
            <Input {...register('siteId')} className="text-content-base h-8 border" />
          </InputContainer>
          <InputHint
            className="[&_i]:text-content-xs [&_:first-child]:w-auto [&_i]:pt-[2px]"
            variant="default"
            hintText={t('settings.ballDispensers.siteIdHint')}
          />
        </InputBase>
      </div>

      {fields?.length === 0 && (
        <div className="max-w-[496px]">
          <BallDispensersEmptyState />
        </div>
      )}

      {fields?.length > 0 &&
        fields?.map((field, index) => (
          <div key={field.id} className="w-fit rounded-md border p-4">
            <div className="mb-4 font-bold">
              {field.name || `${t('sentences.ballDispenser')} ${index + 1}`}
            </div>

            <div className="flex gap-4">
              {field?.outlets?.map((outlet, outletIndex) => (
                <div
                  key={outletIndex}
                  className="bg-background-base-neutral flex w-[464px] flex-col gap-4 rounded-md border p-4"
                >
                  <InputBase required>
                    <InputLabelContainer className="text-text-dark mb-0.5">
                      {t('settings.ballDispensers.assetIdLabel')} - {t('words.outlet')}
                      {field?.outlets.length > 1 ? `: ${outlet.name || outletIndex + 1}` : ''}
                    </InputLabelContainer>
                    <InputContainer>
                      <Input
                        {...register(`ballDispensers.${index}.outlets.${outletIndex}.assetId`)}
                        className="text-content-base h-8 border"
                      />
                    </InputContainer>
                    <InputHint
                      className="[&_i]:text-content-xs [&_:first-child]:w-auto [&_i]:pt-[2px]"
                      variant="default"
                      hintText={t('settings.ballDispensers.assetIdHint')}
                    />
                  </InputBase>

                  <InputBase disabled>
                    <InputLabelContainer className="text-text-pale mb-0.5">
                      {t('settings.ballDispensers.terminalModelLabel')}
                    </InputLabelContainer>
                    <InputContainer>
                      <Input
                        className="text-content-base h-8 cursor-not-allowed border"
                        value={outlet.terminalModel}
                      />
                      <InputTrailingContainer>
                        <span className="fa-regular fa-angle-down" />
                      </InputTrailingContainer>
                    </InputContainer>
                  </InputBase>

                  <InputBase required>
                    <InputLabelContainer className="text-text-dark mb-0.5">
                      {t('settings.ballDispensers.terminalSerialNrLabel')}
                    </InputLabelContainer>
                    <InputContainer>
                      <Input
                        {...register(
                          `ballDispensers.${index}.outlets.${outletIndex}.terminalSerialNumber`
                        )}
                        className="text-content-base h-8 border"
                      />
                    </InputContainer>
                    <InputHint
                      className="[&_i]:text-content-xs [&_:first-child]:w-auto [&_i]:pt-[2px]"
                      variant="default"
                      hintText={t('settings.ballDispensers.terminalSerialNrHint')}
                    />
                  </InputBase>
                </div>
              ))}
            </div>
          </div>
        ))}

      <ButtonDock
        className="px-0 pl-6 md:justify-center"
        dockClassName="left-[15px] pr-6"
        isShown={isDirty}
      >
        <Button variant="ghost-dark" size="small" type="button" onClick={handleFormReset}>
          {t('words.cancel')}
        </Button>
        <Button variant="primary" size="small" onClick={onSubmit}>
          {t('words.save')}
        </Button>
      </ButtonDock>
    </form>
  )
}

export default IntegrationsTab
