import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { tv, VariantProps } from 'tailwind-variants'
import { cn } from '../../../utils'

const tabsButtonVariants = tv({
  base: 'text-text-subtle focus-visible:ring-border-stroke-focused visible:ring-2 border-border-stroke-pale hover:border-brand-black hover:text-brand-black data-[state=focus]:border-brand-black data-[state=active]:border-brand-black data-[state=active]:text-brand-black disabled:text-text-pale flex w-full items-center justify-center gap-3 whitespace-nowrap border-b-[3px] px-3 pb-2 font-bold transition-all focus-visible:outline-none focus-visible:ring-inset disabled:pointer-events-none',
  variants: {
    size: {
      small: 'text-sm',
      medium: 'text-base',
      large: 'text-lg',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'bg-muted text-muted-foreground flex h-10 w-full items-center justify-center rounded-md',
      className
    )}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

interface TabsTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>,
    VariantProps<typeof tabsButtonVariants> {}

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  TabsTriggerProps
>(({ className, size = 'medium', ...props }, ref) => (
  <TabsPrimitive.Trigger ref={ref} className={tabsButtonVariants({ size, className })} {...props} />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'ring-offset-background focus-visible:ring-ring mt-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      className
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
