import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  InputBase,
  InputContainer,
  Input,
  InputLeadingContainer,
  ListItem,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  ListItemParagraph,
  ListItemTrailing,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
  TooltipArrow,
} from '@sweetspot/scramble-ds'

import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import { formatPeriodDatesWithTimezone } from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import { addDays, isBefore, parseISO } from 'date-fns'
import PeriodActionsPopover from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/PeriodActionsPopover'

type PeriodListItem = {
  uuid: string
  name: string
  start_date: Date
  end_date: Date
  dateRange: string
}

type GapListItem = {
  start_date: Date
  end_date: Date
  dateRange: string
}

const AllPeriods = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const { t } = useTranslation()
  const {
    isSideBarOpen,
    selectedPeriod,
    setSelectedPeriod,
    toggleAllPeriodsSideBar,
    periods,
    arePeriodsLoading,
    currentCourse,
  } = useAvailabilityAndPricingContext()

  const timezone = currentCourse?.timezone || 'UTC'

  const periodsListData: [PeriodListItem | GapListItem] = useMemo(() => {
    const formattedPeriods: PeriodListItem[] = periods.map((period) => {
      const { start_date, end_date } = period
      return {
        uuid: period.uuid,
        name: period.name,
        start_date: parseISO(start_date),
        end_date: parseISO(end_date),
        dateRange: formatPeriodDatesWithTimezone(start_date, end_date, timezone, 'yyyy-MM-dd'),
      }
    })

    formattedPeriods.sort((a, b) => (isBefore(a.start_date, b.start_date) ? -1 : 1))

    const periodsWithGaps = []

    for (let i = 0; i < formattedPeriods.length - 1; i++) {
      const currentPeriod = formattedPeriods[i]
      const nextPeriod = formattedPeriods[i + 1]

      // Add current period to the list
      periodsWithGaps.push(currentPeriod)

      // Check for a gap
      if (isBefore(addDays(currentPeriod.end_date, 1), nextPeriod.start_date)) {
        periodsWithGaps.push({
          start_date: addDays(currentPeriod.end_date, 1),
          end_date: addDays(nextPeriod.start_date, -1),
          dateRange: formatPeriodDatesWithTimezone(
            addDays(currentPeriod.end_date, 1),
            addDays(nextPeriod.start_date, -1),
            timezone,
            'yyyy-MM-dd'
          ),
        })
      }
    }
    if (formattedPeriods.length > 0) {
      periodsWithGaps.push(formattedPeriods[formattedPeriods.length - 1])
    }

    periodsWithGaps.sort((a, b) => (isBefore(a.start_date, b.start_date) ? 1 : -1))

    return periodsWithGaps as [PeriodListItem | GapListItem]
  }, [periods, timezone])
  const filteredPeriodsListData = useMemo(() => {
    if (!searchQuery) return periodsListData
    return periodsListData.filter(
      (data) => 'name' in data && data.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [periodsListData, searchQuery])

  const renderGap = useCallback(
    (gap: GapListItem) => {
      return (
        <TooltipProvider key={gap.dateRange}>
          <Tooltip>
            <React.Fragment key={gap.dateRange}>
              <TooltipTrigger asChild>
                <ListItem className="bg-surface-warning-disabled border-border-stroke-warning w-full rounded-md border px-4">
                  <ListItemMainContainer>
                    <ListItemMainContent>
                      <ListItemParagraph className="text-text-subtle text-content-sm font-regular flex items-center gap-2">
                        <i className={'fa-regular fa-circle-exclamation text-text-warning'} />
                        {gap.dateRange}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItemMainContainer>
                </ListItem>
              </TooltipTrigger>
              <TooltipContent>
                <div className="flex max-w-[246px] flex-col gap-4 py-4">
                  <p className="text-content-sm text-text-light font-bold">
                    {t('sentences.unassignedDates')}
                  </p>
                  <p className="text-content-sm font-regular text-text-light">
                    {t('sentences.unassignedDatesDescription')}
                  </p>
                </div>
                <TooltipArrow />
              </TooltipContent>
            </React.Fragment>
          </Tooltip>
        </TooltipProvider>
      )
    },
    [t]
  )

  const handlePeriodClick = useCallback(
    (period: PeriodListItem) => {
      if (period.uuid === selectedPeriod?.uuid) {
        return
      }
      const clickedPeriod = periods.find((p) => p.uuid === period.uuid)
      if (clickedPeriod) {
        setSelectedPeriod(clickedPeriod)
        toggleAllPeriodsSideBar()
      }
    },
    [periods, selectedPeriod?.uuid, setSelectedPeriod, toggleAllPeriodsSideBar]
  )

  const renderPeriod = useCallback(
    (period: PeriodListItem) => {
      const isSelected = selectedPeriod?.uuid === period.uuid
      return (
        <ListItem
          key={period.dateRange}
          className={`bg-background-base-clean w-full cursor-pointer rounded-md border px-4 ${
            isSelected ? 'border-border-stroke-info border-2' : 'border-border-stroke-clean border'
          }`}
          onClick={() => handlePeriodClick(period)}
        >
          <ListItemMainContainer>
            <ListItemMainContent>
              <ListItemMainLabel className="text-text-dark text-content-base font-bold">
                {period.name}
              </ListItemMainLabel>
              <ListItemParagraph className="text-text-subtle text-content-sm font-regular">
                {period.dateRange}
              </ListItemParagraph>
            </ListItemMainContent>
            <ListItemTrailing
              onClick={(e) => {
                // Prevent this click from triggering the ListItem onClick
                e.stopPropagation()
              }}
            >
              <PeriodActionsPopover periodUuid={period.uuid} />
            </ListItemTrailing>
          </ListItemMainContainer>
        </ListItem>
      )
    },
    [handlePeriodClick, selectedPeriod?.uuid]
  )

  if (!periods || arePeriodsLoading) {
    return null
  }

  return (
    <Sheet open={isSideBarOpen} onOpenChange={toggleAllPeriodsSideBar}>
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={toggleAllPeriodsSideBar}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{t('sentences.allPeriods')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-2">
          <InputBase className="w-full">
            <InputContainer>
              <InputLeadingContainer>
                <i className="fa-regular fa-search" />
              </InputLeadingContainer>
              <Input
                placeholder={t('sentences.searchNameOfPeriod')}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="rounded-pill border-border-stroke-clean text-content-base h-8 px-10 py-0 italic"
              />
            </InputContainer>
          </InputBase>
          {filteredPeriodsListData.map((data) =>
            data.uuid ? renderPeriod(data as PeriodListItem) : renderGap(data as GapListItem)
          )}
        </SheetCustomContent>
      </SheetContent>
    </Sheet>
  )
}

export default AllPeriods
