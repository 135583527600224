import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { _deleteGolfCourse } from '@sweetspot/club-portal-legacy/services/golfCourseApi'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'

import { useManageSimulatorContext } from '../ManageSimulatorProvider'

const useDeleteSimulatorMutation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()
  const { refetchSimulators, simulators } = useManageSimulatorContext()

  const deleteSimulatorMutation = useMutation((id: number) => _deleteGolfCourse(id), {
    onSuccess: (_, id) => {
      addToast(t('toast.courseDeleted', { type: t('words.simulator_one') }), {
        appearance: 'success',
      })
      const path = history.location.pathname
      const selectedSimulator = simulators.find((simulator) => simulator.id !== id)
      history.push(`${path}#${selectedSimulator?.id}`)
      // the corresponding course needs a bit time to be created after the simulator is created
      setTimeout(async () => {
        refetchSimulators()
        dispatch({
          type: CONSTANTS.GOLF_COURSE.DELETE,
          payload: id,
        })
      }, 2000)
    },
    onError: () => {
      addToast(t('toast.defaultError'), { appearance: 'error' })
    },
  })

  return deleteSimulatorMutation
}

export default useDeleteSimulatorMutation
