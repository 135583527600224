import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import {
  Button,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import PeriodName from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/Name'
import AdjustmentDates from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/Dates'
import AdjustmentDaysToApply from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/CreateAdjustment/Days'
import { formatPeriodDatesWithTimezone } from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import PeriodOpeningHours from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/OpeningHours'
import Categories from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/Categories'
import MaxPlayers from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/MaxPlayers'
import {
  CreateAdjustmentPayload,
  PeriodFormError,
} from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'
import validateForm from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/validateForm'
import { useToasts } from 'react-toast-notifications'
import { useMutation } from 'react-query'
import { _addPeriodOverride } from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'

const CreateAdjustment = () => {
  const { isSideBarOpen, toggleCreateAdjustmentSideBar, currentCourse, selectedPeriod } =
    useAvailabilityAndPricingContext()

  const { t } = useTranslation()

  const { addToast } = useToasts()

  const timezone = currentCourse?.timezone || 'UTC'

  const [selectedPeriodStartDate, selectedPeriodEndDate] = useMemo(() => {
    if (!selectedPeriod) {
      return ['', '']
    }

    const { start_date = '', end_date = '' } = selectedPeriod
    const formattedDate = formatPeriodDatesWithTimezone(
      start_date,
      end_date,
      timezone,
      'yyyy-MM-dd'
    )
    return formattedDate.split(' - ')
  }, [selectedPeriod, timezone])

  const [selectedPeriodStartTime, selectedPeriodEndTime] = useMemo(() => {
    if (!selectedPeriod) {
      return ['', '']
    }

    const { start_time_from, start_time_to } = selectedPeriod

    if (!start_time_from || !start_time_to) {
      return ['', '']
    }

    return [start_time_from.substr(11, 5), start_time_to.substr(11, 5)]
  }, [selectedPeriod])

  const [name, setName] = useState<string>('')
  const [startDate, setStartDate] = useState<string>(selectedPeriodStartDate)
  const [endDate, setEndDate] = useState<string>(selectedPeriodEndDate)
  const [isWholePeriodChecked, setIsWholePeriodChecked] = useState<boolean>(false)
  const [startTimeFrom, setStartTimeFrom] = useState<string>(selectedPeriodStartTime)
  const [startTimeTo, setStartTimeTo] = useState<string>(selectedPeriodEndTime)
  const [isOpeningHoursChecked, setIsOpeningHoursChecked] = useState<boolean>(false)
  const [repeatOn, setRepeatOn] = useState<string>('every_day')
  const [category, setCategory] = useState<number>(selectedPeriod?.category?.id || 0)
  const [slots, setSlots] = useState<number>(selectedPeriod?.slots || 0)

  const addOverrideMutation = useMutation(
    (payload: CreateAdjustmentPayload) => _addPeriodOverride(selectedPeriod?.uuid, payload),
    {
      onError: ({ detail }) => {
        addToast(detail, { appearance: 'error' })
      },
    }
  )

  const handleWholePeriodChange = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        setStartDate(selectedPeriodStartDate || '')
        setEndDate(selectedPeriodEndDate || '')
      } else {
        setStartDate('')
        setEndDate('')
      }
      setIsWholePeriodChecked(isChecked)
    },
    [selectedPeriodEndDate, selectedPeriodStartDate]
  )

  const handleOpeningHoursChange = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        setStartTimeFrom(selectedPeriodStartTime)
        setStartTimeTo(selectedPeriodEndTime || '')
      } else {
        setStartTimeFrom('')
        setStartTimeTo('')
      }
      setIsOpeningHoursChecked(isChecked)
    },
    [selectedPeriodEndTime, selectedPeriodStartTime]
  )

  const isCreateAdjustmentDisabled = useMemo(() => {
    return !name || !startDate || !endDate || !startTimeFrom || !startTimeTo || !category || !slots
  }, [category, endDate, name, slots, startDate, startTimeFrom, startTimeTo])

  const handleCreateAdjustment = useCallback(() => {
    if (isCreateAdjustmentDisabled) return

    const newErrors: PeriodFormError = validateForm({
      name,
      startDate,
      endDate,
      startTimeFrom,
      startTimeTo,
      interval: selectedPeriod?.interval,
    })

    if (Object.keys(newErrors).length > 0) {
      addToast(t(Object.values(newErrors)[0]), { appearance: 'error' })
      return
    }

    const newAdjustmentPayload: CreateAdjustmentPayload = {
      name,
      start_date: startDate,
      end_date: endDate,
      start_time_from: startTimeFrom,
      start_time_to: startTimeTo,
      category_id: category as number,
      slots: slots as number,
      is_golf_id_required: false,
      is_prime_time: false,
      repeat_on: repeatOn,
    }

    addOverrideMutation.mutate(newAdjustmentPayload)
  }, [
    addOverrideMutation,
    addToast,
    category,
    endDate,
    isCreateAdjustmentDisabled,
    name,
    repeatOn,
    selectedPeriod?.interval,
    slots,
    startDate,
    startTimeFrom,
    startTimeTo,
    t,
  ])

  return (
    <Sheet open={isSideBarOpen} onOpenChange={toggleCreateAdjustmentSideBar}>
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={toggleCreateAdjustmentSideBar}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{t('sentences.newAdjustment')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6 pb-0 pt-2">
          <div className="p-lg border-border-stroke-pale bg-background-mono-lighter flex gap-2  rounded-md border">
            <div className="p-1">
              <FontAwesomeIcon icon={faCircleInfo} className="text-text-dark" />
            </div>
            <p className="text-content-sm font-regular text-text-dark">
              {t('sentences.newAdjustmentAlert')}
            </p>
          </div>
          <PeriodName
            name={name}
            onChange={setName}
            label={t('sentences.nameOfAdjustment')}
            placeholder={t('sentences.nameOfAdjustmentPlaceholder')}
          />
          <AdjustmentDates
            startDate={startDate}
            endDate={endDate}
            timezone={timezone}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            showWholePeriodOption
            isWholePeriodChecked={isWholePeriodChecked}
            onWholePeriodChange={handleWholePeriodChange}
            readOnly={isWholePeriodChecked}
            tooltipContent={'sentences.periodAdjustmentDatesTooltipDescription'}
          />
          <PeriodOpeningHours
            startTimeFrom={startTimeFrom}
            onStartTimeFromChange={setStartTimeFrom}
            startTimeTo={startTimeTo}
            onStartTimeToChange={setStartTimeTo}
            showOpeningHoursOption
            isOpeningHoursChecked={isOpeningHoursChecked}
            onOpeningHoursChange={handleOpeningHoursChange}
            readOnly={isOpeningHoursChecked}
            tooltipContent={'sentences.periodAdjustmentOpeningHoursTooltipDescription'}
          />
          <AdjustmentDaysToApply value={repeatOn} onChange={setRepeatOn} />
          <Categories
            category={category}
            onCategoryChange={setCategory}
            tooltipContent={'sentences.periodAdjustmentCategoryTooltipDescription'}
          />
          <MaxPlayers slots={slots} onSlotChange={setSlots} />
        </SheetCustomContent>
        <SheetFooter>
          <Button disabled={isCreateAdjustmentDisabled} onClick={handleCreateAdjustment}>
            {t('words.create')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
export default CreateAdjustment
