import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@sweetspot/scramble-ds'

type ActivateDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  isAcademyActive: boolean
  onSave: () => void
}

const ActivateDialog = ({ open, setOpen, isAcademyActive, onSave }: ActivateDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent variant="default">
        <DialogHeader>
          <DialogTitle>
            {isAcademyActive
              ? t('settings.deactivateDialogTitle', { type: t('words.academy').toLowerCase() })
              : t('settings.activateDialogTitle', { type: t('words.academy').toLowerCase() })}
          </DialogTitle>
          <DialogDescription>
            {isAcademyActive
              ? t('settings.academy.deactivateDialogText')
              : t('settings.academy.activateDialogText')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost-dark" type="button" size="large" onClick={() => setOpen(false)}>
              {t('words.cancel')}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="primary" size="large" onClick={onSave}>
              {t('words.save')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ActivateDialog
