import { BayTypeV2, Period, Range, TeeTime, TeeTimeCategory } from '@sweetspot/shared/types'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'

export enum SideBarMode {
  CREATE_PERIOD = 'CREATE PERIOD',
  ALL_PERIODS = 'ALL PERIODS',
  CREATE_ADJUSTMENT = 'CREATE ADJUSTMENT',
}

export type AvailabilityAndPricingContextValue = {
  areRangesLoading: boolean
  selectedRange?: Range
  setSelectedRange?: (range: Range) => void
  ranges: Range[]
  refetchRanges: () => void
  periods: Period[]
  arePeriodsLoading: boolean
  arePeriodsFetching: boolean
  refetchPeriods: () => void
  selectedPeriod: Period | null
  setSelectedPeriod: (period: Period) => void
  selectPeriodByUuid: (periodUuid: string) => void
  currentCourse: GolfCourseItem | null
  isSideBarOpen: boolean
  toggleSideBar: (mode?: SideBarMode) => void
  toggleAllPeriodsSideBar: () => void
  toggleCreatePeriodSideBar: () => void
  toggleCreateAdjustmentSideBar: () => void
  changeSideBarMode: (mode?: SideBarMode) => void
  autoSelectPeriod: () => void
  sideBarMode?: SideBarMode
  categories: TeeTimeCategory[]
  pricings: unknown[]
  groupOptions: string[]
  adjustments: unknown[]
  bays: BayTypeV2[]
  selectedBayIds: string[]
  setSelectedBayIds: (bays: string[]) => void
  price: number
  setPrice: (price: number) => void
  pricePerExtraPlayer: number
  setPricePerExtraPlayer: (pricePerExtraPlayer: number) => void
  selectedCells: Set<string>
  allCheckbox: AllCheckboxValues | null
  onAllCheckboxChange: (value: boolean) => void
  DAYS_OF_WEEK: string[]
  activePeriodTab?: string
  setActivePeriodTab: (tab: string) => void
  weekPeriodOptions: WeekPeriodOption[]
  timeSlots: PeriodPricingTableData['timeSlots']
  tableData: PeriodPricingTableData['data']
  onColumnClick: (index: number, isSelected?: boolean) => void
  selectedCols: boolean[] | null
  onRowClick: (index: number, isSelected?: boolean) => void
  selectedRows: boolean[] | null
  onCellClick: (cellId: string, isSelected?: boolean) => void
}

export type PeriodFormSelectFieldOption = {
  label: string
  value: string
}

export const bookingDurationOptions: PeriodFormSelectFieldOption[] = [
  { label: '30 min', value: '30' },
  { label: '60 min', value: '60' },
  { label: '90 min', value: '90' },
  { label: '120 min', value: '120' },
]

export const slotOptions: PeriodFormSelectFieldOption[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
]

export const weekDaysOptions: PeriodFormSelectFieldOption[] = [
  { label: 'dateTime.words.allDays', value: 'every_day' },
  { label: 'dateTime.longDays.monday_one', value: 'monday' },
  { label: 'dateTime.longDays.tuesday_one', value: 'tuesday' },
  { label: 'dateTime.longDays.wednesday_one', value: 'wednesday' },
  { label: 'dateTime.longDays.thursday_one', value: 'thursday' },
  { label: 'dateTime.longDays.friday_one', value: 'friday' },
  { label: 'dateTime.longDays.saturday_one', value: 'saturday' },
  { label: 'dateTime.longDays.sunday_one', value: 'sunday' },
]

export type PeriodFormValue = {
  name?: string
  startDate?: string
  endDate?: string
  startTimeFrom?: string
  startTimeTo?: string
  interval?: number
}

export type PeriodFormError = {
  name?: string
  openingHours?: string
  dateRange?: string
}

export type CreatePeriodPayload = {
  name: string
  start_date: string
  category_id: number
  end_date: string
  start_time_from: string
  start_time_to: string
  interval: number
  slots: number
  is_golf_id_required: boolean
  is_prime_time: boolean
}

export type CreateAdjustmentPayload = {
  name: string
  repeat_on: string
  start_date: string
  category_id: number
  end_date: string
  start_time_from: string
  start_time_to: string
  slots: number
  is_golf_id_required: boolean
  is_prime_time: boolean
}

export enum PeriodTabsEnum {
  PRICING = 'PRICING',
  ADJUSTMENTS = 'ADJUSTMENTS',
}

export enum PricingBayType {
  GROUP = 'GROUP',
  BAYS = 'BAYS',
}

export type WeekPeriodOption = {
  id: number
  name: string
}

export enum AllCheckboxValues {
  ALL = 'all',
  SOME = 'some',
}

export type PeriodPricingTableData = {
  data: { [key: string]: TeeTime[] }
  timeSlots: string[]
  daysMap: { [key: string]: string }
}
