import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TYPES_OBJ } from '@sweetspot/sweetspot-js/features/reservationPolicies/constants/checkers'
import styles from './styles.module.scss'
import ValueText from '../ValueText'

import { BOOKED_SLOTS_UNITS } from '@sweetspot/sweetspot-js/features/reservationPolicies/constants/checkers'

const MembershipCardCheckers = ({ checkers }) => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.container)}>
      {checkers.map((checker) => {
        const config = checker?.configuration
        const used = checker?.used || 0

        let unitsConf = []
        if (checker.type === TYPES_OBJ.BOOKED_SLOTS) {
          const units = config
            .map((x) => x?.range)
            .map((key) => BOOKED_SLOTS_UNITS.find((x) => x.value === key))
            .sort((a, b) => a.id - b.id)

          if (units?.length > 0) unitsConf = units
        }

        return (
          <div className={cx(styles.column)} key={checker.id}>
            {/* Simultaneously booked slots checker */}
            {checker.type === TYPES_OBJ.SIMUL_SLOTS && (
              <>
                <div className={cx(styles.row)}>
                  <ValueText fontWeight="medium">
                    {t('sentences.numberOfSimultaneousBookings')}
                  </ValueText>
                  <ValueText fontWeight="medium">
                    &nbsp;{`${used} / ${config?.number_of_simultaneous_slots}`}
                  </ValueText>
                </div>

                {config?.same_day_booking_time ? (
                  <ValueText fontSize="12">
                    (
                    {t('sentences.ignoredWhenBookingTheSameDay', {
                      time: config?.same_day_booking_time,
                    })}
                    )
                  </ValueText>
                ) : null}
              </>
            )}

            {/* Number of days before checker */}
            {checker.type === TYPES_OBJ.DAYS_BEFORE && (
              <div className={cx(styles.column)}>
                <div className={cx(styles.row)}>
                  <ValueText fontWeight="medium">
                    {config?.type === 'hours'
                      ? t('sentences.addNumberOfHoursBefore')
                      : t('sentences.addNumberOfDaysBefore')}
                  </ValueText>
                  <ValueText fontWeight="medium">&nbsp;{config?.number}</ValueText>
                </div>

                {config.ignore_when_adding_participant ? (
                  <ValueText fontSize="12">
                    ({t('sentences.ignoreWhenAddedAsParticipant')})
                  </ValueText>
                ) : null}
              </div>
            )}

            {/* Total number of booked slots within time range checker */}
            {checker.type === TYPES_OBJ.BOOKED_SLOTS && (
              <div className={styles.bookedSlotsWrapper}>
                {unitsConf.map((timePeriod) => (
                  <div key={timePeriod?.value} className={cx(styles.row, styles.bookedSlots)}>
                    <ValueText fontWeight="medium">
                      {timePeriod?.value !== 'total' ? (
                        <>
                          {t('sentences.numberOfBookedSlotsAllowedPer')}{' '}
                          <span className={styles.range}>{t(timePeriod.label).toLowerCase()}</span>
                        </>
                      ) : (
                        <span className={styles.range}>
                          {t('sentences.totalNumberOfBookedSlotsAllowed')}
                        </span>
                      )}
                    </ValueText>
                    <ValueText fontWeight="medium">
                      &nbsp;{config.find((x) => x.range === timePeriod.value)?.value || '-'}
                    </ValueText>
                  </div>
                ))}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

MembershipCardCheckers.propTypes = {
  checkers: PropTypes.array,
}

MembershipCardCheckers.defaultProps = {
  checkers: [],
}

export default MembershipCardCheckers
